export const isRouteExist = (routes: any, route: string) => {
    const search = (routes: any) => {
        for (let key in routes) {
            if (typeof routes[key] === 'object') {
                if (search(routes[key])) return true;
            } else {
                if (routes[key] === route) {
                    return true;
                }
            }
        }
        return false;
    }

    return search(routes);
};
