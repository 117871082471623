import {Card} from "../../@types/buildingOnlineAdvantagesCardsResources.tsx";
import advantageIcon1 from '/assets/images/why-opay-page-images/icon-about-feature-3.svg';
import advantageIcon2 from '/assets/images/pricing-page-images/prices-advantage-icon-2.svg';
import advantageIcon3 from '/assets/images/pricing-page-images/prices-advantage-icon-3.svg';

export const PricingAdvantagesCardsConfig: Card[] = [
    {
        image: advantageIcon1,
        description: 'pricing_advantage_1'
    },
    {
        image: advantageIcon2,
        description: 'pricing_advantage_2'
    },
    {
        image: advantageIcon3,
        description: 'pricing_advantage_3'
    }
]
