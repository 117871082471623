import {useEffect, useRef} from "react";
import $ from "jquery";
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {PaymentFlowCardsConfig} from "../../../configs/payment-methods-page/paymentFlowCards.ts";
import PaymentFlowCard from "./PaymentFlowCard.tsx";
import {handleNextButtonClick, makeSlick} from "../../../helpers/carousel/carouselHelper.ts";

const PaymentFlowCarousel = () => {

    const sliderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        makeSlick(1, sliderRef.current!);

        $('.next-step').on('click', () => handleNextButtonClick(sliderRef.current!));

        return () => {
            $('.review-next-button').off('click', () => handleNextButtonClick(sliderRef.current!));
            $(sliderRef.current!).slick('unslick');
        };
    }, []);

    return (
        <>
            <div className="opy-bg-white text-white pt-xl-4 pb-6">
                <div className="container opy-block">
                    <div id="payment-flow-slider" ref={sliderRef}>
                        {PaymentFlowCardsConfig.map((card, index) => (
                            <PaymentFlowCard key={index} card={card} />
                            ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentFlowCarousel;
