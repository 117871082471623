import PricingFormBanner from "./PricingFormBanner.tsx";
import PricingForm from "./PricingForm.tsx";
import {useRef, useState} from "react";
import PricingFormThankYou from "./PricingFormThankYou.tsx";
import {useTranslation} from "react-i18next";

const PricingFormSection = (
    {heading, description, subject, page}:
        {heading: string, description: string, subject: string, page: string}
) => {

    const {t} = useTranslation();
    const formContainerRef = useRef<HTMLDivElement>(null);

    const [submit, setSubmit] = useState(false);
    const [captcha, setCaptcha] = useState<boolean|null>(null);
    const [connectionError, setConnectionError] = useState<boolean>(false);

    return (
        <>
            <div ref={formContainerRef} className="opy-bg-gray pb-6 pt-6 pricing-form-container">
                <div className="container opy-block">
                    <div className="row mx-0 ">
                        {captcha === false && <div className="notices error red">
                            <p>{t('check_captcha')}</p>
                        </div>}
                        {connectionError && <div className="notices error red pricing-error">
                            <p>{t('connection_error')}</p>
                        </div>}
                        <PricingFormBanner heading={heading} description={description} />
                        {submit
                            ? <PricingFormThankYou />
                            : <PricingForm setSubmit={setSubmit}
                                           captcha={captcha}
                                           setCaptcha={setCaptcha}
                                           subject={subject}
                                           setConnectionError={setConnectionError}
                                           page={page}
                                           formContainerRef={formContainerRef} />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default PricingFormSection;
