import {Card} from "../../@types/buildingOnlineAdvantagesCardsResources.tsx";
import advantageIcon1 from '/assets/images/building-online-page-images/advantage-icon-1.svg';
import advantageIcon2 from '/assets/images/building-online-page-images/advantage-icon-2.svg';
import advantageIcon3 from '/assets/images/building-online-page-images/advantage-icon-3.svg';

export const BuildingOnlineAdvantagesCardsConfig: Card[] = [
    {
        image: advantageIcon1,
        description: 'building_online_advantage_1'
    },
    {
        image: advantageIcon2,
        description: 'building_online_advantage_2'
    },
    {
        image: advantageIcon3,
        description: 'building_online_advantage_3'
    }
]
