import {PricingFormInputsConfig} from "../../../configs/pricing-page/pricingFormInputsConfig.ts";
import {useTranslation} from "react-i18next";
import {SubmitHandler, useForm} from "react-hook-form";
import {PricingInputs} from "../../../@types/pricingFormResources.tsx";
import {sendPricingForm} from "../../../api";
import {MessageConfigEnum} from "../../../enum/message/MessageConfigEnum.ts";
import FormColumns from "./FormColumns.tsx";
import React, {useRef} from "react";
import {log} from "../../../logger/log.ts";
import {cleanUpWebsiteAddress, handleEmptyValues} from "../../../helpers/pricing/formHelper.ts";
import {getFromIso2} from "../../../@types/generics.tsx";

const PricingForm = (
    {setSubmit, captcha, setCaptcha, setConnectionError, subject, page, formContainerRef}:
        {
            setSubmit: (arg0: boolean) => void,
            captcha: boolean|null,
            setCaptcha: (arg0: boolean|null) => void,
            setConnectionError: (arg0: boolean) => void,
            subject: string,
            page: string,
            formContainerRef: React.RefObject<HTMLDivElement>
        }
) => {

    const {t, i18n} = useTranslation();
    const formRef = useRef<HTMLFormElement>(null);

    const {register, handleSubmit} = useForm<PricingInputs>();

    const onSubmit: SubmitHandler<PricingInputs> = async (data) => {

        setConnectionError(false);

        if (captcha === null || !captcha) {
            setCaptcha(false);
            return formContainerRef.current?.scrollIntoView();
        }

        data = handleEmptyValues(data);

        data.config = MessageConfigEnum.STAFF_PRICING;
        data.language = getFromIso2(i18n.language).toUpperCase();
        data.website_address = cleanUpWebsiteAddress(data.website_address);
        data.subject = `${t(subject)} | ${data.website_address !== null ? data.website_address : data.email}`;
        data.how_did_you_hear_about_us = t(data.how_did_you_hear_about_us);
        data.website_content_management_system = t(data.website_content_management_system);

        try {
            await sendPricingForm(data);
        } catch (error) {
            log.error(error);
            setConnectionError(true);
            return formContainerRef.current?.scrollIntoView();
        }

        if (page === 'pricing') {
            fbq('track', 'Lead', {content_type: "gautikaina"});
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'pricing_form_submission',
                'formLocation': 'ikainiai'
            });
        }

        if (page === 'solutions-shopify') {
            fbq('track', 'Lead', {content_type: "gautikaina"});
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'shopify_form_submission',
                'formLocation': 'sprendimai-shopify'
            });
        }

        setSubmit(true);
        return formContainerRef.current?.scrollIntoView();
    }

    return (
        <>
            <div className="col-lg-6 bg-white py-5 px-md-5">
                <div className="text-center mb-5">
                    <h3 className="mb-0 fs-4">{t('get_offer')}</h3>
                </div>
                <form ref={formRef} id="pricing-form" onSubmit={handleSubmit(onSubmit)} className="prices-form">
                    <FormColumns config={PricingFormInputsConfig}
                                 register={register}
                                 setCaptcha={setCaptcha}
                                 linkClassName={'text-decoration-underline text-blue'} />
                    <div className="row mt-3">
                        <div className="buttons">
                            <button type="submit"
                                    className="button opy-bg-orange rounded text-blue-dark fw-medium py-3 px-5 w-100">
                                {t('get_offer')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default PricingForm;
