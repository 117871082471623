import {useTranslation} from "react-i18next";
import React from "react";

const BannerWithElement = (
    {heading, element, slider}:
        {heading: string, element: React.ReactElement, slider: boolean}
) => {

    const {t} = useTranslation();

    return (
        <>
            <div  className="demo-banner-container">
                <div className="demo-banner-object-1"></div>
                <div className="demo-banner-object-2"></div>
                <div className="demo-banner-heading-container">
                    <div className="container opy-block">
                        <div className="pt-6">
                            <h1 className="pt-3 fs-4 fs-sm-3 text-center">{t(heading)}</h1>
                        </div>
                    </div>
                </div>
                {!slider && element}
            </div>
            {slider && element}
        </>
    )
}

export default BannerWithElement;
