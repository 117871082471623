import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const PaymentMethodCardButton = ({link}: {link: string}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="opy-payment-method-card__footer">
                <Link className="opy-button opy-button--light" to={`/${t(link)}`}>{t('learn_more')}</Link>
            </div>
        </>
    )
}

export default PaymentMethodCardButton;
