import GRecaptcha from "../../g-recaptcha/GRecaptcha.tsx";
import {useRef} from "react";
import ReCAPTCHA from "react-google-recaptcha";

const PricingFormRecaptcha = ({setCaptcha}: {setCaptcha: (arg0: boolean|null) => void}) => {

    const captchaRef = useRef<ReCAPTCHA>(null);

    return (
        <>
            <div className="form-field  ">
                <div className="form-label"><label className="inline"></label>
                </div>
                <div className="form-data">
                    <GRecaptcha setCaptcha={setCaptcha} captchaRef={captchaRef} />
                </div>
            </div>
        </>
    )
}

export default PricingFormRecaptcha;
