import {useTranslation} from "react-i18next";

const PricingCalculatorResult = ({commissionFee}: {commissionFee: number}) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="px-4 text-center elm">
                <h6 className="elo">{t('commission_fee')}</h6>
            </div>
            <div className="px-4 mb-55 text-center">
                <p id="prices-commission-fee" className="el_calc">{commissionFee.toFixed(2)} €</p>
            </div>
        </>
    )
}

export default PricingCalculatorResult;
