import {Service} from "../../@types/pricingTablePaymentServiceResources.tsx";
import flagLt from '/assets/images/pricing-page-images/flag-lt.png';
import flagLv from '/assets/images/pricing-page-images/flag-lv.png';
import flagEe from '/assets/images/pricing-page-images/flag-ee.png';
import flagEu from '/assets/images/pricing-page-images/flag-eu.png';

export const Fees = {
    pis: {
        lithuania: {
            percentageRate: 0.6,
            minRate: 0.05,
            maxRate: 0.40,
            fixedRate: 0.05
        },
        latvia: {
            percentageRate: 0.6,
            minRate: 0.05,
            maxRate: 0.40,
            fixedRate: 0.05
        },
        estonia: {
            percentageRate: 0.6,
            minRate: 0.05,
            maxRate: 0.40,
            fixedRate: 0.05
        }
    },
    bank_link: {
        lithuania: {
            percentageRate: 2,
            minRate: 0.10,
            maxRate: 2.00,
            fixedRate: 0.05
        }
    },
    card: {
        europe: {
            percentageRate: 1.3,
            minRate: 0.10,
            maxRate: null,
            fixedRate: 0.15
        }
    },
    wire_transfers: {
        europe: {
            percentageRate: 0.2,
            minRate: null,
            maxRate: 0.20,
            fixedRate: 0.05
        }
    },
    installment_purchases: {
        lithuania: {
            percentageRate: 0.52,
            minRate: null,
            maxRate: 0.52,
            fixedRate: 0.07
        }
    },
    cash: {
        lithuania: {
            percentageRate: 1.5,
            minRate: null,
            maxRate: null,
            fixedRate: 0.07
        }
    }
}

export const PricingTablePaymentServicesConfig: Service[] = [
    {
        heading: 'pis_service',
        countries: [
            {
                name: 'lithuania',
                flag: flagLt,
                percentageRate: Fees.pis.lithuania.percentageRate,
                minRate: Fees.pis.lithuania.minRate,
                maxRate: Fees.pis.lithuania.maxRate,
                fixedRate: Fees.pis.lithuania.fixedRate
            },
            {
                name: 'latvia',
                flag: flagLv,
                percentageRate: Fees.pis.latvia.percentageRate,
                minRate: Fees.pis.latvia.minRate,
                maxRate: Fees.pis.latvia.maxRate,
                fixedRate: Fees.pis.latvia.fixedRate
            },
            {
                name: 'estonia',
                flag: flagEe,
                percentageRate: Fees.pis.estonia.percentageRate,
                minRate: Fees.pis.estonia.minRate,
                maxRate: Fees.pis.estonia.maxRate,
                fixedRate: Fees.pis.estonia.fixedRate
            }
        ]
    },
    {
        heading: 'bank_link_service',
        countries: [
            {
                name: 'lithuania',
                flag: flagLt,
                percentageRate: Fees.bank_link.lithuania.percentageRate,
                minRate: Fees.bank_link.lithuania.minRate,
                maxRate: Fees.bank_link.lithuania.maxRate,
                fixedRate: Fees.bank_link.lithuania.fixedRate
            }
        ]
    },
    {
        heading: 'card_payments_service',
        countries: [
            {
                name: 'europe',
                flag: flagEu,
                percentageRate: Fees.card.europe.percentageRate,
                minRate: Fees.card.europe.minRate,
                maxRate: Fees.card.europe.maxRate,
                fixedRate: Fees.card.europe.fixedRate
            }
        ]
    },
    {
        heading: 'wire_transfers_service',
        countries: [
            {
                name: 'europe',
                flag: flagEu,
                percentageRate: Fees.wire_transfers.europe.percentageRate,
                minRate: Fees.wire_transfers.europe.minRate,
                maxRate: Fees.wire_transfers.europe.maxRate,
                fixedRate: Fees.wire_transfers.europe.fixedRate
            }
        ]
    },
    {
        heading: 'installment_purchases_service',
        countries: [
            {
                name: 'lithuania',
                flag: flagLt,
                percentageRate: Fees.installment_purchases.lithuania.percentageRate,
                minRate: Fees.installment_purchases.lithuania.minRate,
                maxRate: Fees.installment_purchases.lithuania.maxRate,
                fixedRate: Fees.installment_purchases.lithuania.fixedRate
            }
        ]
    },
    {
        heading: 'cash_payments_service',
        countries: [
            {
                name: 'lithuania',
                flag: flagLt,
                percentageRate: Fees.cash.lithuania.percentageRate,
                minRate: Fees.cash.lithuania.minRate,
                maxRate: Fees.cash.lithuania.maxRate,
                fixedRate: Fees.cash.lithuania.fixedRate
            }
        ]
    }
]
