import {Item} from "../../../@types/purchaseExampleItemsResources.tsx";
import {useTranslation} from "react-i18next";

const PurchaseExampleItem = ({item}: {item: Item}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="opy-bg-light-blue rounded py-3 ps-3 pe-4 mb-4 d-block d-md-flex align-items-center justify-content-between">
                <div className="mb-4 mb-md-0 d-block d-md-flex align-items-center">
                    <img className="me-4 mb-4 mb-md-0" src={item.image} alt="purchase item"/>
                    <div>
                        <div className="fs-7 mb-1 text-blue-dark">{t(item.name)}</div>
                        <div className="fs-9">{t(item.description)}</div>
                    </div>
                </div>
                <div className="fs-7 text-blue-dark">{item.amount}</div>
            </div>
        </>
    )
}

export default PurchaseExampleItem;
