import {Cards} from "../../@types/donationDemoCardsResources.tsx";
import image1Lt from "/assets/images/demo-pages-images/donation-image-1-lt.gif";
import image2Lt from "/assets/images/demo-pages-images/donation-image-2-lt.png";
import image3Lt from "/assets/images/demo-pages-images/donation-image-3-lt.png";
import image4Lt from "/assets/images/demo-pages-images/donation-image-4-lt.png";
import image1En from "/assets/images/demo-pages-images/donation-image-1-en.gif";
import image2En from "/assets/images/demo-pages-images/donation-image-2-en.png";
import image3En from "/assets/images/demo-pages-images/donation-image-3-en.png";
import image4En from "/assets/images/demo-pages-images/donation-image-4-en.png";

export const DonationDemoCardsConfig: Cards = {
    list: [
        {
            image: {
                lt: image1Lt,
                en: image1En
            }
        },
        {
            image: {
                lt: image2Lt,
                en: image2En
            }
        },
        {
            image: {
                lt: image3Lt,
                en: image3En
            }
        },
        {
            image: {
                lt: image4Lt,
                en: image4En
            }
        },
    ],
    button: {
        title: 'try_now',
        link: {
            lt: 'https://apps.opay.lt/donation/demo-rezervatas/',
            en: 'https://apps.opay.lt/donation/demo-rezervatas/?language=eng'
        }
    }
}
