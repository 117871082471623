import {FormSectionType} from "../../@types/forTestingFormSectionsResources.ts";
import FormSectionRow from "./FormSectionRow.tsx";
import {useTranslation} from "react-i18next";
import {UseFormRegister} from "react-hook-form";
import {ForTestingInputs} from "../../@types/forTestingFormResources.ts";
import {AcceptPaymentsInputs} from "../../@types/acceptPaymentsFormResources.ts";

const FormSection = (
    {section, register}:
        {section: FormSectionType, register: UseFormRegister<ForTestingInputs>|UseFormRegister<AcceptPaymentsInputs>}
) => {

    const { t } = useTranslation();

    return (
        <>
            {section.heading && <h2 className="fs-5 mb-4">{t(section.heading)}</h2>}
            {section.rows.map((row, index) => (
                <FormSectionRow row={row} key={index} register={register} />
            ))}
            <hr />
        </>
    )
}

export default FormSection;
