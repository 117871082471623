import {Link} from "react-router-dom";

const CustomLink = ({to, className, children}: {to: string, className: string, children: string}) => {
    return (
        <>
            <Link to={to} className={className}>
                <span>{children}</span>
            </Link>
        </>
    )
}

export default CustomLink;
