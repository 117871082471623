import {useTranslation} from "react-i18next";
import {WhyOpayAdvantagesConfig} from "../../../configs/why-opay-page/whyOpayAdvantages.ts";
import AdvantageImageLeft from "./AdvantageImageLeft.tsx";
import AdvantageImageRight from "./AdvantageImageRight.tsx";

const AdvantagesSection = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="opy-bg-white opy-features pt-md-6 opy-page-about">
                <div className="container opy-block">
                    <h2 className="fs-3 fs-md-2 mb-5 mb-md-6">{t('why_opay')}</h2>
                </div>
                {WhyOpayAdvantagesConfig.map((advantage, index) => (
                    advantage.imagePosition === "left"
                        ?
                        <AdvantageImageLeft key={index}
                                              heading={advantage.heading}
                                              image={advantage.image}
                                              links={advantage.advantages} />
                        :
                        <AdvantageImageRight key={index}
                                               heading={advantage.heading}
                                               image={advantage.image}
                                               links={advantage.advantages} />
                ))}
            </div>
        </>
    )
}

export default AdvantagesSection;
