import bannerImage from "/assets/images/why-opay-page-images/icon-about-feature-5.svg";
import LegalBanner from "../../components/privacy-policy-page-components/legal-banner/LegalBanner.tsx";
import LegalPageSections
    from "../../components/privacy-policy-page-components/privacy-policy-sections/LegalPageSections.tsx";
import {CookiesPolicyPageSectionsConfig} from "../../configs/legal-pages/cookiesPolicySections.ts";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const CookiesPolicyPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.cookies_policy');
    }, []);

    return (
        <>
            <LegalBanner heading={'cookies_policy'} image={bannerImage} />
            <LegalPageSections config={CookiesPolicyPageSectionsConfig} />
        </>
    )
}

export default CookiesPolicyPage;
