import * as Sentry from "@sentry/react";
import {Logger} from "../../../src/logger/logger.ts";
import {useEffect} from "react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import {Scope} from "@sentry/react";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        "localhost",
        /^https:\/\/opay\.eu/,
        /^https:\/\/a\.testing\.opay\.eu/,
        /^https:\/\/website-staging\.opay\.eu/,
        /^https:\/\/website\.opay\.eu/,
    ],
    // Session Replay
    replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: import.meta.env.VITE_APP_ENV !== 'development',
    environment: import.meta.env.VITE_APP_ENV,
    debug: import.meta.env.VITE_APP_ENV === 'development',
});

export class SentryLogger implements Logger {
    error(error: any): void {
        Sentry.withScope((scope: Scope) => {
            if (error.response) {
                scope.setExtras(error?.response?.data);
            }
            scope.setExtras(error);
            Sentry.captureException(error);
        });
    }

    info(error: any): void {
        Sentry.captureMessage(error);
    }
}
