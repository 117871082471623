import {Section} from "../../../@types/faqAccordionResources.tsx";
import {useTranslation} from "react-i18next";
import FaqQuestion from "./FaqQuestion.tsx";
import {getCurrentLanguageEnum} from "../../../@types/generics.tsx";

const FaqQuestionsSection = ({section}: {section: Section}) => {

    const { t, i18n} = useTranslation();

    return (
        <>
            <div className={section.active?.languageToActive === i18n.language ? 'tab-pane fade show active' : 'tab-pane fade'}
                 id={section.id}
                 role="tabpanel"
                 aria-labelledby={section.labeledBy}>
                <div className="opy-faq-topic-header mb-5">
                    <h2 className="fs-3">{t(section.heading)}</h2>
                </div>
                <div className="accordion accordion-flush" id="tos-accordion">
                    {section.questions.map((question, index) => (
                        question.languageToShow.includes(getCurrentLanguageEnum(i18n.language))
                        && <FaqQuestion key={index} question={question} id={section.id} index={index} />
                    ))}
                </div>
            </div>
        </>
    )
}

export default FaqQuestionsSection;
