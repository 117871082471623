import SmallMainBanner from "../../components/building-online-page-components/small-main-banner/SmallMainBanner.tsx";
import LongImageBanner from "../../components/home-page-components/long-image-banner/LongImageBanner.tsx";
import image from "/assets/images/custom-solutions-page-images/long-banner-image-1.jpg";
import Advantages from "../../components/building-online-page-components/advantages/Advantages.tsx";
import {CustomSolutionsAdvantagesCardsConfig} from "../../configs/custom-solutions-page/customSolutionsAdvantagesCards.ts";
import GetStartedBanner from "../../components/home-page-components/get-started-banner/GetStartedBanner.tsx";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const CustomSolutionsPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.custom_solutions');
    }, []);

    return (
        <>
            <SmallMainBanner heading={'custom_payment_solutions_banner_heading'}
                             description={'custom_payment_solutions_banner_description'} />
            <LongImageBanner headingClass={'fs-5 mb-3 fw-semibold text-blue-dark'}
                             heading={'new_opportunities'}
                             descriptions={[
                                 'new_opportunities_description_1',
                                 'new_opportunities_description_2',
                                 'new_opportunities_description_3',
                             ]}
                             descriptionClass={'text-blue-dark'}
                             logo={image} />
            <Advantages className={'opy-bg-white pt-6 pb-6'}
                        heading={'collaborative_implementation'}
                        advantages={CustomSolutionsAdvantagesCardsConfig} />
            <GetStartedBanner bannerText={'get_consult_banner'}
                              linkTo={'routes.consult'}
                              linkClassname={'opy-button opy-button--white opy-button--medium text-center'}
                              linkText={'get_consult_text'} />
        </>
    )
}

export default CustomSolutionsPage;
