import {Card} from "../../@types/serviceCardsResources.tsx";
import serviceLogo1 from '/assets/images/home-page-images/icon-service-1.svg'
import serviceLogo2 from '/assets/images/home-page-images/icon-service-2.svg'
import serviceLogo3 from '/assets/images/home-page-images/icon-service-3.svg'
import serviceLogo4 from '/assets/images/home-page-images/icon-service-4.svg'

export const ServiceCardsConfig: Card[] = [
    {
        to: 'routes.demonstrations_bank_link_payments',
        logo: serviceLogo1,
        heading: 'payment_collection',
        text: 'payment_collection_description',
        buttonText: 'demonstration'
    },
    {
        to: 'routes.demonstrations_donations',
        logo: serviceLogo3,
        heading: 'donation_collection',
        text: 'donation_collection_description',
        buttonText: 'demonstration'
    },
    {
        to: 'routes.payment_methods_card_payments',
        logo: serviceLogo2,
        heading: 'one_click_payments',
        text: 'one_click_payments_description',
        buttonText: 'learn_more'
    },
    {
        to: 'routes.solutions_shopify',
        logo: serviceLogo4,
        heading: 'payment_solutions_shopify',
        text: 'payment_solutions_shopify_description',
        buttonText: 'learn_more'
    }
]
