import {Trans, useTranslation} from "react-i18next";
import {HashLink} from "react-router-hash-link";

const PricingShopifyBanner = () => {

    const {t} = useTranslation();

    return (
        <>
            <p className="pricing-shopify-banner">
                <Trans t={t} components={{
                    textOrange: <span className='text-orange' />,
                    pricingForm: <HashLink className='text-orange' to={`/${t('routes.pricing')}#pricing-form`} />,
                    contactEmail: <a style={{color: '#eee'}} href="mailto:info@opay.eu" />
                }}>
                    {'shopify_banner_text'}
                </Trans>

            </p>
        </>
    )
}

export default PricingShopifyBanner;
