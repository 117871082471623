import {PaymentMethod} from "../../../../../../@types/pricingCalculatorFieldsResources.tsx";
import {useTranslation} from "react-i18next";
import {buildRateString} from "../../../../../../helpers/pricing/calculatorHelper.ts";

const MobileModalTableRates = ({rates}: {rates: PaymentMethod}) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="fs-7 fw-semibold text-center text-blue-dark mb-3">{t('rates_in_example')}</div>
            <div className="container opy-block">
                <div className="mb-5">
                    <div className="text-white text-center fw-semibold py-2 px-3 rounded-top"
                         style={{backgroundColor: '#132748'}}>
                        {t('percentage_rate')}
                    </div>
                    <div className="py-3 text-center fs-5 border-start border-end" style={{color: '#FF9E0C'}}>
                        {rates.percentageRate} %
                    </div>
                    <div className="text-white text-center fw-semibold py-2 px-3" style={{backgroundColor: '#132748'}}>
                        {t('min_rate')}
                    </div>
                    <div className="py-3 text-center fs-5 border-start border-end" style={{color: '#FF5789'}}>
                        {buildRateString(rates.minRate)}
                    </div>
                    <div className="text-white text-center fw-semibold py-2 px-3" style={{backgroundColor: '#132748'}}>
                        {t('max_rate')}
                    </div>
                    <div className="py-3 text-center fs-5 border-start border-end" style={{color: '#09A563'}}>
                        {buildRateString(rates.maxRate)}
                    </div>
                    <div className="text-white text-center fw-semibold py-2 px-3" style={{backgroundColor: '#132748'}}>
                        {t('fixed_rate')}
                    </div>
                    <div className="py-3 text-center fs-5 border-start border-end border-bottom rounded-bottom"
                         style={{color: '#2C74E0'}}>
                        {rates.fixedRate} €
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileModalTableRates;
