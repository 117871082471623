import {Section} from "../../../@types/buildingOnlineBenefitsSectionsResources.tsx";
import {useTranslation} from "react-i18next";
import BenefitsSectionCardImageLeft from "./BenefitsSectionCardImageLeft.tsx";
import BenefitsSectionCardImageRight from "./BenefitsSectionCardImageRight.tsx";

const BenefitsSection = ({section}: {section: Section}) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="opy-bg-white opy-features pb-5">
                <div className="container opy-block">
                    <h2 className="fs-3 fs-lg-2 mb-5">{t(section.heading)}</h2>
                </div>
                {section.content.map((card, index) => (
                    card.imagePosition === 'left'
                        ? <BenefitsSectionCardImageLeft key={index} card={card} />
                        : <BenefitsSectionCardImageRight key={index} card={card} />
                ))}
            </div>
        </>
    )
}

export default BenefitsSection;
