import {FormInput} from "../../../@types/pricingFormInputsResources.ts";
import {useTranslation} from "react-i18next";
import {UseFormRegister} from "react-hook-form";
import {useState} from "react";

const PricingFormInputSingle = ({formInput, register}: { formInput: FormInput, register: UseFormRegister<any> }) => {

    const {t} = useTranslation();

    const [textareaLength, setTextareaLength] = useState(0);

    return (
        <>
            <div className={formInput.secondClass}>
                <div className="form-field">
                    <div className="form-label">
                        <label className="inline">
                            {t(formInput.label)} {formInput.required && '*'}
                        </label>
                    </div>
                    <div className="form-data">
                        <div className="form-input-wrapper ">
                            {formInput.isSelect
                                && <select className='form-select'
                                          required={formInput?.required}
                                          {...register(formInput.label)} defaultValue={''}>
                                    <option disabled={true} value={''}>{t('select')}</option>
                                    {formInput.selectOptions?.map((option, index) => (
                                        <option key={index} value={option.name}>{t(option.name)}</option>
                                    ))}
                                </select>}
                            {formInput.isTextarea
                                && <>
                                    <textarea className="col-12"
                                              rows={formInput?.rows}
                                              required={formInput?.required}
                                              maxLength={formInput?.maxLength}
                                              minLength={formInput?.minLength}
                                              {...register(formInput.label)}
                                              onChange={(e) => {
                                                  setTextareaLength(e.target.value.length);
                                                  e.target.focus()
                                              }}>
                                    </textarea>
                                    <p className="text-white">
                                        {formInput?.maxLength
                                            && `${formInput.maxLength - textareaLength}/${formInput.maxLength}`}
                                    </p>
                                </>
                            }
                            {!formInput.isSelect
                                && !formInput.isTextarea
                                && <input type={formInput.type}
                                          className="form-control"
                                          placeholder={formInput?.placeholder}
                                          pattern={formInput?.pattern}
                                          required={formInput?.required}
                                          maxLength={formInput?.maxLength}
                                          minLength={formInput?.minLength}
                                          {...register(formInput.label)}
                                          onChange={(e) => e.target.focus()}
                                />}
                        </div>
                    </div>
                </div>
                <div>
                    {formInput.description && <div>
                        <p className="font-weight-normal mt-2 text-white">{t(formInput.description)}</p>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default PricingFormInputSingle;
