import {Section} from "../../@types/buildingOnlineBenefitsSectionsResources.tsx";
import benefitImage1lt from '/assets/images/building-online-page-images/successful-payments-benefit-1-lt.svg';
import benefitImage2lt from '/assets/images/building-online-page-images/successful-payments-benefit-2-lt.svg';
import benefitImage3lt from '/assets/images/building-online-page-images/successful-payments-benefit-3-lt.svg';
import benefitImage1en from '/assets/images/building-online-page-images/successful-payments-benefit-1-en.jpg';
import benefitImage2en from '/assets/images/building-online-page-images/successful-payments-benefit-2-en.jpg';
import benefitImage3en from '/assets/images/building-online-page-images/successful-payments-benefit-3-en.jpg';
import benefitImage4 from '/assets/images/accelerating-online-page-images/transparent-pricing-benefit-1.jpg';

export const AcceleratingOnlineBenefitsSectionsConfig: Section[] = [
    {
        heading: 'successful_payments',
        content: [
            {
                image: {
                    lt: benefitImage1lt,
                    en: benefitImage1en
                },
                text: ['successful_payments_benefit_1'],
                textClass: 'py-6 fs-6',
                imagePosition: 'left'
            },
            {
                image: {
                    lt: benefitImage2lt,
                    en: benefitImage2en
                },
                text: ['successful_payments_benefit_2'],
                textClass: 'py-6 fs-6',
                imagePosition: 'right'
            },
            {
                image: {
                    lt: benefitImage3lt,
                    en: benefitImage3en
                },
                text: ['successful_payments_benefit_3'],
                textClass: 'py-6 fs-6',
                imagePosition: 'left'
            }
        ]
    },
    {
        heading: 'transparent_pricing',
        content: [
            {
                image: {
                    lt: benefitImage4,
                    en: benefitImage4
                },
                text: [
                    '01.',
                    'transparent_pricing_benefit_1_accelerating_online',
                    '02.',
                    'transparent_pricing_benefit_2_accelerating_online'
                ],
                textClass: null,
                imagePosition: 'left'
            }
        ]
    }
]
