import {PaymentMethod} from "../../../../../../@types/pricingCalculatorFieldsResources.tsx";
import {useTranslation} from "react-i18next";
import {buildRateString} from "../../../../../../helpers/pricing/calculatorHelper.ts";

const DesktopModalTableRates = ({rates}: {rates: PaymentMethod}) => {

    const {t} = useTranslation();

    return (
        <>
            <tr>
                <td colSpan={5}>
                    <div className=" text-center text-blue-dark mb-3 elmb">{t('rates_in_example')}</div>
                </td>
            </tr>
            <tr style={{height: '100px'}}>
                <td id="price-percentage" className="fs-7 text-center align-middle" style={{color: '#FF9E0C'}}>
                    {rates.percentageRate} %
                </td>
                <td id="price-min" className="fs-7 text-center align-middle" style={{color: '#FF5789'}}>
                    {buildRateString(rates.minRate)}
                </td>
                <td id="price-max"
                    className="fs-7 text-center align-middle position-relative"
                    style={{color: '#09A563'}}>
                    {buildRateString(rates.maxRate)}
                </td>
                <td id="price-fixed" className="fs-7 text-center align-middle" style={{color: '#2C74E0'}}>
                    {rates.fixedRate} €
                </td>
            </tr>
        </>
    )
}

export default DesktopModalTableRates;
