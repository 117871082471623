import {MenuSectionConfig} from "../../../configs/layout/headerMenuSection.ts";
import MenuSectionItem from "./MenuSectionItem.tsx";


const MenuSection = () => {
    return (
        <>
            <ul className="navbar-nav">
                {MenuSectionConfig.map((item, index) => (
                    <MenuSectionItem action={item.action} name={item.name} key={index} />
                ))}
            </ul>
        </>
    )
}

export default MenuSection;
