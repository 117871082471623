import AdvantageCard from "./AdvantageCard.tsx";
import {Card} from "../../../@types/buildingOnlineAdvantagesCardsResources.tsx";
import {useTranslation} from "react-i18next";

const Advantages = (
    {className, heading, advantages}:
        {className: string, heading: string|null, advantages: Card[]}
) => {

    const {t} = useTranslation();

    return (
        <>
            <div className={className}>
                <div className="container opy-block">
                    {heading && <h2 className="fs-4 fs-md-3 mb-5">{t(heading)}</h2>}
                    <div className="fw-medium fs-8">
                        <div className="row">
                            {advantages.map((card, index) => (
                                <AdvantageCard key={index} card={card} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Advantages;
