import {Banner} from "../../@types/homePageTwoColumnsDynamicBannersResources.tsx";
import bannerImage1lt from '/assets/images/solutions-shopify-page/opay-shopify-2.svg';
import bannerImage1en from '/assets/images/solutions-shopify-page/opay-shopify-2-en.svg';
import bannerImage2 from '/assets/images/solutions-shopify-page/opay-shopify-3.svg';
import bannerImage3 from '/assets/images/solutions-shopify-page/opay-shopify-4.svg';

export const SolutionsShopifyBannersConfig: Banner[] = [
    {
        heading: 'higher_conversion',
        text: 'higher_conversion_description',
        image: {
            lt: bannerImage1lt,
            en: bannerImage1en
        },
        imagePosition: 'left'
    },
    {
        heading: 'easier_refunds',
        text: 'easier_refunds_description',
        image: {
            lt: bannerImage2,
            en: bannerImage2
        },
        imagePosition: 'right'
    },
    {
        heading: 'simplified_setup',
        text: 'simplified_setup_description',
        image: {
            lt: bannerImage3,
            en: bannerImage3
        },
        imagePosition: 'left'
    }
]
