import Logo from "../../logo/Logo.tsx";
import Copyright from "../copyright/Copyright.tsx";
import FooterContacts from "../footer-contacts/FooterContacts.tsx";
import FooterSolutionsSection from "../footer-solutions-section/FooterSolutionsSection.tsx";
import FooterUsefulSection from "../footer-useful-section/FooterUsefulSection.tsx";
import FooterReferencesSection from "../footer-references-section/FooterReferencesSection.tsx";

const Footer = () => {
    return (
        <>
            <div className="opy-bg-white pt-6 pb-6 border-top">
                <div className="opy-footer">
                    <div className="container opy-block mb-5">
                        <Logo className={'logo mb-5'} />
                    </div>
                    <div className="container opy-block">
                        <div className="row">
                            <FooterContacts />
                            <FooterSolutionsSection />
                            <FooterUsefulSection />
                            <FooterReferencesSection />
                        </div>
                    </div>
                </div>
            </div>
            <Copyright />
        </>
    )
}

export default Footer;
