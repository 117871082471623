import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import NotFoundPage from "../pages/not-found-page/NotFoundPage.tsx";
import i18n from "i18next";
import * as routes from "../../routes.json";
import {isRouteExist} from "../helpers/routes/routesHelper.ts";
import Header from "../components/header-components/header/Header.tsx";
import Footer from "../components/footer-components/footer/Footer.tsx";

const LegacyRedirect = () => {
    const opayEuRedirects: any = {
        '/kas-yra-opay/': '/lt/kas-yra-opay',
        '/ypatybes/': '/lt/kas-yra-opay',
        '/ypatybes/saugumas-ir-pasiekiamumas/': '/lt/kas-yra-opay',
        '/ypatybes/isvaizda-ir-patogumas-naudoti/': '/lt/kas-yra-opay',
        '/ypatybes/skatinkite-pardavimus/': '/lt/kas-yra-opay',
        '/ypatybes/integruokite-patogiai-ir-paprastai/': '/lt/kas-yra-opay',
        '/ypatybes/taupykite-laika-ir-pinigus/': '/lt/kas-yra-opay',
        '/paslaugos-kaina/': '/lt/ikainiai',
        '/ypatingas-pasiulymas/': '/lt/ikainiai',
        '/mokejimo-budai/': '/lt/mokejimo-budai',
        '/mokejimo-budai/mokejimas-mobiliaja-pinigine/': '/lt/mokejimo-budai',
        '/mokejimo-budai/mokejimo-inicijavimo-paslauga/': '/lt/mokejimo-budai/mokejimo-inicijavimas',
        '/mokejimo-budai/internetine-bankininkyste-bank-link/': '/lt/mokejimo-budai/internetine-bankininkyste',
        '/mokejimo-budai/mokejimo-kortele/': '/lt/mokejimo-budai/mokejimo-korteles',
        '/mokejimo-demonstracija/': '/lt/demonstracijos/banklink-mokejimai',
        '/mokejimo-demonstracija/el-parduotuves-atsiskaitymo-lange/': '/lt/demonstracijos/banklink-mokejimai',
        '/mokejimo-demonstracija/opay-mokejimo-lange/': '/lt/demonstracijos/banklink-mokejimai',
        '/pasiulymai/mip-demo/': '/lt/demonstracijos/mip-mokejimai',
        '/pradeti-naudotis/': '/lt/pradeti-naudotis/priimti-mokejimus',
        '/pradeti-naudotis/isidiegti-ir-priimti-apmokejimus/': '/lt/pradeti-naudotis/priimti-mokejimus',
        '/pradeti-naudotis/isidiegti-tik-testavimui/': '/lt/pradeti-naudotis/isidiegti-testavimui',
        '/registruokites-konsultacijai/': '/lt/registruotis-konsultacijai',
        '/programuotoju-registracija/': '/lt/registruotis-konsultacijai',
        '/pagalba/': '/lt/registruotis-konsultacijai',
        '/pagalba/susisiekite-su-mumis/': '/lt/registruotis-konsultacijai',
        '/mokejimas-priimtas/': '/lt/mokejimas-sekmingas',
        '/pradeti-naudotis/dekojame/': '/lt/duomenis-gavome',
        '/kontaktai-ir-rekvizitai/': '/lt/rekvizitai',
        '/duomenu-tvarkymo-politika/': '/lt/teisine-informacija/privatumo-politika',
        '/opay-slapuku-aprasymas/': '/lt/teisine-informacija/slapuku-politika',
        '/informacijos-tvarkymo-salygos/': '/lt/teisine-informacija/kliento-sutikimas',
        '/privacy-policy/': '/en/legal-information/privacy-policy',
        '/mokejimo-paslaugu-sutarciu-archyvas/mokejimo-paslaugu-sutarties-bendroji-dalis/': '/lt/teisine-informacija/bendrosios-sutarties-salygos',
        '/mokejimo-paslaugu-sutarciu-archyvas/mokejimo-paslaugu-sutarties-bendroji-dalis-2019-09-05/': '/lt/archyvas/bendrosios-sutarties-salygos',
        '/mokejimo-paslaugu-sutarciu-archyvas/': '/lt/teisine-informacija/bendrosios-sutarties-salygos',
        '/mokejimo-paslaugu-sutarciu-archyvas/mokejimo-paslaugu-sutarties-bendroji-dalis-2019-04-26/': '/lt/teisine-informacija/bendrosios-sutarties-salygos',
        '/mokejimo-paslaugu-sutarciu-archyvas/mokejimo-paslaugu-sutarties-bendroji-dalis-2018-06-07/': '/lt/teisine-informacija/bendrosios-sutarties-salygos',
        '/mokejimo-paslaugu-sutarciu-archyvas/mokejimo-paslaugu-sutarties-bendroji-dalis-20160913/': '/lt/teisine-informacija/bendrosios-sutarties-salygos',
        '/mokejimo-paslaugu-sutarciu-archyvas/mokejimo-paslaugu-sutarties-bendroji-dalis-20141010/': '/lt/teisine-informacija/bendrosios-sutarties-salygos',
        '/mokjimo-paslaugu-sutarties-bendroji-dalis-20200730istrint/': '/lt/teisine-informacija/bendrosios-sutarties-salygos',
        '/pasirasymas-mobiliu-ar-elektroniniu-parasu/': '/lt/teisine-informacija/pasirasymas-e-parasu',
        '/kvalifikuotas-elektroninis-parasas/': '/lt/teisine-informacija/pasirasymas-e-parasu',
        '/diegimas/plugin': '/lt/diegimas'
    }

    const opayDevRedirects: any = {
        '/iskiepiai/': '/plugins',
        '/iskiepiai/eshoprent-com/': '/plugins/eshoprent',
        '/iskiepiai/verskis-lt/': '/plugins/verskis',
        '/iskiepiai/shopify/': '/plugins/shopify',
        '/en/iskiepiai/shopify-en/': '/plugins/shopify',
        '/iskiepiai/joomla-virtuemart-iskiepis/': '/plugins/joomla-virtuemart',
        '/iskiepiai/wordpress-woocommerce-iskiepis/': '/plugins/wordpress-woocommerce',
        '/iskiepiai/prestashop-iskiepis/': '/plugins/prestashop',
        '/iskiepiai/opencart-iskiepis/': '/plugins/opencart',
        '/iskiepiai/magento-iskiepis/': '/plugins/magento',
        '/technine-informacija/': '/docs/v1#tag/Introduction',
        '/technine-informacija/naudojamos-savokos/': '/docs/v1#tag/Introduction',
        '/technine-informacija/naudojamos-savokos/sisteminiai-atsiskaitymo-budu-pavadinimai/': '/docs/v1#tag/Introduction',
        '/technine-informacija/naudojamos-savokos/pirkejo-nukreipimas-ir-pranesimai-tarp-serveriu/': '/docs/v1#tag/Introduction',
        '/technine-informacija/opay-web-api/': '/docs/v1#tag/Introduction',
        '/technine-informacija/opay-web-api/skaitmeninis-duomenu-pasirasymas/asimetrinis-pasirasymas-ir-paraso-tikrinimas/': '/docs/v1#tag/Introduction',
        '/technine-informacija/opay-web-api/skaitmeninis-duomenu-pasirasymas/simetrinis-pasirasymas-ir-paraso-tikrinimas/': '/docs/v1#tag/Introduction',
        '/technine-informacija/opay-web-api/skaitmeninis-duomenu-pasirasymas/parametru-apjungimo-i-vientisa-eilute-tvarka-pasirasymo-paraso-tikrinimo-eilute/': '/docs/v1#tag/Introduction',
        '/technine-informacija/opay-web-api/skaitmeninis-duomenu-pasirasymas/': '/docs/v1#tag/Introduction',
        '/technine-informacija/opay-web-api/duomenu-kodavimas-siekiant-isvengti-galimu-iskraipymu/': '/docs/v1#tag/Introduction',
        '/technine-informacija/opay-web-api/minimalaus-laiko-skirto-mokejimui-atlikti-lentele/': '/docs/v1#tag/Payments/operation/CreatePayment',
        '/technine-informacija/opay-web-api/parametras-time_limit/': '/docs/v1#tag/Payments/operation/CreatePayment',
        '/technine-informacija/opay-web-api/parametrai-show_channels-ir-hide_channels/': '/docs/v1#tag/Payments/operation/CreatePayment',
        '/technine-informacija/opay-web-api/pardavejo-gaunami-duomenys/': '/docs/v1#tag/Payment-Callback/operation/PaymentCallback',
        '/technine-informacija/opay-web-api/pardavejo-siunciami-duomenys/': '/docs/v1#tag/Payments/operation/CreatePayment',
        '/technine-informacija/opay-web-api/bendra-taisykle-privalomais-pazymetiems-parametrams/': '/docs/v1#tag/Payments/operation/CreatePayment',
        '/technine-informacija/opay-web-api/bendra-taisykle-neprivalomais-pazymetiems-parametrams/': '/docs/v1#tag/Payments/operation/CreatePayment',
        '/technine-informacija/opay-php-biblioteka/': '/docs/integracija/php-biblioteka',
        '/technine-informacija/opay-php-biblioteka/paveiksleliu-su-banku-logotipais-gavimas/': '/docs/integracija/php-biblioteka#logotipu-gavimas',
        '/technine-informacija/opay-php-biblioteka/atsakymo-apie-apmokejima-priemimas/': '/docs/integracija/php-biblioteka#atsakymo-priemimas',
        '/technine-informacija/opay-php-biblioteka/pirkejo-nukreipimas-tiesiai-i-banka/': '/docs/integracija/php-biblioteka#nukreipimas-i-banka',
        '/technine-informacija/opay-php-biblioteka/pirkejo-nukreipimas-i-opay-atsiskaitymo-puslapi/': '/docs/integracija/php-biblioteka#nukreipiamas-i-opay-puslapi',
        '/technine-informacija/opay-php-biblioteka/bibliotekos-api/': '/docs/integracija/php-biblioteka#bibliotekos-api',
        '/technine-informacija/opay-php-biblioteka/bibliotekos-failu-stuktura/': '/docs/integracija/php-biblioteka#failu-struktura',
        '/technine-informacija/opay-php-biblioteka/bendri-naudojimosi-biblioteka-principai/': '/docs/integracija/php-biblioteka#bendri-principai'
    }

    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        i18n.language = 'lt';

        if (opayEuRedirects[`${currentPath}/`]) {
            window.location.href = opayEuRedirects[`${currentPath}/`];
        }

        if (opayEuRedirects[currentPath]) {
            window.location.href = opayEuRedirects[currentPath];
        }

        if (opayDevRedirects[`${currentPath}/`]) {
            window.location.href = `https://opay.dev${opayDevRedirects[`${currentPath}/`]}`;
        }

        if (opayDevRedirects[currentPath]) {
            window.location.href = `https://opay.dev${opayDevRedirects[currentPath]}`;
        }
    }, [location]);

    return (
        <>
            {isRouteExist(routes, location.pathname.substring(1))
                ? null
                : <>
                    <Header />
                    <NotFoundPage />
                    <Footer />
                </>}
        </>
    )
}

export default LegacyRedirect;
