import { MenuSubItem } from "../../../@types/menuResources.tsx";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const MenuSectionSubItemList = ({ items }: { items: MenuSubItem[] }) => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <ul className="dropdown-menu">
                {items.map((link, index) => (
                    <li key={index} className="nav-item me-3 fw-semibold ">
                        <Link to={i18n.language === 'lt' ? `/${t(link.to.lt)}` : `/${t(link.to.en)}`}
                              className={"nav-link dropdown-item " + (link.className || "")} >
                            <span>{t(link.text)}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default MenuSectionSubItemList;
