import {useTranslation} from "react-i18next";
import icon from "/assets/images/integration-page-images/icon-arrow-right-orange.svg";

const DevelopersBanner = (
    {heading, paragraph, buttonText, link, image}
        : {heading: string, paragraph: string, buttonText:string, link: string, image: string}
) => {

    const { t } = useTranslation();

    return (
        <>
            <div id={t('developers_banner_id')}></div>
            <div className="opy-bg-white pt-4 pb-6">
                <div className="container opy-block">
                    <div className="row py-5">
                        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                            <h1 className="fs-3 mb-4">{t(heading)}</h1>
                            <p className="w-sm-75 fs-8 text-muted mb-5">{t(paragraph)}</p>
                            <div className="mb-4">
                            <a className="fw-semibold" href={link} >
                                <span className="pe-2">
                                    <img src={icon} alt='button icon'/>
                                </span>
                                <span>{t(buttonText)}</span>
                            </a>
                            </div>
                            <div className="pe-2 mb-4 mb-sm-6">
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 mb-5">
                            <img className="img-fluid" src={image} alt='banner visualization'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DevelopersBanner;
