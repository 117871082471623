import {FormInput} from "../../@types/pricingFormInputsResources.ts";
import {RegexPatternEnum} from "../../enum/pattern/regexPatternEnum.ts";

export const PricingFormInputsConfig: FormInput[] = [
    {
        firstClass: 'form-column row',
        secondClass: 'form-column col-12 mb-4',
        label: 'payments_per_month',
        required: true,
        type: 'number',
    },
    {
        firstClass: 'form-column row',
        secondClass: 'form-column col-12 mb-4',
        label: 'average_basket',
        required: true,
        type: 'number',
    },
    {
        firstClass: 'form-column row',
        secondClass: 'form-column col-12 mb-4',
        label: 'website_address',
        required: true,
        type: 'text',
    },
    {
        firstClass: 'form-column row',
        secondClass: 'form-column col-12 mb-4',
        label: 'website_content_management_system',
        required: true,
        isSelect: true,
        selectOptions: [
            {name: 'Shopify'},
            {name: 'WordPress- WooCommerce'},
            {name: 'Prestashop'},
            {name: 'OpenCart'},
            {name: 'Magento'},
            {name: 'Joomla- VirtueMart'},
            {name: 'Eshoprent'},
            {name: 'Verskis'},
            {name: 'Hostpartner'},
            {name: 'LeShopo'},
            {name: 'other'},
        ]
    },
    {
        firstClass: 'form-column row',
        secondClass: 'form-column col-12 mb-4',
        label: 'email',
        required: true,
        type: 'email',
        pattern: RegexPatternEnum.email,
    },
    {
        firstClass: 'form-column row mb-4',
        secondClass: 'form-column col-md-6 mb-4',
        label: 'phone_number',
        required: true,
        type: 'tel',
        pattern: '^[0-9\\-\\+\\s\\(\\)]*$',
        placeholder: '+370 65912387',
        secondInput: {
            firstClass: 'form-column col-md-6 mb-4',
            secondClass: 'form-column col-md-6 mb-4',
            label: 'how_did_you_hear_about_us',
            required: true,
            isSelect: true,
            selectOptions: [
                {name: 'Shopify Meetup Lithuania'},
                {name: 'Google'},
                {name: 'Facebook'},
                {name: 'LinkedIn'},
                {name: 'developer_recommendation'},
                {name: 'other_person_recommendation'},
                {name: 'other'},
            ]
        }
    },
]
