export const cleanUpWebsiteAddress = (websiteAddress: string) => {
    return websiteAddress.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
};

export const handleEmptyValues = (values: any) => {
    const newValues = { ...values };
    Object.keys(newValues).forEach((key) => {
        if (newValues[key] === '') {
            newValues[key] = null;
        }
    });
    return newValues;
};
