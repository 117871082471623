import {useTranslation} from "react-i18next";

const SmallMainBanner = ({heading, description}: {heading: string, description: string}) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="opy-bg-white py-5 py-md-6">
                <div className="container opy-block">
                    <div className="py-5">
                        <h1 className="fs-4 fs-md-3 mw-md-75">{t(heading)}</h1>
                        <p className="mw-md-50">{t(description)}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SmallMainBanner;
