import {Trans, useTranslation} from "react-i18next";

const PricingFormBanner = ({heading, description}: {heading: string, description: string}) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="col-lg-6 position-relative bg-black d-flex align-items-center justify-content-center form-description">
                <div className="position-absolute top-0 left-0 w-100 h-100 pricing-banner-texture"></div>
                <div className="position-absolute d-none d-lg-block pricing-banner-texture-left"></div>
                <div className="position-absolute d-none d-md-block pricing-banner-texture-right"></div>
                <div className="position-relative fs-md-4 py-5 px-md-5 text-white">
                    <Trans t={t} components={{
                        paragraph: <p />,
                        paragraphSmallerFont: <p className="fs-6" />,
                        textOrange: <span className="text-orange" />,
                    }}>
                        {t(heading)}
                        <br></br>
                        {t(description)}
                    </Trans>
                </div>
            </div>
        </>
    )
}

export default PricingFormBanner;
