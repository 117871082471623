import {useTranslation} from "react-i18next";
import Requisites from "./Requisites.tsx";
import Contacts from "./Contacts.tsx";

const RequisitesAndContacts = () => {

    const {t} = useTranslation();

    return (
        <>
            <div className="opy-bg-white py-5 py-lg-6">
                <div className="container opy-block">
                    <div className="py-5">
                        <div className="mb-5">
                            <h1 className="fs-3 fs-md-2">{t('requisites_and_contacts')}</h1>
                        </div>
                        <div className="d-flex flex-wrap flex-md-nowrap">
                            <Requisites />
                            <Contacts />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequisitesAndContacts;
