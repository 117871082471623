import SmallMainBanner from "../../components/building-online-page-components/small-main-banner/SmallMainBanner.tsx";
import {useTranslation} from "react-i18next";
import LongImageBanner from "../../components/home-page-components/long-image-banner/LongImageBanner.tsx";
import image from "/assets/images/building-online-page-images/long-banner-image-1.jpg";
import Advantages from "../../components/building-online-page-components/advantages/Advantages.tsx";
import Benefits from "../../components/building-online-page-components/benefits/Benefits.tsx";
import GetStartedBanner from "../../components/home-page-components/get-started-banner/GetStartedBanner.tsx";
import {BuildingOnlineAdvantagesCardsConfig} from "../../configs/building-online-page/buildingOnlineAdvantagesCards.ts";
import {BuildingOnlineBenefitsSectionsConfig} from "../../configs/building-online-page/buildingOnlineBenefitsSections.ts";
import {useEffect} from "react";

const BuildingOnlinePage = () => {

    const { t, i18n } = useTranslation();

    useEffect(() => {
        document.title = t('titles.building_online');
    }, []);

    return (
        <>
            <SmallMainBanner heading={'building_online_business_banner_heading'}
                             description={'building_online_business_banner_description'} />
            <LongImageBanner headingClass={i18n.language === 'lt'
                ? 'fs-5 mb-3 fw-semibold text-blue-dark'
                : 'fs-5 mb-3 fw-semibold text-blue-dark margin-top-minus-5' }
                             heading={'powerful_payment_solutions'}
                             descriptions={[
                                 'powerful_payment_solutions_description_1',
                                 'powerful_payment_solutions_description_2',
                                 'powerful_payment_solutions_description_3',
                             ]}
                             descriptionClass={'text-blue-dark'}
                             logo={image} />
            <Advantages className={'opy-bg-white pt-6 pb-6'}
                        heading={'our_advantages'}
                        advantages={BuildingOnlineAdvantagesCardsConfig} />
            <Benefits benefits={BuildingOnlineBenefitsSectionsConfig} />
            <GetStartedBanner bannerText={'get_consult_banner'}
                              linkTo={'routes.consult'}
                              linkClassname={'opy-button opy-button--white opy-button--medium text-center'}
                              linkText={'get_consult_text'} />
        </>
    )
}

export default BuildingOnlinePage;
