import {Trans, useTranslation} from "react-i18next";
import {HashLink} from "react-router-hash-link";

const StartUsingDescription = ({description1, description2}: {description1: string, description2: string}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="container opy-block">
                <div className="mb-5 mb-md-6">
                    <Trans t={t} components={{
                        plugin: <HashLink style={{textDecoration: 'underline'}}
                                      to={`/${t('routes.integration')}#${t('plugins_id')}`} />,
                        techDocs: <HashLink style={{textDecoration: 'underline'}}
                                        to={`/${t('routes.integration')}#${t('for_developers_id')}`} />,
                    }}>
                        <p>{t(description1)}</p>
                        <p>{t(description2)}</p>
                    </Trans>
                </div>
            </div>
        </>
    )
}

export default StartUsingDescription;
