import {Section} from "../../../@types/demoPaymentMethodsResources.ts";
import PaymentMethodsSingleChannel from "./PaymentMethodsSingleChannel.tsx";

const PaymentMethodsSection = (
    {section, setSelectedChannel}:
        {section: Section, setSelectedChannel: (arg0: string) => void}
) => {
    return (
        <>
            <div className="text-center text-md-start fs-6 mb-4 text-blue-dark fw-semibold">{section.group_title}</div>
            <div className="row mb-4">
                {Object.values(section.channels).map((channel, index) => (
                    <PaymentMethodsSingleChannel key={index} channel={channel} setSelectedChannel={setSelectedChannel}/>
                ))}
            </div>
        </>
    )
}

export default PaymentMethodsSection;
