import $ from "jquery";

export const makeSlick = (slidesToShow: number, sliderRef: HTMLDivElement) => {
    $(sliderRef).slick({
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        dots: true,
    });
};

export const getSlidesCount = () => {
    return window.innerWidth >= 991 ? 2 : 1;
};

export const handleNextButtonClick = (sliderRef: HTMLDivElement) => {
    $(sliderRef).slick('slickNext');
};

export const handlePrevButtonClick = (sliderRef: HTMLDivElement) => {
    $(sliderRef).slick('slickPrev');
};

export const handleResize = (sliderRef: HTMLDivElement) => {
    $(sliderRef).slick('unslick');
    makeSlick(getSlidesCount(), sliderRef);
};
