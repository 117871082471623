import {Card} from "../../../@types/buildingOnlineBenefitsSectionsResources.tsx";
import {useTranslation} from "react-i18next";

const benefitsSectionCardImageLeft = ({card}: {card: Card}) => {

    const {t, i18n} = useTranslation();

    return (
        <>
            <div className="container opy-block">
                <div className="row mb-lg-5 px-3 mb-3">
                    <div className="d-block d-lg-none px-0">
                        <img className="w-100"
                             src={i18n.language === 'lt' ? card.image.lt : card.image.en}
                             alt='benefit visualization'/>
                    </div>
                    <div className="col-lg-6 d-none d-lg-block">
                        <img className="w-100"
                             src={i18n.language === 'lt' ? card.image.lt : card.image.en}
                             alt='benefit visualization'/>
                    </div>
                    <div className="col-lg-6 px-lg-4 px-xl-6 d-flex align-items-center">
                        <div className="fs-6 text-blue-dark">
                            {card.text.map((text, index) => (
                                <p key={index}
                                   className={card.textClass === null ? '' : card.textClass}>
                                    {t(text)}
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default benefitsSectionCardImageLeft;
