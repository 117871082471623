import {Section} from "../../@types/generalTermsUpdatesSectionResources.tsx";

export const GeneralTermsArchiveUpdatesSectionConfig: Section = {
    heading1: 'general_terms_updates_section_heading_1',
    heading2: 'general_terms_archive_updates_section_heading_2',
    heading3: 'general_terms_archive_updates_section_heading_3',
    button: {
        lt: {
            text: 'latest_redaction',
            link: 'routes.general_terms'
        },
        en: {
            text: 'latest_redaction',
            link: 'routes.general_terms'
        }
    }
}
