import {Section} from "../../@types/privacyPolicySectionResources.tsx";
import {Language} from "../../@types/generics.tsx";

export const GeneralTermsPageSectionsConfig: Section[] = [
    {
        heading: 'definitions_heading',
        paragraphs: [
            {
                text: 'definitions_paragraph_1'
            },
            {
                text: 'definitions_paragraph_2'
            },
            {
                text: 'definitions_paragraph_3'
            },
            {
                text: 'definitions_paragraph_4'
            },
            {
                text: 'definitions_paragraph_5'
            },
            {
                text: 'definitions_paragraph_6'
            },
            {
                text: 'definitions_paragraph_7'
            },
            {
                text: 'definitions_paragraph_8'
            },
            {
                text: 'definitions_paragraph_9'
            },
            {
                text: 'definitions_paragraph_10'
            },
            {
                text: 'definitions_paragraph_11'
            },
            {
                text: 'definitions_paragraph_12'
            },
            {
                text: 'definitions_paragraph_13'
            },
            {
                text: 'definitions_paragraph_14'
            },
            {
                text: 'definitions_paragraph_15'
            },
            {
                text: 'definitions_paragraph_16'
            },
            {
                text: 'definitions_paragraph_17'
            },
            {
                text: 'definitions_paragraph_18'
            },
            {
                text: 'definitions_paragraph_19'
            },
            {
                text: 'definitions_paragraph_20'
            },
            {
                text: 'definitions_paragraph_21'
            },
            {
                text: 'definitions_paragraph_22'
            },
            {
                text: 'definitions_paragraph_23'
            },
            {
                text: 'definitions_paragraph_24'
            },
            {
                text: 'definitions_paragraph_25'
            },
            {
                text: 'definitions_paragraph_26'
            },
            {
                text: 'definitions_paragraph_27'
            },
            {
                text: 'definitions_paragraph_28'
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'subject_of_agreement_heading',
        paragraphs: [
            {
                text: 'subject_of_agreement_paragraph_1'
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'rights_and_obligations_heading',
        paragraphs: [
            {
                text: 'rights_and_obligations_paragraph_1'
            },
            {
                text: 'rights_and_obligations_paragraph_2'
            },
            {
                text: 'rights_and_obligations_paragraph_3'
            },
            {
                text: 'rights_and_obligations_paragraph_4'
            },
            {
                text: 'rights_and_obligations_paragraph_5'
            },
            {
                text: 'rights_and_obligations_paragraph_6'
            },
            {
                text: 'rights_and_obligations_paragraph_7'
            },
            {
                text: 'rights_and_obligations_paragraph_8'
            },
            {
                text: 'rights_and_obligations_paragraph_9'
            },
            {
                text: 'rights_and_obligations_paragraph_10'
            },
            {
                text: 'rights_and_obligations_paragraph_11'
            },
            {
                text: 'rights_and_obligations_paragraph_12'
            },
            {
                text: 'rights_and_obligations_paragraph_13'
            },
            {
                text: 'rights_and_obligations_paragraph_14'
            },
            {
                text: 'rights_and_obligations_paragraph_15'
            },
            {
                text: 'rights_and_obligations_paragraph_16'
            },
            {
                text: 'rights_and_obligations_paragraph_17'
            },
            {
                text: 'rights_and_obligations_paragraph_18'
            },
            {
                text: 'rights_and_obligations_paragraph_19'
            },
            {
                text: 'rights_and_obligations_paragraph_20'
            },
            {
                text: 'rights_and_obligations_paragraph_21'
            },
            {
                text: 'rights_and_obligations_paragraph_22'
            },
            {
                text: 'rights_and_obligations_paragraph_23'
            },
            {
                text: 'rights_and_obligations_paragraph_24'
            },
            {
                text: 'rights_and_obligations_paragraph_25'
            },
            {
                text: 'rights_and_obligations_paragraph_26'
            },
            {
                text: 'rights_and_obligations_paragraph_27'
            },
            {
                text: 'rights_and_obligations_paragraph_28'
            },
            {
                text: 'rights_and_obligations_paragraph_29'
            },
            {
                text: 'rights_and_obligations_paragraph_30'
            },
            {
                text: 'rights_and_obligations_paragraph_31'
            },
            {
                text: 'rights_and_obligations_paragraph_32'
            },
            {
                text: 'rights_and_obligations_paragraph_33'
            },
            {
                text: 'rights_and_obligations_paragraph_34'
            },
            {
                text: 'rights_and_obligations_paragraph_35'
            },
            {
                text: 'rights_and_obligations_paragraph_36'
            },
            {
                text: 'rights_and_obligations_paragraph_37'
            },
            {
                text: 'rights_and_obligations_paragraph_38'
            },
            {
                text: 'rights_and_obligations_paragraph_39'
            },
            {
                text: 'rights_and_obligations_paragraph_40'
            },
            {
                text: 'rights_and_obligations_paragraph_41'
            },
            {
                text: 'rights_and_obligations_paragraph_42'
            },
            {
                text: 'rights_and_obligations_paragraph_43'
            },
            {
                text: 'rights_and_obligations_paragraph_44'
            },
            {
                text: 'rights_and_obligations_paragraph_45'
            },
            {
                text: 'rights_and_obligations_paragraph_46'
            },
            {
                text: 'rights_and_obligations_paragraph_47'
            },
            {
                text: 'rights_and_obligations_paragraph_48'
            },
            {
                text: 'rights_and_obligations_paragraph_49'
            },
            {
                text: 'rights_and_obligations_paragraph_50'
            },
            {
                text: 'rights_and_obligations_paragraph_51'
            },
            {
                text: 'rights_and_obligations_paragraph_52'
            },
            {
                text: 'rights_and_obligations_paragraph_53'
            },
            {
                text: 'rights_and_obligations_paragraph_54'
            },
            {
                text: 'rights_and_obligations_paragraph_55'
            },
            {
                text: 'rights_and_obligations_paragraph_56'
            },
            {
                text: 'rights_and_obligations_paragraph_57'
            },
            {
                text: 'rights_and_obligations_paragraph_58'
            },
            {
                text: 'rights_and_obligations_paragraph_59'
            },
            {
                text: 'rights_and_obligations_paragraph_60'
            },
            {
                text: 'rights_and_obligations_paragraph_61'
            },
            {
                text: 'rights_and_obligations_paragraph_62'
            },
            {
                text: 'rights_and_obligations_paragraph_63'
            },
            {
                text: 'rights_and_obligations_paragraph_64'
            },
            {
                text: 'rights_and_obligations_paragraph_65'
            },
            {
                text: 'rights_and_obligations_paragraph_66'
            },
            {
                text: 'rights_and_obligations_paragraph_67'
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'self_service_heading',
        paragraphs: [
            {
                text: 'self_service_paragraph_1'
            },
            {
                text: 'self_service_paragraph_2'
            },
            {
                text: 'self_service_paragraph_3'
            },
            {
                text: 'self_service_paragraph_4'
            },
            {
                text: 'self_service_paragraph_5'
            },
          ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'digital_signature_heading',
        paragraphs:[
            {
                text: 'digital_signature_paragraph_1'
            },
            {
                text: 'digital_signature_paragraph_2'
            },
            {
                text: 'digital_signature_paragraph_3'
            },
            {
                text: 'digital_signature_paragraph_4'
            },
            {
                text: 'digital_signature_paragraph_5'
            },
            {
                text: 'digital_signature_paragraph_6'
            },
            {
                text: 'digital_signature_paragraph_7'
            },
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'taxes_heading',
        paragraphs:[
            {
                text: 'taxes_paragraph_1'
            },
            {
                text: 'taxes_paragraph_2'
            },
            {
                text: 'taxes_paragraph_3'
            },
            {
                text: 'taxes_paragraph_4'
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'deposit_heading',
        paragraphs:[
            {
                text: 'deposit_paragraph_1'
            },
            {
                text: 'deposit_paragraph_2'
            },
            {
                text: 'deposit_paragraph_3'
            },
            {
                text: 'deposit_paragraph_4'
            },
            {
                text: 'deposit_paragraph_5'
            },
            {
                text: 'deposit_paragraph_6'
            },
            {
                text: 'deposit_paragraph_7'
            },
            {
                text: 'deposit_paragraph_8'
            },
            {
                text: 'deposit_paragraph_9'
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'errors_heading',
        paragraphs: [
            {
                text: 'errors_paragraph_1'
            },
            {
                text: 'errors_paragraph_2'
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'operation_cancellation_heading',
        paragraphs: [
            {
                text: 'operation_cancellation_paragraph_1'
            },
            {
                text: 'operation_cancellation_paragraph_2'
            },
            {
                text: 'operation_cancellation_paragraph_3'
            },
            {
                text: 'operation_cancellation_paragraph_4'
            },
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'disputes_heading',
        paragraphs: [
            {
                text: 'disputes_paragraph_1'
            },
            {
                text: 'disputes_paragraph_2'
            },
            {
                text: 'disputes_paragraph_3'
            },
            {
                text: 'disputes_paragraph_4'
            },
            {
                text: 'disputes_paragraph_5'
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'confidentiality_heading',
        paragraphs: [
            {
                text: 'confidentiality_paragraph_1'
            },
            {
                text: 'confidentiality_paragraph_2'
            },
            {
                text: 'confidentiality_paragraph_3'
            },
            {
                text: 'confidentiality_paragraph_4'
            },
            {
                text: 'confidentiality_paragraph_5'
            },
            {
                text: 'confidentiality_paragraph_6'
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'personal_data_heading',
        paragraphs: [
            {
                text: 'personal_data_paragraph_1'
            },
            {
                text: 'personal_data_paragraph_2'
            },
            {
                text: 'personal_data_paragraph_3'
            },
            {
                text: 'personal_data_paragraph_4'
            },
            {
                text: 'personal_data_paragraph_5'
            },
            {
                text: 'personal_data_paragraph_6'
            },
            {
                text: 'personal_data_paragraph_7'
            },
            {
                text: 'personal_data_paragraph_8'
            },
            {
                text: 'personal_data_paragraph_9'
            },
            {
                text: 'personal_data_paragraph_10'
            },
            {
                text: 'personal_data_paragraph_11'
            },
            {
                text: 'personal_data_paragraph_12'
            },
            {
                text: 'personal_data_paragraph_13'
            },
            {
                text: 'personal_data_paragraph_14'
            },
            {
                text: 'personal_data_paragraph_15'
            },
            {
                text: 'personal_data_paragraph_16'
            },
            {
                text: 'personal_data_paragraph_17'
            },
            {
                text: 'personal_data_paragraph_18'
            },
            {
                text: 'personal_data_paragraph_19'
            },
            {
                text: 'personal_data_paragraph_20'
            },
            {
                text: 'personal_data_paragraph_21'
            },
            {
                text: 'personal_data_paragraph_22'
            },
            {
                text: 'personal_data_paragraph_23'
            },
            {
                text: 'personal_data_paragraph_24'
            },
            {
                text: 'personal_data_paragraph_25'
            },
            {
                text: 'personal_data_paragraph_26'
            },
            {
                text: 'personal_data_paragraph_27'
            },
            {
                text: 'personal_data_paragraph_28'
            },
            {
                text: 'personal_data_paragraph_29'
            },
            {
                text: 'personal_data_paragraph_30'
            },
            {
                text: 'personal_data_paragraph_31'
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'liability_heading',
        paragraphs: [
            {
                text: 'liability_paragraph_1'
            },
            {
                text: 'liability_paragraph_2'
            },
            {
                text: 'liability_paragraph_3'
            },
            {
                text: 'liability_paragraph_4'
            },
            {
                text: 'liability_paragraph_5'
            },
            {
                text: 'liability_paragraph_6'
            },
            {
                text: 'liability_paragraph_7'
            },
            {
                text: 'liability_paragraph_8'
            },
            {
                text: 'liability_paragraph_9'
            },
            {
                text: 'liability_paragraph_10'
            },
            {
                text: 'liability_paragraph_11'
            },
            {
                text: 'liability_paragraph_12'
            },
            {
                text: 'liability_paragraph_13'
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'validity_of_agreement_heading',
        paragraphs: [
            {
                text: 'validity_of_agreement_paragraph_1'
            },
            {
                text: 'validity_of_agreement_paragraph_2'
            },
            {
                text: 'validity_of_agreement_paragraph_3'
            },
            {
                text: 'validity_of_agreement_paragraph_4'
            },
            {
                text: 'validity_of_agreement_paragraph_5'
            },
            {
                text: 'validity_of_agreement_paragraph_6'
            },
            {
                text: 'validity_of_agreement_paragraph_7'
            },
            {
                text: 'validity_of_agreement_paragraph_8'
            },
            {
                text: 'validity_of_agreement_paragraph_9'
            },
            {
                text: 'validity_of_agreement_paragraph_10'
            },
            {
                text: 'validity_of_agreement_paragraph_11'
            },
            {
                text: 'validity_of_agreement_paragraph_12'
            },
            {
                text: 'validity_of_agreement_paragraph_13'
            },
            {
                text: 'validity_of_agreement_paragraph_14'
            },
            {
                text: 'validity_of_agreement_paragraph_15'
            },
            {
                text: 'validity_of_agreement_paragraph_16'
            },
            {
                text: 'validity_of_agreement_paragraph_17'
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'communication_heading',
        paragraphs: [
            {
                text: 'communication_paragraph_1'
            },
            {
                text: 'communication_paragraph_2'
            },
            {
                text: 'communication_paragraph_3'
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'other_conditions_heading',
        paragraphs: [
            {
                text: 'other_conditions_paragraph_1'
            },
            {
                text: 'other_conditions_paragraph_2'
            },
            {
                text: 'other_conditions_paragraph_3'
            },
            {
                text: 'other_conditions_paragraph_4'
            },
            {
                text: 'other_conditions_paragraph_5'
            },
            {
                text: 'other_conditions_paragraph_6'
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    }
]
