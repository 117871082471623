import {useTranslation} from "react-i18next";

const PricingTableCalculateButton = () => {

    const {t} = useTranslation();

    return (
        <>
            <div className="opy-page-prices">
                <div className="opy-bg-light-blue pt-5">
                    <div className="container">
                        <a className="d-flex text-white ps-2 pe-3 py-2 opy-bg-green calculate-button"
                           href="#"
                           data-bs-toggle="collapse"
                           data-bs-target="#pricesExample"
                           aria-expanded="false"
                           aria-controls="#pricesExample">
                            <span className="me-2 question-icon">?</span>
                            <span>{t('commission_fee_calculator')}</span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PricingTableCalculateButton;
