import {useTranslation} from "react-i18next";
import {Card} from "../../../@types/paymentFlowCardsResources.tsx";

const PaymentFlowCard = ({card}: {card: Card}) => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="payment-flow-slide">
                <div className="row about-slide-content mx-0 py-4 px-sm-3 px-md-4">
                    <div className="col-md-6 mb-4 mb-md-0">
                        <img className="img-fluid rounded"
                             src={i18n.language === 'lt' ? card.image.lt : card.image.en}
                             alt='Slider image' />
                    </div>
                    <div className="col-md-6 col-lg-4 ps-md-5 ps-lg-5 ps-xl-6 d-flex align-items-center">
                        <div>
                            <div className="opy-step-number mb-4">{card.stepNumber}</div>
                            <p className="mb-5 text-black">{t(card.mainText)}</p>
                            <div className="d-flex">
                                <a className="next-step opy-button opy-button opy-button--small"><span
                                    className="me-3">{t(card.buttonText)}</span>
                                    <img className="d-inline"
                                         src="/assets/images/payment-methods-page-images/icon-arrow-white-longer.svg"
                                         alt='Arrow icon'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentFlowCard;
