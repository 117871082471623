import {Channel} from "../../../@types/demoPaymentMethodsResources.ts";
import {useRef} from "react";

const PaymentMethodsSingleChannel = (
    {channel, setSelectedChannel}:
        {channel: Channel, setSelectedChannel: (arg0: string) => void}
) => {

    const cardContainerRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <div className="col-md-6 col-lg-3 pb-4">
                <label className="opy-payment-method">
                    <div ref={cardContainerRef}
                         className="opy-payment-method__card"
                         onClick={() => setSelectedChannel(channel.channel_name)}>
                        <div className="opy-payment-method__logo">
                            <img src={channel.logo_urls.color_33px} alt={channel.title}/>
                        </div>
                    </div>
                </label>
            </div>
        </>
    )
}

export default PaymentMethodsSingleChannel;
