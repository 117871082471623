import {Cards} from "../../@types/donationDemoCardsResources.tsx";
import image1Lt from "/assets/images/demo-pages-images/self-service-demo-1-lt.png";
import image2Lt from "/assets/images/demo-pages-images/self-service-demo-2-lt.png";
import image3Lt from "/assets/images/demo-pages-images/self-service-demo-3-lt.png";
import image1En from "/assets/images/demo-pages-images/self-service-demo-1-en.png";
import image2En from "/assets/images/demo-pages-images/self-service-demo-2-en.png";
import image3En from "/assets/images/demo-pages-images/self-service-demo-3-en.png";


export const SelfServiceDemoCardsConfig: Cards = {
    list: [
        {
            image: {
                lt: image1Lt,
                en: image1En
            }
        },
        {
            image: {
                lt: image2Lt,
                en: image2En
            }
        },
        {
            image: {
                lt: image3Lt,
                en: image3En
            }
        }
    ]
}
