import {Banner} from "../../@types/homePageTwoColumnsDynamicBannersResources.tsx";
import bannerImage1 from '/assets/images/home-page-images/why-opay-1.jpeg';
import bannerImage2 from '/assets/images/home-page-images/why-opay-2.jpeg';
import bannerImage3 from '/assets/images/home-page-images/why-opay-3.jpeg';
import LBLogo from '/assets/images/home-page-images/logo-lietuvosbankas.png'

export const WhyOpayBannersConfig: Banner[] = [
    {
        heading: 'we_accredited',
        text: 'we_accredited_description',
        image: {
            lt: bannerImage1,
            en: bannerImage1
        },
        smallImage: LBLogo,
        imagePosition: 'left'
    },
    {
        heading: 'we_specialize',
        text: 'we_specialize_description',
        image: {
            lt: bannerImage2,
            en: bannerImage2
        },
        imagePosition: 'right'
    },
    {
        heading: 'your_business_success',
        text: 'your_business_success_description',
        image: {
            lt: bannerImage3,
            en: bannerImage3
        },
        imagePosition: 'left'
    }
]
