import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Button} from "../../../@types/mainBannerButtonLinksResources.tsx";
import {HashLink} from "react-router-hash-link";

const MainBannerButtons = ({links}: {links: Button[]}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="pe-2 mb-4 mb-sm-6">
                {links.map((link, index) => (
                    link.toId
                        ? <HashLink to={`/${t('routes.' + link.to)}#${t(link.toId)}`}
                                  key={index}
                                  className={link.className}>
                            <span>{t(link.text)}</span>
                        </HashLink>
                        : <Link to={`/${t('routes.' + link.to)}`}
                              key={index}
                              className={link.className}>
                            <span>{t(link.text)}</span>
                        </Link>
                ))}
            </div>
        </>
    )
}

export default MainBannerButtons;
