import {Card} from "../../../@types/buildingOnlineAdvantagesCardsResources.tsx";
import {useTranslation} from "react-i18next";

const AdvantageCard = ({card}: {card: Card}) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="col-md-4 mb-4"><img className="mb-3" src={card.image} alt='advantage logo' />
                <p className="text-blue-dark">{t(card.description)}</p>
            </div>
        </>
    )
}

export default AdvantageCard;
