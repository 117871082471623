import {Section} from "../../@types/singlePaymentMethodDescriptionSectionResources.tsx";

export const CardPaymentsDescriptionSectionConfig: Section = {
    heading: 'what_is_card_payments_heading',
    description: 'what_is_card_payments_description',
    paragraphs: [
        {
            heading: 'what_to_know_card_payments_heading',
            description: 'what_to_know_card_payments_description'
        },
        {
            heading: 'how_card_payments_works_heading',
            orderedList: [
                'how_card_payments_works_list_1',
                'how_card_payments_works_list_2',
                'how_card_payments_works_list_3',
                'how_card_payments_works_list_4',
                'how_card_payments_works_list_5',
                'how_card_payments_works_list_6',
            ]
        },
        {
            heading: 'how_chargeback_work_heading',
            description: 'how_chargeback_work_description'
        },
        {
            heading: 'features_heading',
            unorderedList: [
                'card_payments_features_list_1',
                'card_payments_features_list_2',
                'card_payments_features_list_3',
                'card_payments_features_list_4',
                'card_payments_features_list_5',
                'card_payments_features_list_6',
                'card_payments_features_list_7',

            ]
        }
    ]
}
