import {PricingCalculatorConfig} from "../../configs/pricing-page/pricingCalculatorConfig.ts";
import {ExampleFields, PaymentMethod} from "../../@types/pricingCalculatorFieldsResources.tsx";

export const updateMethods = (country: string, setMethods: (arg0: PaymentMethod[]) => void) => {
    PricingCalculatorConfig.forEach((item) => {
        if (item.name === country.toLowerCase()) {
            setMethods(item.paymentMethods);
        }
    });
}

export const getCurrentRates = (
    methods: PaymentMethod[],
    selectedMethod: string,
    setCurrentRates: (arg0: PaymentMethod) => void
) => {
    methods.forEach((method) => {
        if (method.name !== selectedMethod) {
            return;
        }

        setCurrentRates(method);
    });
}

export const handleMethodChange = (e: any, setSelectedMethod: (arg0: string) => void) => {
    setSelectedMethod(e.target.value);
}

export const calculateCommissionFee = (
    paymentAmount: number,
    currentRates: PaymentMethod,
    setCommissionFee: (arg0: number) => any
) => {
    const initialFee = paymentAmount * currentRates.percentageRate / 100;

    if (initialFee <= 0) {
        return setCommissionFee(0);
    }

    if (currentRates.minRate && initialFee < currentRates.minRate) {
        return setCommissionFee(currentRates.minRate + currentRates.fixedRate);
    }

    if (currentRates.maxRate && initialFee > currentRates.maxRate) {
        return setCommissionFee(currentRates.maxRate + currentRates.fixedRate);
    }

    return setCommissionFee(initialFee + currentRates.fixedRate);
}

export const getExampleFields = (
    paymentAmount: number, currentRates: PaymentMethod, setExampleFields: (arg0: ExampleFields) => void, commissionFee: number
) => {
    const exampleFields = {} as ExampleFields

    exampleFields.percentageRate = {
        paymentAmount: paymentAmount,
        percentageRate: currentRates.percentageRate,
        result: paymentAmount * currentRates.percentageRate / 100
    };

    exampleFields.minRate = {
        percentageRateResult: exampleFields.percentageRate.result,
        minRate: currentRates.minRate === null ? '0.00' : currentRates.minRate.toFixed(2),
        equalString: getEqualString(currentRates.minRate, exampleFields.percentageRate.result),
        result: currentRates.minRate === null
            ? '-'
            : currentRates.minRate >= exampleFields.percentageRate.result
                ? currentRates.minRate.toFixed(2)
                : '-'
    };

    exampleFields.maxRate = {
        percentageRateResult: exampleFields.percentageRate.result,
        maxRate: currentRates.maxRate === null ? '0.00' : currentRates.maxRate.toFixed(2),
        equalString: getEqualString(currentRates.maxRate, exampleFields.percentageRate.result),
        result: currentRates.maxRate === null
            ? '-'
            : currentRates.maxRate <= exampleFields.percentageRate.result
                ? currentRates.maxRate.toFixed(2)
                : '-'
    };

    exampleFields.fixedRate = {
        calculationResult: (commissionFee - currentRates.fixedRate),
        fixedRate: currentRates.fixedRate,
        result: commissionFee
    };

    setExampleFields(exampleFields);
}

const getEqualString = (rate: number|null, amount: number) => {
    if (rate === null) {
        rate = 0;
    }

    if (rate < amount) {
        return 'greater_than';
    }

    if (rate > amount) {
        return 'less_than';
    }

    return 'equal';
}

export const isCurrentRatesPopulated = (rates: PaymentMethod) => {
    return rates.name !== undefined
        && rates.percentageRate !== undefined
        && rates.minRate !== undefined
        && rates.maxRate !== undefined
        && rates.fixedRate !== undefined;
};

export const buildRateString = (rate: number|null|undefined) => {
    if (rate === null || rate === undefined) {
        return '-';
    }

    return `${rate.toFixed(2)} €`;
}

export const buildResultString = (result: number|string) => {
    if (result === '-') {
        return '-';
    }

    return `${result} €`;
}
