import BannerWithElement from "../../components/demo-pages-components/demo-banner/BannerWithElement.tsx";
import StartUsingBannerButtons from "../../components/for-testing-page-components/StartUsingBannerButtons.tsx";
import StartUsingDescription from "../../components/for-testing-page-components/StartUsingDescription.tsx";
import ForTestingForm from "../../components/for-testing-page-components/form/ForTestingForm.tsx";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const ForTestingPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.for_testing');
    }, []);

    return (
        <>
            <div className="opy-content">
                <BannerWithElement heading={'start_using'}
                                   element={<StartUsingBannerButtons isForTestingPage={true} />}
                                   slider={false} />
                <div className="clearfix" style={{paddingTop: '50px'}}>
                    <StartUsingDescription description1={'fill_form_for_testing'}
                                       description2={'after_start_using_form_filling'} />
                    <ForTestingForm />
                </div>
            </div>
        </>
    )
}

export default ForTestingPage;
