import {useTranslation} from "react-i18next";
import PaymentMethodCardBanks from "./PaymentMethodCardBanks.tsx";
import PaymentMethodCardFeatures from "./PaymentMethodCardFeatures.tsx";
import PaymentMethodText from "./PaymentMethodText.tsx";
import PaymentMethodCardButton from "./PaymentMethodCardButton.tsx";
import {Card} from "../../../@types/paymentMethodsCardsResources.tsx";

const PaymentMethodCard = ({card}: {card: Card}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="opy-page-payment-methods">
                <div className="opy-bg-gray pt-5">
                    <div className="pb-5">
                        <div className="container opy-block">
                            <div className="opy-bg-white">
                                <div className="opy-payment-method-card">
                                    <div className="opy-payment-method-card__icon">
                                        <img src={card.image} alt="Service icon"/>
                                    </div>
                                    <PaymentMethodText heading={card.heading}
                                                       description={card.description}
                                                       additionalDescription={card.additionalDescription}
                                    />
                                </div>
                                <div className="opy-payment-method-card__body pe-md-6 pb-3 pb-md-5">
                                    <div className="text-blue-dark fs-7 fw-semibold">
                                        {t('accept_payments_from')}
                                    </div>
                                    <PaymentMethodCardBanks banks={card.banks} />
                                    <PaymentMethodCardFeatures features={card.features} />
                                </div>
                                <PaymentMethodCardButton link={card.link} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentMethodCard;
