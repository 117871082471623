import {ExampleFields} from "../../../../../../@types/pricingCalculatorFieldsResources.tsx";
import MobileModalTableExamplePercentageRate
    from "./MobileModalTableExamplePercentageRate.tsx";
import MobileModalTableExampleMinRate
    from "./MobileModalTableExampleMinRate.tsx";
import MobileModalTableExampleMaxRate
    from "./MobileModalTableExampleMaxRate.tsx";
import MobileModalTableExampleFixedRate
    from "./MobileModalTableExampleFixedRate.tsx";

const MobileModalTableExample = ({exampleFields}: {exampleFields: ExampleFields}) => {
    return (
        <>
            <div className="container opy-block">
                <div className="mb-4">
                    <MobileModalTableExamplePercentageRate
                        rate={exampleFields.percentageRate} />
                    <MobileModalTableExampleMinRate rate={exampleFields.minRate} />
                    <MobileModalTableExampleMaxRate rate={exampleFields.maxRate} />
                    <MobileModalTableExampleFixedRate rate={exampleFields.fixedRate} />
                </div>
            </div>
        </>
    )
}

export default MobileModalTableExample;
