import DesktopPricingCalculatorModal from "../modal/desktop/DesktopPricingCalculatorModal.tsx";
import MobilePricingCalculatorModal from "../modal/mobile/MobilePricingCalculatorModal.tsx";
import './Calculator.css';

const PricingCalculator = () => {
    return (
        <>
            <div className="raw-html-embed">
                <div className="opy-page-prices">
                    <div className="collapse prices-example-modal" id="pricesExample">
                        <DesktopPricingCalculatorModal />
                        <MobilePricingCalculatorModal />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PricingCalculator;
