import {useTranslation} from "react-i18next";
import PhoneContact from "../../footer-components/footer-contacts/PhoneContact.tsx";
import EmailContact from "../../footer-components/footer-contacts/EmailContact.tsx";
import {requisitesAndContacts} from "../../../configs/contacts-page/requisitesAndContacts.ts";

const Contacts = () => {

    const {t, i18n} = useTranslation();

    return (
        <>
            <div className="me-md-6 me-lg-7 mb-5">
                <h2 className="fs-6 fw-bold">{t('contacts')}</h2>
                <div className="fs-8">
                    <div>{i18n.language === 'lt' ? t('contacts_address') : t('requisites_address')}</div>
                    <PhoneContact />
                    <EmailContact />
                    {i18n.language === 'lt' && <div>{requisitesAndContacts.workingHours}</div>}
                </div>
            </div>
        </>
    )
}

export default Contacts;
