import {useTranslation} from "react-i18next";

const IsLegalEntityRadioButtons = (
    {isLegalEntity, setIsLegalEntity}:
        {isLegalEntity: boolean, setIsLegalEntity: (arg0: boolean) => void}
) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="form-group">
                <label className="radio-inline" style={{marginRight: '5px'}}>
                    <input type="radio"
                           checked={!isLegalEntity}
                           style={{marginRight: '5px'}}
                           onChange={() => setIsLegalEntity(false)} />
                    {t('individual_person')}
                </label>
                <label className="radio-inline">
                    <input type="radio"
                           checked={isLegalEntity}
                           style={{marginRight: '5px'}}
                           onChange={() => setIsLegalEntity(true)} />
                    {t('legal_entity')}
                </label>
            </div>
        </>
    )
}

export default IsLegalEntityRadioButtons;
