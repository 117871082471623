import {List} from "../../@types/solutionsListResources.tsx";
import solutions1 from '/assets/images/demo-pages-images/demo-pis-solution-cover.jpg';

export const DemoPisSolutionsListConfig: List[] = [
    {
        subheading: 'demo_pis_solutions',
        subheadingClassName: 'fs-4 mb-4',
        image: solutions1,
        classNameFirst: 'row align-items-center opy-bg-white pt-md-4 pb-4',
        classNameSecond: 'col-lg-6 ps-lg-5 d-flex flex-column justify-content-center',
        content: [
            'demo_pis_solutions_description_1',
            'demo_pis_solutions_description_2',
            'demo_pis_solutions_description_3',
            'demo_pis_solutions_description_4',
            'demo_pis_solutions_description_5',
            'demo_pis_solutions_description_6',
            'demo_pis_solutions_description_7'
        ]
    }
]
