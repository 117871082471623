import {Link} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";

const LegalPageSectionParagraphTransComponent = ({text}: {text: string|undefined}) => {

    const {t} = useTranslation();

    return (
        <>
            <Trans t={t} components={{
                lrvComplaint: <a href="https://vdai.lrv.lt/lt/veiklos-sritys-1/skundu-nagrinejimas/" />,
                cookiesPolicy: <Link to={`/${t('routes.cookies_policy')}`} />,
                privacyEmail: <a className='contact-by-email' href="mailto:privacy@opay.eu" />,
                contactEmail: <a className='contact-by-email' href="mailto:info@opay.eu" />,
                officerEmail: <a className='contact-by-email' href="mailto:info@privacypartners.lt" />,
                phone: <a href="tel:+37061155554" />,
                officerPhone: <a href="tel:+37052548240" />,
                privacyPolicy: <Link to={`/${t('routes.privacy_policy')}`} />,
                opayWebsite: <a href="https://opay.eu" target="_blank" />,
                lbDisputes: <a href="https://www.lb.lt/lt/spreskite-ginca-su-finansiniu-paslaugu-teikeju#ex-1-4" target="_blank" />,
                lb: <a href={t('lbLink')} target="_blank" />,
                vvtat: <a href={t('vvtatLink')} target="_blank" />,
                ada: <a href={t('adaLink')} />
            }}>
                {text}
            </Trans>
        </>
    )
}

export default LegalPageSectionParagraphTransComponent;
