import {useTranslation} from "react-i18next";

const EmailContact = () => {

    const { t } = useTranslation();

    return (
        <>
            <div>
                {t('contacts_email')}
                <a className="contact-by-email inline-contacts contact-button" href="mailto:info&#64;opay.eu">
                    info&#64;opay.eu
                </a>
            </div>
        </>
    )
}

export default EmailContact;
