import {useTranslation} from "react-i18next";

const ConsultPageDescription = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="col-lg-6 mb-4">
                <h1 className="fs-3 fs-md-1 pb-4">{t('free_consult')}</h1>
                <p className="pe-5">{t('free_consult_description')}</p>
            </div>
        </>
    )
}

export default ConsultPageDescription;
