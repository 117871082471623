import {RefObject} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import GRecaptcha from "../../g-recaptcha/GRecaptcha.tsx";
import {
    AcceptPaymentsAgreementSectionCheckboxesConfig
} from "../../../configs/accept-payments-page/acceptPaymentsAgreementSectionCheckboxesConfig.ts";
import AgreementSectionCheckbox from "./AgreementSectionCheckbox.tsx";
import {useTranslation} from "react-i18next";

const AgreementSection = (
    {setCaptcha, captchaRef}:
        {setCaptcha: (arg0: boolean|null) => void, captchaRef: RefObject<ReCAPTCHA>}
) => {

    const { t } = useTranslation();

    return (
        <>
            <div id="client_data" className="mb-6">
                <h2 className="fs-6 mt-4 mt-md-5">{t('agreement')}</h2>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        {AcceptPaymentsAgreementSectionCheckboxesConfig.map((checkbox, index) => (
                            <AgreementSectionCheckbox key={index} checkbox={checkbox} />
                        ))}
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group pt-4 d-flex justify-content-end">
                            <div id="g-recaptcha" className="g-recaptcha">
                                <GRecaptcha setCaptcha={setCaptcha} captchaRef={captchaRef}/>
                            </div>
                        </div>
                        <div className="form-group pt-4 d-flex justify-content-end">
                            <button className="opy-button opy-button--medium" type="submit">{t('send_data')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AgreementSection;
