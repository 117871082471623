import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import logo from '/assets/images/layout-images/opay-logo-blue.svg'
import React from "react";

const Logo = ({className}: {className: string}) => {

    const { i18n } = useTranslation();
    const location = useLocation();

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (location.pathname === `/${i18n.language}`) {
            e.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    return (
        <>
            <Link to={`/${i18n.language}`} className={className} onClick={handleClick}>
                <img src={logo} alt="Opay logo"/>
            </Link>
        </>
    )
}

export default Logo;
