import {useTranslation} from "react-i18next";

const SelectOptionsAmountField = (
    {sectionClass, sectionHeadingClass, setPaymentAmount}:
        {sectionClass: string, sectionHeadingClass: string|undefined, setPaymentAmount: (arg0: number) => void}
) => {

    const {t} = useTranslation();

    return (
        <>
            <div className={sectionClass}>
                <p className={sectionHeadingClass}>{t('payment_amount')}</p>
                <input defaultValue={40}
                       id="calculator_amount"
                       name="calculator_amount"
                       className="calculator_amount m-calculator_amount"
                       type="number"
                       max="999999999"
                       onChange={(e) => {
                           isNaN(e.target.valueAsNumber)
                               ? setPaymentAmount(0)
                               : setPaymentAmount(e.target.valueAsNumber)
                       }}/>
            </div>
        </>
    )
}

export default SelectOptionsAmountField;
