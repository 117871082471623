import {Card} from "../../@types/linkableCardResources.tsx";
import legal1Image from '/assets/images/legal-information-page-images/legal-image-1.svg';
import legal2Image from '/assets/images/legal-information-page-images/legal-image-2.svg';
import legal3Image from '/assets/images/legal-information-page-images/legal-image-3.svg';

export const LegalInformationPageLinkableCardsConfig: Card[] = [
    {
        image: legal1Image,
        heading: "general_terms",
        link: {
            lt: "routes.general_terms",
            en: "routes.general_terms"
        }
    },
    {
        image: legal2Image,
        heading: "privacy_policy",
        link: {
            lt: "routes.privacy_policy",
            en: "routes.privacy_policy"
        }
    },
    {
        image: legal3Image,
        heading: "cookies_policy",
        link: {
            lt: "routes.cookies_policy",
            en: "routes.cookies_policy"
        }
    }
]
