import {useTranslation} from "react-i18next";
import {requisitesAndContacts} from "../../../configs/contacts-page/requisitesAndContacts.ts";

const PhoneContact = () => {

    const { t } = useTranslation();

    return (
        <>
            <div>{t('contacts_phone')}
                <a className="contact-by-phone inline-contacts contact-button" href={`tel:${requisitesAndContacts.phone}`}>
                    {requisitesAndContacts.phone}
                </a>
            </div>
        </>
    )
}

export default PhoneContact;
