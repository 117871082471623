import {Card} from "../../../@types/donationDemoCardsResources.tsx";
import header from "/assets/images/demo-pages-images/mockup-header.svg";
import {useTranslation} from "react-i18next";

const DemoBannerCarouselCard = ({card}: {card: Card}) => {

    const { i18n } = useTranslation();

    return (
        <>
            <div className="demo-slide">
                <img className="w-100" src={header} alt="decoration image"/>
                <img className="w-100"
                     src={i18n.language === 'lt' ? card.image.lt : card.image.en}
                     alt="donation demonstration image" />
            </div>
        </>
    )
}

export default DemoBannerCarouselCard;
