import {ExampleMinRate} from "../../../../../../@types/pricingCalculatorFieldsResources.tsx";
import {useTranslation} from "react-i18next";
import arrowImage from '/assets/images/pricing-page-images/round-arrow-right.png';
import {buildResultString} from "../../../../../../helpers/pricing/calculatorHelper.ts";

const DesktopModalTableExampleMinRate = ({rate}: {rate: ExampleMinRate}) => {

    const {t} = useTranslation();

    return (
        <>
            {rate &&
                <td className="fw-semibold text-center align-middle position-relative" style={{width: '200px'}}>
                    <div className="pt-1">
                        <div className="fs-9 mb-2" id="example-step-2">
                            <span style={{color: '#7D7D7D'}}>
                                {rate.percentageRateResult.toFixed(2)} € {t(rate.equalString)}
                            </span>
                            <span style={{color: '#FF5789'}}> {rate.minRate} €</span>
                        </div>
                        <div className="fs-7" id="example-step-2-value" style={{color: '#FF5789'}}>
                            {buildResultString(rate.result)}
                        </div>
                    </div>
                    <img className="position-absolute"
                         src={arrowImage}
                         style={{top: '35px', right: '-15px'}}
                         alt='direction icon' />
                </td>
            }
        </>
    )
}

export default DesktopModalTableExampleMinRate;
