import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {SolutionsSectionConfig} from "../../../configs/layout/footerLinks.ts";
import {getCurrentLanguageEnum} from "../../../@types/generics.tsx";

const FooterSolutionsSection = () => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="col md-4 col-lg-2">
                <div className="opy-footer-header">{t('solutions')}</div>
                <div className="opy-footer-info fw-semibold">
                    {SolutionsSectionConfig.map((link, index) => (
                        link.languageToShow.includes(getCurrentLanguageEnum(i18n.language))
                        && <Link to={t(`routes.${link.to}`)} key={index}>
                            <span>{t(link.text)}</span>
                        </Link>
                    ))}
                </div>
            </div>
        </>
    )
}

export default FooterSolutionsSection;
