import image1 from "/assets/images/demo-pages-images/self-service-demo-advantage-1.jpg";
import image2 from "/assets/images/demo-pages-images/self-service-demo-advantage-2.jpg";
import image3 from "/assets/images/demo-pages-images/self-service-demo-advantage-3.jpg";
import {Section} from "../../@types/demoAdvantagesResources.tsx";

export const SelfServiceDemoAdvantagesConfig: Section = {
    heading: 'efficient_finance_management',
    className: 'opy-bg-white pt-xl-4 pb-5',
    list: [
        {
            image: {
                lt: image1,
                en: image1
            },
            description: 'efficient_finance_management_advantage_1'
        },
        {
            image: {
                lt: image2,
                en: image2
            },
            description: 'efficient_finance_management_advantage_2'
        },
        {
            image: {
                lt: image3,
                en: image3
            },
            description: 'efficient_finance_management_advantage_3'
        }
    ]
}
