import bannerImage from '/assets/images/payment-methods-page-images/icon-banklink.svg';
import LegalBanner from "../../components/privacy-policy-page-components/legal-banner/LegalBanner.tsx";
import DemonstrationsCards from "../../components/demonstrations/DemonstrationsCards.tsx";
import {PaymentMethodsDemonstrationsCardsConfig} from "../../configs/payment-methods-page/paymentMethodsDemonstrationsCards.ts";
import SinglePaymentMethodDescriptionSection
    from "../../components/single-payment-method-page-components/single-payment-method-description-section/SinglePaymentMethodDescriptionSection.tsx";
import {BankLinkDescriptionSectionConfig} from "../../configs/payment-methods-page/bankLinkDescriptionSection.ts";
import GetStartedBanner from "../../components/home-page-components/get-started-banner/GetStartedBanner.tsx";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const OnlineBankingPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.online_banking');
    }, []);

    return (
        <>
            <LegalBanner heading={'bank_link_service'} image={bannerImage} />
            <DemonstrationsCards config={PaymentMethodsDemonstrationsCardsConfig} />
            <SinglePaymentMethodDescriptionSection config={BankLinkDescriptionSectionConfig} />
            <GetStartedBanner bannerText={'payment_methods_banner_text'}
                              linkTo={'routes.pricing'}
                              linkClassname={'opy-button opy-button--white opy-button--medium text-center'}
                              linkText={'get_prices'} />
        </>
    )
}

export default OnlineBankingPage;
