import {Section} from "../../../@types/generalTermsUpdatesSectionResources.tsx";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const GeneralTermsUpdatesSection = ({config}: {config: Section}) => {

    const {t, i18n} = useTranslation();

    return (
        <>
            <div className="opy-bg-white pt-6 pb-6">
                <div className="container opy-block">
                    <div className="row">
                        <div className="col-lg-8 mb-5 mb-xl-0">
                            <Trans t={t}>
                                <div>{t(config.heading1)}</div>
                                <div>{t(config.heading2)}</div>
                                <div>{t(config.heading3)}</div>
                            </Trans>
                        </div>
                        <div className="col-lg-4 d-flex align-items-center justify-content-center justify-content-md-end">
                            {i18n.language === 'lt'
                                ? <Link to={`/${t(config.button.lt.link)}`} className="opy-button">
                                    {t(config.button.lt.text)}
                                </Link>
                                : <Link to={`/${t(config.button.en.link)}`} className="opy-button">
                                    {t(config.button.en.text)}
                                </Link>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GeneralTermsUpdatesSection;
