import {Section} from "../../@types/singlePaymentMethodDescriptionSectionResources.tsx";

export const WireTransferDescriptionSectionConfig: Section = {
    heading: 'what_is_wire_transfer_heading',
    description: 'what_is_wire_transfer_description',
    paragraphs: [
        {
            heading: 'how_wire_transfer_works_heading',
            orderedList: [
                'how_wire_transfer_works_list_1',
                'how_wire_transfer_works_list_2',
                'how_wire_transfer_works_list_3',
                'how_wire_transfer_works_list_4',
                'how_wire_transfer_works_list_5',
            ]
        },
        {
            heading: 'features_heading',
            unorderedList: [
                'wire_transfer_features_list_1',
                'wire_transfer_features_list_2',
                'wire_transfer_features_list_3',
                'wire_transfer_features_list_4',
                'wire_transfer_features_list_5',
            ],
            description: 'wire_transfer_features_description'
        }
    ]
}
