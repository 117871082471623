import {Paragraph} from "../../../@types/singlePaymentMethodDescriptionSectionResources.tsx";
import {Trans, useTranslation} from "react-i18next";

const SinglePaymentMethodDescriptionSectionParagraph = ({paragraph}: {paragraph: Paragraph}) => {

    const {t, i18n} = useTranslation();

    return (
        <>
            <div className="mb-4">
                <h2 className="mb-2">{t(paragraph.heading)}</h2>
                {paragraph.orderedList && (
                    <ol>
                        {paragraph.orderedList.map((listItem, index) => (
                            <li className="mb-0 align-list-item" key={index}>{t(listItem)}</li>
                        ))}
                    </ol>
                )}
                {paragraph.unorderedList && (
                    <ul>
                        {paragraph.unorderedList.map((listItem, index) => (
                            <li className="mb-0 align-list-item" key={index}>{t(listItem)}</li>
                        ))}
                    </ul>
                )}
                {paragraph.description && (
                    <p className="mb-4">
                        <Trans t={t} components={{
                            cardGuide: i18n.language === 'lt'
                                ? <a href='/user/themes/opay-theme/images/Mokejimo_korteles_atmintine_klientams_LT_v2024-08-22.pdf' />
                                : <a href='/user/themes/opay-theme/images/Payment_card_service_guidelines_EN_v2024-08-22.pdf' />,
                        }}>
                            {paragraph.description}
                        </Trans>
                    </p>
                )}
            </div>
        </>
    )
}

export default SinglePaymentMethodDescriptionSectionParagraph;
