import {FormSectionType} from "../../@types/forTestingFormSectionsResources.ts";
import {handlePhoneNumber, handlePhonePrefix} from "../../helpers/register-form/registerFormHelper.tsx";
import {RegexPatternEnum} from "../../enum/pattern/regexPatternEnum.ts";

export const ForTestingPageFormSectionsConfig: FormSectionType[] = [
    {
        heading: 'e_shop_information',
        rows: [
            {
                inputs: [
                    {
                        label: 'website_address_of_store',
                        registerLabel: 'web_protocol',
                        required: true,
                        isSelect: true,
                        selectOptions: [
                            {
                                name: 'http://'
                            },
                            {
                                name: 'https://'
                            }
                        ],
                        secondInput: {
                            registerLabel: 'website_url',
                            required: true,
                            type: 'text',
                            onchange: () => {},
                        },
                        hint: 'example_website_address_of_store',
                        onchange: () => {}
                    },
                    {
                        label: 'website_phone_number',
                        registerLabel: 'phone_number_prefix',
                        required: true,
                        type: 'tel',
                        initialValue: '+370',
                        pattern: '.{2,}',
                        maxLength: 4,
                        secondInput: {
                            registerLabel: 'website_phone_number',
                            required: true,
                            type: 'tel',
                            onchange: (e) => handlePhoneNumber(e),
                        },
                        hint: 'example_phone_number',
                        onchange: (e) => handlePhonePrefix(e)
                    }
                ]
            },
            {
                inputs: [
                    {
                        label: 'website_email',
                        registerLabel: 'website_email',
                        required: true,
                        type: 'email',
                        pattern: RegexPatternEnum.email,
                        hint: 'example_email_address',
                        singleInput: true,
                        onchange: () => {},
                    }
                ]
            }
        ]
    },
    {
        heading: 'installer_information',
        rows: [
            {
                inputs: [
                    {
                        label: 'name',
                        registerLabel: 'installer_name',
                        required: true,
                        type: 'text',
                        singleInput: true,
                        onchange: () => {},
                    },
                    {
                        label: 'surname',
                        registerLabel: 'installer_surname',
                        required: true,
                        type: 'text',
                        singleInput: true,
                        onchange: () => {},
                    }
                ]
            },
            {
                inputs: [
                    {
                        label: 'email',
                        registerLabel: 'installer_email',
                        required: true,
                        type: 'email',
                        pattern: RegexPatternEnum.email,
                        hint: 'example_installer_email',
                        singleInput: true,
                        onchange: () => {},
                    }
                ]
            }
        ]
    }
]