import BannerWithElement from "../../components/demo-pages-components/demo-banner/BannerWithElement.tsx";
import DemoBannerCarousel from "../../components/demo-pages-components/demo-banner/DemoBannerCarousel.tsx";
import {DonationDemoCardsConfig} from "../../configs/demo-pages/donationDemoCarouselCards.ts";
import DemoAdvantages from "../../components/demo-pages-components/demo-advantages/DemoAdvantages.tsx";
import DemonstrationsCards from "../../components/demonstrations/DemonstrationsCards.tsx";
import {DonationDemonstrationsCardsConfig} from "../../configs/demo-pages/donationDemoCards.ts";
import GetStartedBanner from "../../components/home-page-components/get-started-banner/GetStartedBanner.tsx";
import {DonationDemoAdvantagesConfig} from "../../configs/demo-pages/donationDemoAdvantages.ts";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const DemoDonationsPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.demo_donations');
    }, []);

    return (
        <>
            <div className="opy-content">
                <div className="opy-page-demo-donation">
                    <BannerWithElement heading={'demonstration_donation'}
                                       element={<DemoBannerCarousel
                                    config={DonationDemoCardsConfig} />}
                                       slider={true} />
                    <DemoAdvantages config={DonationDemoAdvantagesConfig} />
                    <DemonstrationsCards config={DonationDemonstrationsCardsConfig} />
                    <GetStartedBanner bannerText={'boost_your_business'}
                                      linkText={'get_started'}
                                      linkClassname={'opy-button opy-button--white opy-button--medium text-center'}
                                      linkTo={'routes.start_using_accept_payments'} />
                </div>
            </div>
        </>
    )
}

export default DemoDonationsPage;
