import {useTranslation} from "react-i18next";
import image from '/assets/images/demo-pages-images/purchase-example-header.svg';

const PurchaseExampleHeader = () => {

    const { t } = useTranslation();

    return (
        <>
            <div>
                <img className="mw-100" src={image} alt='purchase example header' />
            </div>
            <div className="opy-bg-green py-4 px-5"><span
                className="text-white fs-4">{t('shopping_basket')}</span>
            </div>
        </>
    )
}

export default PurchaseExampleHeader;
