import {useTranslation} from "react-i18next";

const PaymentPageSelectVisualization = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="d-flex flex-column mb-6">
                <div className="form-check">
                    <input type="radio" disabled={true}/>
                    <label className="form-check-label">...</label>
                </div>
                <div className="form-check">
                    <input type="radio" defaultChecked={true}/>
                    <label className="form-check-label">{t('banks_cards_others')}</label>
                </div>
                <div className="form-check">
                    <input type="radio" disabled={true}/>
                    <label className="form-check-label">...</label>
                </div>
            </div>
        </>
    )
}

export default PaymentPageSelectVisualization;
