import MainBannerButtons from "./MainBannerButtons.tsx";
import MainBannerImage from "./MainBannerImage.tsx";
import MainBannerText from "./MainBannerText.tsx";
import {Button} from "../../../@types/mainBannerButtonLinksResources.tsx";

const MainBanner = (
    {image, links, heading, paragraph}:
    {image: string, links: Button[], heading: string, paragraph: string}
) => {
    return (
        <>
            <div className="opy-content">
                <div className="opy-bg-white">
                    <div className="container opy-block">
                        <div className="row pt-5 pb-5 pt-md-6 pb-md-6">
                            <div className="col-lg-6 d-flex flex-column justify-content-center">
                                <MainBannerText heading={heading} paragraph={paragraph}/>
                                <MainBannerButtons links={links}/>
                            </div>
                            <div className="col-lg-6">
                                <MainBannerImage className={'img-fluid'} image={image}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainBanner;
