import image from '/assets/images/thank-you-page-images/thankyou-image.svg';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ThankYouBanner = ({text, isNavigationToHome = false}: {text: string, isNavigationToHome: boolean}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="opy-bg-white pb-7">
                <div className="container opy-block">
                    <div className="modular-row form ">
                        <div className="opy-page-thankyou">
                            <div className="opy-bg-white flex-grow-1 d-flex flex-column align-items-center justify-content-center py-5 py-xl-6">
                                <div className="bg-white py-5 py-md-6">
                                    <div className="text-center mb-5 px-5">
                                        <img className="img-fluid" src={image} alt="Thank you image"/>
                                        <div className="text-center text-blue-dark fs-6 fs-lg-5 my-5">
                                            {t(text)}
                                        </div>
                                        <div className="text-center">
                                            {isNavigationToHome
                                                ? <Link to={`/${t('routes.language')}`}
                                                        className="opy-button"
                                                        style={{textTransform: 'none'}}>
                                                    {t('to_home_page')}
                                            </Link>
                                                : <Link to={`/${t('routes.contacts')}`}
                                                        className="opy-button"
                                                        style={{textTransform: 'none'}}>
                                                {t('contact_us')}
                                            </Link>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ThankYouBanner;
