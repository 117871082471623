import {PaymentMethodsCardsConfig} from "../../../configs/payment-methods-page/paymentMethodsCards.ts";
import PaymentMethodCard from "./PaymentMethodCard.tsx";

const PaymentMethods = () => {
    return (
        <>
            {PaymentMethodsCardsConfig.map((card, index) => (
                <PaymentMethodCard key={index} card={card} />
                ))}
        </>
    )
}

export default PaymentMethods;
