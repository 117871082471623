import {Section} from "../../@types/buildingOnlineBenefitsSectionsResources.tsx";
import benefitImage1 from '/assets/images/building-online-page-images/simple-benefit-1.jpg';
import benefitImage2 from '/assets/images/building-online-page-images/simple-benefit-2.jpg';
import benefitImage3lt from '/assets/images/building-online-page-images/successful-payments-benefit-1-lt.svg';
import benefitImage4lt from '/assets/images/building-online-page-images/successful-payments-benefit-2-lt.svg';
import benefitImage5lt from '/assets/images/building-online-page-images/successful-payments-benefit-3-lt.svg';
import benefitImage3en from '/assets/images/building-online-page-images/successful-payments-benefit-1-en.jpg';
import benefitImage4en from '/assets/images/building-online-page-images/successful-payments-benefit-2-en.jpg';
import benefitImage5en from '/assets/images/building-online-page-images/successful-payments-benefit-3-en.jpg';
import benefitImage6 from '/assets/images/building-online-page-images/convenient-benefit-1.jpg';
import benefitImage7 from '/assets/images/building-online-page-images/convenient-benefit-2.jpg';
import benefitImage8 from '/assets/images/building-online-page-images/transparent-pricing-benefit-1.jpg';

export const BuildingOnlineBenefitsSectionsConfig: Section[] = [
    {
        heading: 'simple',
        content: [
            {
                image: {
                    lt: benefitImage1,
                    en: benefitImage1
                },
                text: ['simple_benefit_1'],
                textClass: 'py-6 fs-6',
                imagePosition: 'left'
            },
            {
                image: {
                    lt: benefitImage2,
                    en: benefitImage2
                },
                text: ['simple_benefit_2'],
                textClass: 'py-6 fs-6',
                imagePosition: 'right'
            }
        ]
    },
    {
        heading: 'successful_payments',
        content: [
            {
                image: {
                    lt: benefitImage3lt,
                    en: benefitImage3en
                },
                text: ['successful_payments_benefit_1'],
                textClass: 'py-6 fs-6',
                imagePosition: 'left'
            },
            {
                image: {
                    lt: benefitImage4lt,
                    en: benefitImage4en
                },
                text: ['successful_payments_benefit_2'],
                textClass: 'py-6 fs-6',
                imagePosition: 'right'
            },
            {
                image: {
                    lt: benefitImage5lt,
                    en: benefitImage5en
                },
                text: ['successful_payments_benefit_3'],
                textClass: 'py-6 fs-6',
                imagePosition: 'left'
            }
        ]
    },
    {
        heading: 'convenient',
        content: [
            {
                image: {
                    lt: benefitImage6,
                    en: benefitImage6
                },
                text: ['convenient_benefit_1'],
                textClass: 'py-6 fs-6',
                imagePosition: 'left'
            },
            {
                image: {
                    lt: benefitImage7,
                    en: benefitImage7
                },
                text: ['convenient_benefit_2'],
                textClass: 'py-6 fs-6',
                imagePosition: 'right'
            }
        ]
    },
    {
        heading: 'transparent_pricing',
        content: [
            {
                image: {
                    lt: benefitImage8,
                    en: benefitImage8
                },
                text: [
                    '01.',
                    'transparent_pricing_benefit_1_building_online',
                    '02.',
                    'transparent_pricing_benefit_2_building_online'
                ],
                textClass: null,
                imagePosition: 'left'
            }
        ]
    }
]
