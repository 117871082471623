import {useTranslation} from "react-i18next";
import ServiceCardsContainer from "./ServiceCardsContainer.tsx";
import PaymentServiceBanner from "./PaymentServiceBanner.tsx";
import PluginsServiceBanner from "./PluginsServiceBanner.tsx";
import './ServiceAndProducts.css';
import ShopifyBanner from "./ShopifyBanner.tsx";


const ServicesAndProducts = () => {

    const { t } = useTranslation();

    return (
        <>
                <div className="container opy-block">
                    <div className="mb-5 pt-5">
                        <h2 className="fs-3 fs-md-2">{t('services_and_products')}</h2>
                    </div>
                    <ServiceCardsContainer />
                    <ShopifyBanner />
                    <PaymentServiceBanner />
                    <PluginsServiceBanner />
                </div>
        </>
    )
}

export default ServicesAndProducts;
