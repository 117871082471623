import {List} from "../../../@types/solutionsListResources.tsx";
import {useTranslation} from "react-i18next";

const SolutionsList = ({solution}: {solution: List}) => {

    const {t} = useTranslation();

    return (
        <>
            {solution.heading && <div className="container opy-block">
                <h2 className="fs-3 fs-lg-2 mb-5">{t(solution.heading)}</h2>
            </div>}
            <div className="container opy-block">
                <div className={solution.classNameFirst}>
                <div className="col-lg-6 mb-5 mb-lg-6">
                        <div>
                            <img className="img-fluid" src={solution.image} alt="banner visualization"/>
                        </div>
                    </div>
                    <div className={solution.classNameSecond}>
                        {solution.subheading
                            && <h2 className={solution.subheadingClassName}>{t(solution.subheading)}</h2>}
                        <ul className="opy-list-2 opy-list-2--large text-blue-dark">
                            {solution.content.map((text, index) => (
                                <li key={index}>{t(text)}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SolutionsList;
