import {Cards} from "../../@types/demonstrationsCardsResources.tsx";
import serviceLogo1 from '/assets/images/home-page-images/icon-service-1.svg'
import serviceLogo2 from '/assets/images/home-page-images/icon-service-3.svg'

export const SelfServiceDemonstrationsCardsConfig: Cards = {
    heading: 'other_demonstrations',
    cards: [
        {
            link: 'routes.demonstrations_bank_link_payments',
            image: serviceLogo1,
            title: 'payment_initiation'
        },
        {
            link: 'routes.demonstrations_donations',
            image: serviceLogo2,
            title: 'donation_collection'
        }
    ]
}
