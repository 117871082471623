import {Question} from "../../../@types/faqAccordionResources.tsx";
import {useTranslation} from "react-i18next";
import FaqTransComponent from "./FaqTransComponent.tsx";

const FaqQuestion = ({question, id, index}: {question: Question, id: string, index: number}) => {

    const { t} = useTranslation();

    return (
        <>
            <div className="accordion-item opy-faq-item">
                <div className="accordion-header opy-faq-item__header" id={`tos-heading-${id}-${index + 1}`} >
                    <button className="accordion-button opy-faq-item__title collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#tos-content-${id}-${index + 1}`}
                            aria-expanded="false"
                            aria-controls={`tos-content-${id}-${index + 1}`} >
                        {t(question.question)}
                    </button>
                </div>
                <div id={`tos-content-${id}-${index + 1}`}
                     className="accordion-collapse collapse opy-faq-item__content"
                     aria-labelledby={`tos-heading-${id}-${index + 1}`}
                     data-bs-parent="#tos-accordion" data-i18n="[html]content.body" >
                    <div className="accordion-body">
                        <FaqTransComponent answer={question.answer} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FaqQuestion;
