import './Copyright.css';
import {useTranslation} from "react-i18next";
import FollowUsSection from "../follow-us-section/FollowUsSection.tsx";

const Copyright = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="opy-bg-white border-top">
                <div className="opy-copyright">
                    <div className="container opy-block">
                        <div className="opy-copyright-content">
                            <div className="opy-copyright-left">
                                <span className="text-blue-dark">{t('copyright')}</span>
                            </div>
                            <FollowUsSection />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Copyright;
