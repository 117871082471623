import {Link} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";

const FaqTransComponent = ({answer}: {answer: string}) => {

    const { t, i18n} = useTranslation();

    return (
        <>
            <Trans t={t} components={{
                ul: <ul />,
                li: <li />,
                h5: <h5 />,
                lb: <a href="https://www.lb.lt" />,
                fntt: <a href="https://www.fntt.lt" />,
                urm: <a href="https://www.urm.lt" />,
                contact: <Link to={`/${t('routes.consult')}`} style={{textDecoration: 'underline'}} />,
                register: <Link to={`/${t('routes.start_using_accept_payments')}`}
                                style={{textDecoration: 'underline'}} />,
                registerTesting: <Link to={`/${t('routes.start_using_install_for_test')}`}
                                       style={{textDecoration: 'underline'}} />,
                demo: <Link to={`/${t('routes.demonstrations_bank_link_payments')}`}
                            style={{textDecoration: 'underline'}} />,
                contactEmail: <a className='contact-by-email'
                                 href={`mailto:info@opay.eu`}
                                 style={{textDecoration: 'underline'}} />,
                selfService: <a href="https://gateway.opay.lt/my" style={{textDecoration: 'underline'}} />,
                payerRights: <a href={i18n.language === 'lt'
                    ? 'https://commission.europa.eu/system/files/2019-11/leaflet-your-rights-payments-eu_lt.pdf'
                    : 'https://commission.europa.eu/system/files/2019-09/leaflet-your-rights-payments-eu_en.pdf' }/>,
            }}>
                {answer}
            </Trans>
        </>
    )
}

export default FaqTransComponent;
