import {useTranslation} from "react-i18next";
import './FooterContacts.css'
import PhoneContact from "./PhoneContact.tsx";
import EmailContact from "./EmailContact.tsx";
import {requisitesAndContacts} from "../../../configs/contacts-page/requisitesAndContacts.ts";

const FooterContacts = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="col-md-8 col-lg-6">
                <div className="opy-footer-header">{t('contacts')}</div>
                <div className="opy-footer-info">
                    <div>{t('contacts_info')}</div>
                    <div>{t('contacts_address')}</div>
                    <PhoneContact />
                    <EmailContact />
                    <div>
                        <p>{requisitesAndContacts.workingHours}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterContacts;
