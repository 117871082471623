import image from '../../../public/assets/images/not-found-page-images/404-image.svg'
import {useTranslation} from "react-i18next";
import {BannerButtonsConfig} from "../../configs/not-found-page/bannerButtonsConfig.ts";
import BannerButton from "../../components/not-found-page-components/BannerButton.tsx";

const NotFoundPage = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="opy-content">
                <div className="opy-page-404">
                    <div className="opy-fullpage">
                        <div className="opy-bg-gray flex-grow-1 flex-column d-flex align-items-center justify-content-center pb-lg-7">
                            <div className="container opy-block">
                                <div className="mb-4 text-center">
                                    <img className="img-fluid" src={image} alt="Page not found image"/>
                                </div>
                                <div className="fw-semibold fs-4 fs-md-3 text-center mb-5">
                                    {t('page_not_found')}
                                </div>
                                <div className="w-lg-75 mx-auto">
                                    <div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-3">
                                        {BannerButtonsConfig.map((button, index) => (
                                            <BannerButton key={index} button={button} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotFoundPage;
