import {Section} from "../../@types/privacyPolicySectionResources.tsx";
import {Language} from "../../@types/generics.tsx";

export const CookiesPolicyPageSectionsConfig: Section[] = [
    {
        heading: 'cookies_policy',
        paragraphs: [
            {
                text: 'cookies_policy_paragraph_1',
                list: [
                    {
                        subList: [
                            {
                                text: '<strong>opay.eu</strong>',
                                className: 'text-blue-dark'
                            },
                            {
                                text: '<strong>gateway.opay.lt</strong>',
                                className: 'text-blue-dark'
                            },
                            {
                                text: '<strong>apps.opay.lt</strong>',
                                className: 'text-blue-dark'
                            },
                            {
                                text: '<strong>opay.dev</strong>',
                                className: 'text-blue-dark'
                            }
                        ],
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'cookies_policy_paragraph_2',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'what_is_cookie_heading',
        paragraphs: [
            {
                text: 'what_is_cookie_paragraph_1',
            },
            {
                text: 'what_is_cookie_paragraph_2',
                list: [
                    {
                        text: 'what_is_cookie_paragraph_2_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_is_cookie_paragraph_2_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_is_cookie_paragraph_2_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_is_cookie_paragraph_2_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                ]
            },
            {
                text: 'what_is_cookie_paragraph_3',
            },
            {
                text: 'what_is_cookie_paragraph_4',
                list: [
                    {
                        text: 'what_is_cookie_paragraph_4_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_is_cookie_paragraph_4_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'what_is_cookie_paragraph_5',
            },
            {
                text: 'what_is_cookie_paragraph_6',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'how_we_use_cookies_heading',
        paragraphs: [
            {
                text: 'how_we_use_cookies_paragraph_1',
                list: [
                    {
                        text: 'how_we_use_cookies_paragraph_1_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'how_we_use_cookies_paragraph_1_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'how_we_use_cookies_paragraph_2',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'what_cookies_and_services_we_use_heading',
        paragraphs: [
            {
                text: 'what_cookies_and_services_we_use_paragraph_1',
            },
            {
                text: 'what_cookies_and_services_we_use_paragraph_2',
                list: [
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_2_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_2_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_2_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_2_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'what_cookies_and_services_we_use_paragraph_3',
                list: [
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_3_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_3_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_3_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_3_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_3_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_3_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_3_list_7',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'what_cookies_and_services_we_use_paragraph_4',
                list: [
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_4_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_4_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_4_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_4_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_4_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_4_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'what_cookies_and_services_we_use_paragraph_5',
            },
            {
                text: 'what_cookies_and_services_we_use_paragraph_6',
            },
            {
                text: 'what_cookies_and_services_we_use_paragraph_7',
                list: [
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_7_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_7_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'what_cookies_and_services_we_use_paragraph_8',
            },
            {
                text: 'what_cookies_and_services_we_use_paragraph_9',
                list: [
                    {
                        text: 'what_cookies_and_services_we_use_paragraph_9_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'what_cookies_and_services_we_use_paragraph_10',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'how_disable_cookies_heading',
        paragraphs: [
            {
                text: 'how_disable_cookies_paragraph_1',
            },
            {
                text: 'how_disable_cookies_paragraph_2',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'cookies_policy_version_heading',
        paragraphs: [
            {
                text: 'cookies_policy_version_paragraph_1',
            },
            {
                text: 'cookies_policy_version_paragraph_2',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    }
]
