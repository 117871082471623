import {Button} from "../../@types/mainBannerButtonLinksResources.tsx";

export const IntegrationBannerButtonLinksConfig: Button[] = [
    {
        to: 'integration',
        toId: 'plugins_id',
        text: 'plugins',
        className: 'd-block d-sm-inline mb-3 me-sm-2 mb-sm-0 text-center opy-button'
    },
    {
        to: 'integration',
        toId: 'for_developers_id',
        text: 'for_developers',
        className: 'd-block d-sm-inline text-center opy-button opy-button--light'
    }
]
