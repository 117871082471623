import CenteredBannerNoImage
    from "../../components/legal-information-page-components/centered-banner-no-image/CenteredBannerNoImage.tsx";
import PricingTable from "../../components/pricing-page-components/pricing-table/PricingTable.tsx";
import PricingTableCalculateButton
    from "../../components/pricing-page-components/pricing-table/PricingTableCalculateButton.tsx";
import PricingFormSection from "../../components/pricing-page-components/pricing-form/PricingFormSection.tsx";
import PricingCalculator
    from "../../components/pricing-page-components/pricing-calculator/calculator/PricingCalculator.tsx";
import Advantages from "../../components/building-online-page-components/advantages/Advantages.tsx";
import {PricingAdvantagesCardsConfig} from "../../configs/pricing-page/pricingAdvantagesCards.ts";
import PricingServices from "../../components/pricing-page-components/pricing-services/PricingServices.tsx";
import GetStartedBanner from "../../components/home-page-components/get-started-banner/GetStartedBanner.tsx";
import {MessageSubjectEnum} from "../../enum/message/MessageSubjectEnum.ts";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const PricingPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.pricing');
    }, []);

    return (
        <>
            <CenteredBannerNoImage heading={'pricing'} paragraph={'pricing_banner_text'}/>
            <PricingTableCalculateButton/>
            <PricingTable/>
            <PricingCalculator/>
            <PricingFormSection heading={'more_than_100_payments'}
                                description={'fill_form_get_offer'}
                                subject={MessageSubjectEnum.SUBJECT_PRICING}
                                page={'pricing'} />
            <Advantages className={'opy-bg-gray pb-6'} heading={null} advantages={PricingAdvantagesCardsConfig} />
            <PricingServices />
            <GetStartedBanner bannerText={'boost_your_business'}
                              linkText={'get_started'}
                              linkClassname={'opy-button opy-button--white opy-button--medium text-center'}
                              linkTo={'routes.start_using_accept_payments'} />
        </>
    )
}

export default PricingPage;
