import ReCAPTCHA from "react-google-recaptcha";
import {useTranslation} from "react-i18next";
import {RefObject} from "react";

const GRecaptcha = (
    {setCaptcha, captchaRef}:
        {setCaptcha: (arg0: boolean|null) => void, captchaRef: RefObject<ReCAPTCHA>}
) => {

    const {i18n} = useTranslation();

    return (
        <>
            <ReCAPTCHA className="g-recaptcha"
                       ref={captchaRef}
                       sitekey="6LfEBg8TAAAAAFkL2EslDtjIlMCKevUbeAT6xAdR"
                       hl={i18n.language}
                       onChange={() => setCaptcha(true)}
                       onExpired={() => setCaptcha(null)} />
        </>
    )
}

export default GRecaptcha;
