import {handleMethodChange} from "../../../../helpers/pricing/calculatorHelper.ts";
import {PaymentMethod} from "../../../../@types/pricingCalculatorFieldsResources.tsx";
import {useTranslation} from "react-i18next";

const SelectOptionsMethodSelect = (
    {sectionClass, sectionHeadingClass, selectedMethod, setSelectedMethod, methods}:
        {
            sectionClass: string,
            sectionHeadingClass: string|undefined,
            selectedMethod: string,
            setSelectedMethod: (arg0: string) => void,
            methods: PaymentMethod[],
        }
) => {

    const {t} = useTranslation();

    return (
        <>
            <div className={sectionClass}>
                <p className={sectionHeadingClass}>{t('payment_method')}</p>
                <select className="calculator_payment_method m-calculator_payment_method"
                        name="calculator_payment_method"
                        value={selectedMethod}
                        onChange={(e) => handleMethodChange(e, setSelectedMethod)}>
                    {methods.map((method, index) => (
                        <option key={index} value={method.name}>
                            {t(method.name)}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}

export default SelectOptionsMethodSelect;
