const PaymentMethodCardBanks = ({banks}: {banks: string[]}) => {
    return (
        <div className="row">
            {banks.map((bank, index) => (
                <div key={index} className="col-6 col-md-2">
                    <img className="img-fluid" src={bank} alt="Payment method icon"/>
                </div>
            ))}
        </div>
    )
}

export default PaymentMethodCardBanks;
