import {Outlet, useLocation} from "react-router-dom";
import Header from "../../header-components/header/Header.tsx";
import Footer from "../../footer-components/footer/Footer.tsx";
import {useEffect} from "react";

const PageTemplate = () => {

    const {pathname} = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
    }, [pathname]);

    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

export default PageTemplate;
