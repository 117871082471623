import axios from "axios";
import {PricingInputs} from "../@types/pricingFormResources.tsx";
import {ConsultInputs} from "../@types/consultFormResources.tsx";
import {ForTestingInputs} from "../@types/forTestingFormResources.ts";
import {AcceptPaymentsInputs} from "../@types/acceptPaymentsFormResources.ts";
import {ListChannelsRequest} from "../../server/@types/listChannelsResources.ts";

export const sendPricingForm = async (body: PricingInputs) => axios.post(`/api/messages/pricing`, body);
export const sendConsultForm = async (body: ConsultInputs) => axios.post(`/api/messages/consult`, body);
export const sendForTestingForm = async (body: ForTestingInputs) => axios.post(`/api/registration/testing`, body);
export const sendAcceptPaymentsForm = async (body: AcceptPaymentsInputs)=> axios.post(
    `/api/registration/accept-payments`,
    body
);
export const checkWebsite = async (body: {website_id: string, language: string}) => axios.post(
    `/api/registration/check-website`,
    body
);
export const listChannels = async (body: ListChannelsRequest) => axios.post(`/api/demo/channels`, body);
export const redirectToPayment = async (body: ListChannelsRequest) => axios.post(`/api/demo/redirect`, body);
