import {List} from "../../../@types/solutionsListResources.tsx";
import SolutionsList from "./SolutionsList.tsx";

const Solutions = ({solutions}: {solutions: List[]}) => {
    return (
        <>
            {solutions.map((solution, index) => (
                <SolutionsList key={index} solution={solution} />
            ))}
        </>
    )
}

export default Solutions;
