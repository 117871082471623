import {Button} from "../../@types/mainBannerButtonLinksResources.tsx";

export const HomeBannerButtonLinksConfig: Button[] = [
    {
        to: 'pricing',
        toId: 'pricing_form',
        text: 'get_offer',
        className: 'd-block d-sm-inline mb-3 me-sm-2 mb-sm-0 text-center opy-button'
    },
    {
        to: 'start_using_accept_payments',
        text: 'get_started',
        className: 'd-block d-sm-inline text-center opy-button opy-button--light'
    }
]
