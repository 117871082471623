import {ExamplePercentageRate} from "../../../../../../@types/pricingCalculatorFieldsResources.tsx";
import arrowImage from '/assets/images/pricing-page-images/round-arrow-right.png';
import {X} from "lucide-react";

const DesktopModalTableExamplePercentageRate = ({rate}: {rate: ExamplePercentageRate}) => {
    return (
        <>
            {rate &&
                <td className="fw-semibold text-center align-middle position-relative" style={{width: '200px'}}>
                    <div className="pt-1">
                        <div className="fs-9 mb-2" id="example-step-1">
                            <span style={{color: '#8F3090'}}>{rate.paymentAmount}</span>
                            <span style={{color: '#7D7D7D'}}> <X size={14} /> </span>
                            <span style={{color: '#FF9E0C'}}>{rate.percentageRate} %</span>
                        </div>
                        <div className="fs-7" id="example-step-1-value" style={{color: '#454545'}}>
                            {rate.result.toFixed(2)} €
                        </div>
                    </div>
                    <img className="position-absolute"
                         src={arrowImage}
                         style={{top: '35px', right: '-15px'}}
                         alt='direction icon' />
                </td>
            }
        </>
    )
}

export default DesktopModalTableExamplePercentageRate;
