import ServiceCard from "./ServiceCard.tsx";
import {ServiceCardsConfig} from "../../../configs/home-page/serviceCards.ts";

const ServiceCardsContainer = () => {

    return (
        <>
            <div className="row mb-5">
                {ServiceCardsConfig.map((card, index) => (
                    <ServiceCard key={index} card={card} />
                ))}
            </div>
        </>
    )
}

export default ServiceCardsContainer;
