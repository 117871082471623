import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from '../locales/en/translation.json';
import translationLt from '../locales/lt/translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-http-backend";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['path', 'querystring', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'cookie', 'subdomain'],
            lookupFromPathIndex: 0,
        },
        debug: false,
        resources: {
            en: {
                translation: translationEn,
            },
            lt: {
                translation: translationLt,
            },
        },
        fallbackLng: 'lt',
    })

export default i18n;
