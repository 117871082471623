import GRecaptcha from "../../g-recaptcha/GRecaptcha.tsx";
import {useTranslation} from "react-i18next";
import {RefObject} from "react";
import ReCAPTCHA from "react-google-recaptcha";

const ForTestingFormAgreementSection = (
    {captcha, setCaptcha, captchaRef}:
        {captcha: boolean|null, setCaptcha: (arg0: boolean|null) => void, captchaRef: RefObject<ReCAPTCHA>}
) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="row mb-6">
                <h2 className="fs-6 my-4">{t('agreement')}</h2>
                <div className="col-sm-12">
                    <div className="form-group">
                        <div id="g-recaptcha" className="g-recaptcha">
                            <GRecaptcha setCaptcha={setCaptcha} captchaRef={captchaRef}/>
                        </div>
                        {captcha === false && <div className="error-block">{t('check_captcha')}</div>}
                    </div>
                    <div className="form-group">
                        <button className="opy-button opy-button--medium" type="submit">{t('send_data')}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForTestingFormAgreementSection;
