import ServiceCardsContainer from "../../home-page-components/services-and-products/ServiceCardsContainer.tsx";
import {useTranslation} from "react-i18next";

const PricingServices = () => {

    const {t} = useTranslation();

    return (
        <>
            <div className="opy-bg-white py-4">
                <div className="container opy-block">
                    <div className="mb-5 pt-5">
                        <h2 className="fs-3 fs-md-2">{t('services_and_products')}</h2>
                    </div>
                    <ServiceCardsContainer/>
                </div>
            </div>
        </>
    )
}

export default PricingServices;
