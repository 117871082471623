import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const StartUsingBannerButtons = ({isForTestingPage}: {isForTestingPage: boolean}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="demo-banner-buttons-container">
                <div className="container opy-block">
                    <div className="opy-tab-buttons">
                        <Link to={`/${t('routes.start_using_install_for_test')}`}
                              className={isForTestingPage
                                  ? 'opy-tab-button opy-tab-button--active'
                                  : 'opy-tab-button'}>
                            {t('install_for_testing')}
                        </Link>
                        <Link to={`/${t('routes.start_using_accept_payments')}`}
                              className={isForTestingPage
                                  ? 'opy-tab-button'
                                  : 'opy-tab-button  opy-tab-button--active'}>
                            {t('install_and_accept_payments')}
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StartUsingBannerButtons;
