import LinkableCard from "./LinkableCard.tsx";
import {Card} from "../../../@types/linkableCardResources.tsx";

const LinkableCardsBanner = ({cards}: {cards: Card[]}) => {
    return (
        <>
            <div className="opy-bg-gray pt-5 pb-6 pb-xl-6">
                <div className="container opy-block">
                    <div className="row">
                        {cards.map((card, index) => (
                            <LinkableCard key={index} card={card} />
                            ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LinkableCardsBanner;
