import {Card} from "../../@types/pluginsCardsResources.tsx";
import pluginImage1 from '/assets/images/integration-page-images/logo-shopify.png';
import pluginImage2 from '/assets/images/integration-page-images/logo-woocommerce.png';
import pluginImage3 from '/assets/images/integration-page-images/logo-prestashop.png';
import pluginImage4 from '/assets/images/integration-page-images/logo-eshoprent.png';
import pluginImage5 from '/assets/images/integration-page-images/logo-verskis.png';
import pluginImage6 from '/assets/images/integration-page-images/logo-opencart.png';
import pluginImage7 from '/assets/images/integration-page-images/logo-magento.png';
import pluginImage8 from '/assets/images/integration-page-images/logo-virtuemart.png';

export const PluginsCardsConfig: Card[] = [
    {
        name: 'SHOPIFY',
        link: 'https://opay.dev/plugins/shopify',
        image: pluginImage1,
    },
    {
        name: 'WORDPRESS-WOOCOMMERCE',
        link: 'https://opay.dev/plugins/wordpress-woocommerce',
        image: pluginImage2,
    },
    {
        name: 'PRESTASHOP',
        link: 'https://opay.dev/plugins/prestashop',
        image: pluginImage3,
    },
    {
        name: 'ESHOPRENT',
        link: 'https://opay.dev/plugins/eshoprent',
        image: pluginImage4,
    },
    {
        name: 'VERSKIS',
        link: 'https://opay.dev/plugins/verskis',
        image: pluginImage5,
    },
    {
        name: 'OPENCART',
        link: 'https://opay.dev/plugins/opencart',
        image: pluginImage6,
    },
    {
        name: 'MAGENTO',
        link: 'https://opay.dev/plugins/magento',
        image: pluginImage7,
    },
    {
        name: 'VIRTUEMART',
        link: 'https://opay.dev/plugins/joomla-virtuemart',
        image: pluginImage8,
    },
]
