import {Section} from "../../@types/generalTermsUpdatesSectionResources.tsx";

export const GeneralTermsUpdatesSectionConfig: Section = {
    heading1: 'general_terms_updates_section_heading_1',
    heading2: 'general_terms_updates_section_heading_2',
    heading3: 'general_terms_updates_section_heading_3',
    button: {
        lt: {
            text: 'previous_version',
            link: 'routes.general_terms_archive'
        },
        en: {
            text: 'privacy_policy',
            link: 'routes.privacy_policy'
        }
    }
}
