import {Section} from "../../../@types/privacyPolicySectionResources.tsx";
import {useTranslation} from "react-i18next";
import LegalPageSectionParagraph from "./LegalPageSectionParagraph.tsx";
import {getCurrentLanguageEnum} from "../../../@types/generics.tsx";

const LegalPageSection = ({section}: {section: Section}) => {

    const {t, i18n} = useTranslation();

    return (
        <>
            {
                section.languageToShow.includes(getCurrentLanguageEnum(i18n.language))
                    ? <div className="opy-bg-white ">
                        <div className="container opy-block">
                            {section.heading && <h2 className="fs-6 mb-3">{t(section.heading)}</h2>}
                            {section.paragraphs.map((paragraph, index) => (
                                section.languageToShow.includes(getCurrentLanguageEnum(i18n.language))
                                    ? <LegalPageSectionParagraph key={index} paragraph={paragraph}/>
                                    : null
                            ))}
                        </div>
                    </div>
                    : null
            }

        </>
    )
}

export default LegalPageSection;
