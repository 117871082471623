import HeaderButtons from "../header-buttons/HeaderButtons.tsx";
import LanguageSelector from "../language-selector/LanguageSelector.tsx";
import {useEffect, useState} from "react";
import {isRouteExist} from "../../../helpers/routes/routesHelper.ts";
import * as routes from "../../../../routes.json"

const ButtonsSection = () => {
    const [isRouteValid, setIsRouteValid] = useState<boolean>(false);

    useEffect(() => {
        setIsRouteValid(isRouteExist(routes, location.pathname.substring(1)));
    }, [location.pathname]);

    return (
        <>
            <ul className="navbar-nav ms-auto">
                <HeaderButtons />
                {isRouteValid && <LanguageSelector />}
            </ul>
        </>
    )
}

export default ButtonsSection;
