import {Field} from "../../@types/pricingCalculatorFieldsResources.tsx";
import {Fees} from "./pricingTablePaymentServicesConfig.ts";

export const PricingCalculatorConfig: Field[] = [
    {
        name: 'lithuania',
        paymentMethods: [
            {
                name: 'pis_service',
                percentageRate: Fees.pis.lithuania.percentageRate,
                minRate: Fees.pis.lithuania.minRate,
                maxRate: Fees.pis.lithuania.maxRate,
                fixedRate: Fees.pis.lithuania.fixedRate
            },
            {
                name: 'bank_link_service',
                percentageRate: Fees.bank_link.lithuania.percentageRate,
                minRate: Fees.bank_link.lithuania.minRate,
                maxRate: Fees.bank_link.lithuania.maxRate,
                fixedRate: Fees.bank_link.lithuania.fixedRate
            },
            {
                name: 'card_payments_service',
                percentageRate: Fees.card.europe.percentageRate,
                minRate: Fees.card.europe.minRate,
                maxRate: Fees.card.europe.maxRate,
                fixedRate: Fees.card.europe.fixedRate
            },
            {
                name: 'wire_transfers_service',
                percentageRate: Fees.wire_transfers.europe.percentageRate,
                minRate: Fees.wire_transfers.europe.minRate,
                maxRate: Fees.wire_transfers.europe.maxRate,
                fixedRate: Fees.wire_transfers.europe.fixedRate
            },
            {
                name: 'installment_purchases_service',
                percentageRate: Fees.installment_purchases.lithuania.percentageRate,
                minRate: Fees.installment_purchases.lithuania.minRate,
                maxRate: Fees.installment_purchases.lithuania.maxRate,
                fixedRate: Fees.installment_purchases.lithuania.fixedRate
            },
            {
                name: 'cash_payments_service',
                percentageRate: Fees.cash.lithuania.percentageRate,
                minRate: Fees.cash.lithuania.minRate,
                maxRate: Fees.cash.lithuania.maxRate,
                fixedRate: Fees.cash.lithuania.fixedRate
            }
        ]
    },
    {
        name: 'latvia',
        paymentMethods: [
            {
                name: 'pis_service',
                percentageRate: Fees.pis.latvia.percentageRate,
                minRate: Fees.pis.latvia.minRate,
                maxRate: Fees.pis.latvia.maxRate,
                fixedRate: Fees.pis.latvia.fixedRate
            },
            {
                name: 'card_payments_service',
                percentageRate: Fees.card.europe.percentageRate,
                minRate: Fees.card.europe.minRate,
                maxRate: Fees.card.europe.maxRate,
                fixedRate: Fees.card.europe.fixedRate
            },
            {
                name: 'wire_transfers_service',
                percentageRate: Fees.wire_transfers.europe.percentageRate,
                minRate: Fees.wire_transfers.europe.minRate,
                maxRate: Fees.wire_transfers.europe.maxRate,
                fixedRate: Fees.wire_transfers.europe.fixedRate
            }
        ]
    },
    {
        name: 'estonia',
        paymentMethods: [
            {
                name: 'pis_service',
                percentageRate: Fees.pis.estonia.percentageRate,
                minRate: Fees.pis.estonia.minRate,
                maxRate: Fees.pis.estonia.maxRate,
                fixedRate: Fees.pis.estonia.fixedRate
            },
            {
                name: 'card_payments_service',
                percentageRate: Fees.card.europe.percentageRate,
                minRate: Fees.card.europe.minRate,
                maxRate: Fees.card.europe.maxRate,
                fixedRate: Fees.card.europe.fixedRate
            },
            {
                name: 'wire_transfers_service',
                percentageRate: Fees.wire_transfers.europe.percentageRate,
                minRate: Fees.wire_transfers.europe.minRate,
                maxRate: Fees.wire_transfers.europe.maxRate,
                fixedRate: Fees.wire_transfers.europe.fixedRate
            }
        ]
    },
    {
        name: 'europe',
        paymentMethods: [
            {
                name: 'card_payments_service',
                percentageRate: Fees.card.europe.percentageRate,
                minRate: Fees.card.europe.minRate,
                maxRate: Fees.card.europe.maxRate,
                fixedRate: Fees.card.europe.fixedRate
            },
            {
                name: 'wire_transfers_service',
                percentageRate: Fees.wire_transfers.europe.percentageRate,
                minRate: Fees.wire_transfers.europe.minRate,
                maxRate: Fees.wire_transfers.europe.maxRate,
                fixedRate: Fees.wire_transfers.europe.fixedRate
            }
        ]
    }
]