import {useTranslation} from "react-i18next";

const CenteredBannerNoImage = ({heading, paragraph}: {heading: string, paragraph: string}) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="opy-content">
                <div className="opy-bg-white py-5 py-lg-6">
                    <div className="container opy-block">
                        <div className="py-4 py-md-5 d-flex flex-column align-items-center justify-content-center">
                            <h1 className="fs-4 fs-md-3 fs-lg-1 mb-5">{t(heading)}</h1>
                            <p className="text-center mw-md-75">{t(paragraph)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CenteredBannerNoImage;
