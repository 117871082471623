import {useTranslation} from "react-i18next";
import BannerImageLeft from "./BannerImageLeft.tsx";
import BannerImageRight from "./BannerImageRight.tsx";
import {Banner} from "../../../@types/homePageTwoColumnsDynamicBannersResources.tsx";

const TwoColumnsDynamicBanners = ({heading, config}: {heading: string, config: Banner[]}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="opy-bg-white">
                <div className="pb-5">
                    <div className="container opy-block">
                        <div className="mb-5 mb-md-6">
                            <h2 className="fs-3 fs-md-2">{t(heading)}</h2>
                        </div>
                    </div>
                    {config.map((banner, index) => (
                        banner.imagePosition === 'left'
                            ? <BannerImageLeft key={index} banner={banner} />
                            : <BannerImageRight key={index} banner={banner} />
                        ))}
                </div>
            </div>
        </>
    )
}

export default TwoColumnsDynamicBanners;
