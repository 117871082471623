import AdvantageItem from "./AdvantageItem.tsx";
import {useTranslation} from "react-i18next";
import {AdvantageSubItem} from "../../../@types/advantagesResources.tsx";

const AdvantageImageLeft = ({heading, image, links}: {heading: string, image: string, links: AdvantageSubItem[]}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="container opy-block">
                <div className="row align-items-center mb-5 mb-md-6">
                    <div className="col-lg-6 mb-4 mb-md-0">
                        <div>
                            <img className="img-fluid" src={image} alt="Advantage banner image"/>
                        </div>
                    </div>
                    <div className="col-lg-6 ps-4">
                        <h3 className="fs-4 fs-md-3 mb-5">{t(heading)}</h3>
                        <ul className="opy-features__list">
                            {links.map((link, index) => (
                                <AdvantageItem key={index} image={link.image} text={link.text} />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdvantageImageLeft;
