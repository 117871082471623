import {Item} from "../../@types/purchaseExampleItemsResources.tsx";
import item1 from '/assets/images/demo-pages-images/headphones.png';
import item2 from '/assets/images/demo-pages-images/box.png';


export const PurchaseExampleItemsConfig: Item[] = [
    {
        image: item1,
        name: 'gaming_headphones',
        description: 'item_code_4564',
        amount: '1 x 0.01 €'
    },
    {
        image: item2,
        name: 'items_delivery',
        description: 'courier',
        amount: '1 x 0.01 €'
    }
]
