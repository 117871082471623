import {useTranslation} from "react-i18next";
import {Card} from "../../@types/demonstrationsCardsResources.tsx";
import {Link} from "react-router-dom";

const DemonstrationsCard = ({card}: {card: Card}) => {

    const {t} = useTranslation();

    return (
        <>
            <Link className="col-md-6 col-xl-4 mb-4" to={`/${t(card.link)}`}>
                <div className="px-3 py-3 d-flex align-items-center bg-white demonstrations-card-container">
                    <img className="me-3" src={card.image} alt="demonstration icon"/>
                    <div className="fw-semibold mw-50 text-blue-dark">{t(card.title)}</div>
                </div>
            </Link>
        </>
    )
}

export default DemonstrationsCard;
