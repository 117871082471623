import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {UsefulSectionConfig} from "../../../configs/layout/footerLinks.ts";
import {getCurrentLanguageEnum} from "../../../@types/generics.tsx";

const FooterUsefulSection = () => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="col-md-8 col-lg-2">
                <div className="opy-footer-header">{t('useful')}</div>
                <div className="opy-footer-info fw-semibold">
                    {UsefulSectionConfig.map((link, index) => (
                        link.languageToShow.includes(getCurrentLanguageEnum(i18n.language))
                        && <Link to={t(`routes.${link.to}`)} key={index}>
                            <span>{t(link.text)}</span>
                        </Link>
                    ))}
                    <p className="cookies-settings-para" data-cc="c-settings">{t('cookies_settings')}</p>
                </div>
            </div>
        </>
    )
}

export default FooterUsefulSection;
