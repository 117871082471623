import './GetStartedBanner.css';
import {useTranslation} from "react-i18next";
import CustomLink from "../../custom-link/CustomLink.tsx";

const GetStartedBanner = (
    {bannerText, linkTo, linkClassname, linkText}:
    {bannerText: string, linkTo: string, linkClassname: string, linkText: string}
) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="container opy-block">
                <div className="pt-6 pb-6 px-5 my-4 position-relative overflow-hidden banner-container">
                    <div className="left-banner-side banner"></div>
                    <div className="d-none d-md-block right-banner-side banner"></div>
                    <div className="row">
                        <div className="col-md-7 text-center text-md-start position-relative text-white mb-3 mb-md-0">
                            <p className="fw-semibold fs-5 fs-xl-4">{t(bannerText)}</p>
                        </div>
                        <div className="col-md-5 d-flex align-items-center justify-content-center justify-content-md-end position-relative">
                            <CustomLink to={`/${t(linkTo)}`}
                                        className={linkClassname}
                                        children={t(linkText)} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GetStartedBanner;
