export enum Language {
    LT = 'lt',
    EN = 'en'
}

export enum LanguageIso3 {
    LT = 'lit',
    EN = 'eng'
}

export type Translatable<T> = {
    [key in Language]: T
}

export const getCurrentLanguageEnum = (language: string): Language => {
    return language === 'lt' ? Language.LT : Language.EN;
}

export const getFromIso2 = (language: string): LanguageIso3 => {
    switch (language) {
        case 'lt':
            return LanguageIso3.LT;
        case 'en':
            return LanguageIso3.EN;
        default:
            return LanguageIso3.LT;
    }
}
