import {Banner} from "../../../@types/homePageTwoColumnsDynamicBannersResources.tsx";
import {useTranslation} from "react-i18next";

const BannerImageRight = ({banner}: {banner: Banner}) => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="container opy-block">
                <div className="row align-items-center">
                    <div className="col-lg-6 px-sm-3 px-lg-6 order-2 order-lg-1">
                        <h3 className="fs-4 fs-sm-3 fs-lg-4 fs-xl-3 mb-3">{t(banner.heading)}</h3>
                        <p className="mb-4">{t(banner.text)}</p>
                    </div>
                    <div className="col-lg-6 mb-5 mb-lg-0 order-1 order-lg-2">
                        <div>
                            <img className="img-fluid opy-border-radius-4"
                                 src={i18n.language === 'lt' ? banner.image.lt : banner.image.en}
                                 alt="Banner image"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BannerImageRight;
