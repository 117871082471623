import {ExampleFixedRate} from "../../../../../../@types/pricingCalculatorFieldsResources.tsx";

const DesktopModalTableExampleFixedRate = ({rate}: {rate: ExampleFixedRate}) => {
    return (
        <>
            {rate &&
                <td className="fw-semibold text-center align-middle" style={{width: '200px'}}>
                    <div className="pt-1">
                        <div className="fs-9 mb-2" id="example-step-4">
                            <span style={{color: '#7D7D7D'}}>
                                {rate.calculationResult <= 0 ? 0 : rate.calculationResult.toFixed(2)} € +
                            </span>
                            <span style={{color: '#09A563'}}> {rate.fixedRate.toFixed(2)} €</span>
                        </div>
                        <div className="fs-7" id="example-step-4-value" style={{color: '#454545'}}>
                            {rate.result.toFixed(2)} €
                        </div>
                    </div>
                </td>
            }
        </>
    )
}

export default DesktopModalTableExampleFixedRate;
