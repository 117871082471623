import SelectOptions from "../../select-options/SelectOptions.tsx";
import {useTranslation} from "react-i18next";

const DesktopPricingCalculatorModal = () => {

    const {t} = useTranslation();

    return (
        <>
            <div id="calculator-desktop" className="d-none d-md-block prices-example pt-6 pb-5">
                <div className="position-absolute fs-3 cursor-pointer"
                     style={{top: '10px', right:' 30px'}}
                     data-bs-toggle="collapse"
                     data-bs-target="#pricesExample"
                     aria-expanded="false"
                     aria-controls="#pricesExample">×
                </div>
                <div className="fs-7 fw-semibold text-center text-blue-dark eld">
                    <h2 className="fs-3 fs-md-3 elh">{t('commission_calculator')}</h2>
                </div>
                <SelectOptions flex={true}
                               sectionClass={'calculator_el'}
                               sectionHeadingClass={undefined}
                               mobile={false} />
            </div>
        </>
    )
}

export default DesktopPricingCalculatorModal;
