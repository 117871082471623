import {ExampleFields} from "../../../../../../@types/pricingCalculatorFieldsResources.tsx";
import DesktopModalTableExamplePercentageRate
    from "./DesktopModalTableExamplePercentageRate.tsx";
import DesktopModalTableExampleMinRate
    from "./DesktopModalTableExampleMinRate.tsx";
import DesktopPricingCalculatorModalTableCalculationExampleMaxRate
    from "./DesktopModalTableExampleMaxRate.tsx";
import DesktopModalTableExampleFixedRate
    from "./DesktopModalTableExampleFixedRate.tsx";

const DesktopModalTableExample = ({exampleFields}: {exampleFields: ExampleFields}) => {
    return (
        <>
            <tr style={{height: '100px'}}>
                <DesktopModalTableExamplePercentageRate
                    rate={exampleFields.percentageRate} />
                <DesktopModalTableExampleMinRate rate={exampleFields.minRate} />
                <DesktopPricingCalculatorModalTableCalculationExampleMaxRate rate={exampleFields.maxRate} />
                <DesktopModalTableExampleFixedRate rate={exampleFields.fixedRate} />
            </tr>
        </>
    )
}

export default DesktopModalTableExample;
