import {useTranslation} from "react-i18next";
import * as data from '../../../../routes.json'
import {Language} from "../../../@types/generics.tsx";
import {isRouteExist} from "../../../helpers/routes/routesHelper.ts";
import {log} from "../../../logger/log.ts";

type MyData = {
    [key: string]: {
        [nestedKey: string]: string;
    };
};

const LanguageSelector = () => {

    const {t, i18n} = useTranslation();
    const availableLanguages: Language[] = [Language.LT, Language.EN];

    const routes: MyData = data;

    const handleLanguageChange = async (language: string) => {
        await i18n.changeLanguage(language);
        const currentPath = window.location.pathname.substring(1);

        if (currentPath !== '/' && currentPath.endsWith('/')) {
            currentPath.slice(0, -1);
        }

        if (!isRouteExist(routes, currentPath)) {
            log.error({
                message: 'Language selector: Route does not exist',
                route: currentPath,
            });
            location.href = '/';
        }

        const translationKey = findCurrentRouteTranslationKey(currentPath);
        location.href = `/${t(`routes.${translationKey}`)}`;
    }

    const findCurrentRouteTranslationKey = (route: string) => {

        if (route.charAt(route.length - 1) === '/') {
            route = route.slice(0, -1);
        }

        let translationKey = '';
        for (const key in routes) {
            if (Object.prototype.hasOwnProperty.call(routes, key)) {
                const nestedRoutes = routes[key];

                if (nestedRoutes && Object.values(nestedRoutes).includes(route)) {
                    translationKey = key;
                    break;
                }
            }
        }

        return translationKey;
    }

    return (
        <>
            <li className="nav-item dropdown langswitcher fw-semibold">
                <a className="nav-link dropdown-toggle" href="#" id="navBarDropdown" role="button"
                   data-bs-toggle="dropdown">
                    <span>{i18n.language.toUpperCase()}</span>
                </a>
                <ul className="dropdown-menu">
                    <li>
                        {availableLanguages.map((language, index) => (
                            <span key={index}
                               className='dropdown-item'
                               onClick={() => handleLanguageChange(language)}>
                                <span>{language.toUpperCase()}</span>
                            </span>
                        ))}
                    </li>
                </ul>
            </li>
        </>
    )
}

export default LanguageSelector;
