import SmallMainBanner from "../../components/building-online-page-components/small-main-banner/SmallMainBanner.tsx";
import LongImageBanner from "../../components/home-page-components/long-image-banner/LongImageBanner.tsx";
import image from "/assets/images/accelerating-online-page-images/long-banner-image-1.jpg";
import Advantages from "../../components/building-online-page-components/advantages/Advantages.tsx";
import {AcceleratingOnlineAdvantagesCardsConfig} from "../../configs/accelerating-online-page/acceleratingOnlineAdvantagesCards.ts";
import Solutions from "../../components/accelerating-online-page-components/solutions/Solutions.tsx";
import {AcceleratingOnlineSolutionsListConfig} from "../../configs/accelerating-online-page/acceleratingOnlineSolutionsListItems.ts";
import Benefits from "../../components/building-online-page-components/benefits/Benefits.tsx";
import {AcceleratingOnlineBenefitsSectionsConfig} from "../../configs/accelerating-online-page/acceleratingOnlineBenefitsSections.ts";
import GetStartedBanner from "../../components/home-page-components/get-started-banner/GetStartedBanner.tsx";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const AcceleratingOnlinePage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.accelerating_online');
    }, []);

    return (
        <>
            <SmallMainBanner heading={'accelerating_online_business_banner_heading'}
                             description={'accelerating_online_business_banner_description'} />
            <LongImageBanner headingClass={'fs-5 mb-3 fw-semibold text-blue-dark'}
                             heading={'business_potential'}
                             descriptions={[
                                 'business_potential_description_1',
                                 'business_potential_description_2',
                                 'business_potential_description_3',
                             ]}
                             descriptionClass={'text-blue-dark'}
                             logo={image} />
            <Advantages className={'opy-bg-white pt-6 pb-6'}
                        heading={'our_advantages'}
                        advantages={AcceleratingOnlineAdvantagesCardsConfig} />
            <div className="opy-bg-white opy-features pt-6">
                <Solutions solutions={AcceleratingOnlineSolutionsListConfig} />
            </div>
            <Benefits benefits={AcceleratingOnlineBenefitsSectionsConfig} />
            <GetStartedBanner bannerText={'get_consult_banner'}
                              linkTo={'routes.consult'}
                              linkClassname={'opy-button opy-button--white opy-button--medium text-center'}
                              linkText={'get_consult_text'} />
        </>
    )
}

export default AcceleratingOnlinePage;
