import PurchaseExampleHeader from "./PurchaseExampleHeader.tsx";
import PurchaseExampleCheckout from "./PurchaseExampleCheckout.tsx";

const PurchaseExample = ({page, channelsInCheckout}: {page: string, channelsInCheckout: boolean}) => {
    return (
        <>
            <div className="opy-bg-white pb-5 d-flex justify-content-center">
                <div style={{marginTop: '-70px'}}>
                    <div className="container opy-block">
                        <PurchaseExampleHeader />
                        <PurchaseExampleCheckout page={page} channelsInCheckout={channelsInCheckout} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PurchaseExample;
