import SelectOptions from "../../select-options/SelectOptions.tsx";
import {useTranslation} from "react-i18next";
import {X} from "lucide-react";

const MobilePricingCalculatorModal = () => {

    const {t} = useTranslation();

    return (
        <>
            <div id="calculator-mobile" className="d-block d-md-none prices-example-mobile pt-6 pb-5">
                <div className="position-absolute fs-3 cursor-pointer"
                     style={{top: '10px', right:'30px'}}
                     data-bs-toggle="collapse"
                     data-bs-target="#pricesExample"
                     aria-expanded="false"
                     aria-controls="#pricesExample">
                    <X />
                </div>
                <div className="elh mb-4">{t('commission_calculator')}</div>
                <SelectOptions flex={false}
                               sectionClass={'calculator_el mb-4'}
                               sectionHeadingClass={'mx-0 text-center'}
                               mobile={true} />
            </div>
        </>
    )
}

export default MobilePricingCalculatorModal;
