import {List} from "../../@types/solutionsListResources.tsx";
import solutions1 from '/assets/images/demo-pages-images/blue-feature-system.svg';

export const DemoSelfServiceSolutionsListConfig: List[] = [
    {
        subheading: 'communication_between_services',
        subheadingClassName: 'fs-4 mb-4',
        image: solutions1,
        classNameFirst: 'row align-items-center opy-bg-white pt-md-4 pb-4',
        classNameSecond: 'col-lg-6 ps-lg-5 d-flex flex-column justify-content-center mb-6',
        content: [
            'communication_between_services_description_1',
            'communication_between_services_description_2',
            'communication_between_services_description_3',
            'communication_between_services_description_4',
        ]
    }
]
