import {useTranslation} from "react-i18next";
import CustomLink from "../../custom-link/CustomLink.tsx";

const HeaderButtons = () => {

    const {t} = useTranslation();

    return (
        <>
            <li className="nav-item nav-item--action">
                <a className="opy-button opy-button--light opy-button--medium"
                   href="https://gateway.opay.lt/my/">
                    <span>{t('self_service_1')}</span>
                </a>
            </li>
            <li className="nav-item nav-item--action">
                <CustomLink to={t('routes.start_using_accept_payments')}
                            className={'opy-button opy-button--medium'}
                            children={t('get_started')}/>
            </li>
        </>
    )
}

export default HeaderButtons;
