import {Button} from "../../@types/mainBannerButtonLinksResources.tsx";

export const WhyOpayBannerButtonLinksConfig: Button[] = [
    {
        to: 'start_using_accept_payments',
        text: 'get_started',
        className: 'd-block d-sm-inline mb-3 me-sm-2 mb-sm-0 text-center opy-button'
    },
    {
        to: 'pricing',
        text: 'pricing',
        className: 'd-block d-sm-inline text-center opy-button opy-button--light'
    }
]
