import {Card} from "../../@types/paymentFlowCardsResources.tsx";
import step1LtImage from "/assets/images/payment-methods-page-images/payment-flow-step-1-lt.png";
import step1EnImage from "/assets/images/payment-methods-page-images/payment-flow-step-1-en.png";
import step2LtImage from "/assets/images/payment-methods-page-images/payment-flow-step-2-lt.png";
import step2EnImage from "/assets/images/payment-methods-page-images/payment-flow-step-2-en.png";
import step3LtImage from "/assets/images/payment-methods-page-images/payment-flow-step-3-lt.png";
import step3EnImage from "/assets/images/payment-methods-page-images/payment-flow-step-3-en.png";
import step4Image from "/assets/images/payment-methods-page-images/payment-flow-step-4.png";

export const PaymentFlowCardsConfig: Card[] = [
    {
        image: {
            lt: step1LtImage,
            en: step1EnImage,
        },
        stepNumber: '1',
        mainText: 'payment_flow_step_1_description',
        buttonText: 'next_step'
    },
    {
        image: {
            lt: step2LtImage,
            en: step2EnImage,
        },
        stepNumber: '2',
        mainText: 'payment_flow_step_2_description',
        buttonText: 'next_step'
    },
    {
        image: {
            lt: step3LtImage,
            en: step3EnImage,
        },
        stepNumber: '3',
        mainText: 'payment_flow_step_3_description',
        buttonText: 'next_step'
    },
    {
        image: {
            lt: step4Image,
            en: step4Image,
        },
        stepNumber: '3',
        mainText: 'payment_flow_step_4_description',
        buttonText: 'to_start'
    }
]
