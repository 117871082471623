import BannerWithElement from "../../components/demo-pages-components/demo-banner/BannerWithElement.tsx";
import StartUsingBannerButtons from "../../components/for-testing-page-components/StartUsingBannerButtons.tsx";
import StartUsingDescription from "../../components/for-testing-page-components/StartUsingDescription.tsx";
import AcceptPaymentsForm from "../../components/accept-payments-page-components/form/AcceptPaymentsForm.tsx";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const AcceptPaymentsPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.accept_payments');
    }, []);

    return (
        <>
            <div className="opy-content">
                <BannerWithElement heading={'start_using'}
                                   element={<StartUsingBannerButtons isForTestingPage={false}/>}
                                   slider={false}/>
                <div className="clearfix" style={{paddingTop: '50px'}}>
                    <StartUsingDescription description1={'fill_form_to_accept_payments'}
                                           description2={'after_start_using_form_filling'}/>
                    <AcceptPaymentsForm />
                </div>
            </div>
        </>
    )
}

export default AcceptPaymentsPage;
