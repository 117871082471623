import ConsultPageDescription from "../../components/consult-page/ConsultPageDescription.tsx";
import ConsultForm from "../../components/consult-page/ConsultForm.tsx";
import {useEffect, useState} from "react";
import PricingFormThankYou from "../../components/pricing-page-components/pricing-form/PricingFormThankYou.tsx";
import {useTranslation} from "react-i18next";

const ConsultPage = () => {

    const [submit, setSubmit] = useState(false);
    const [captcha, setCaptcha] = useState<boolean|null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.consult');
    }, []);

    return (
        <>
            <div className="opy-page-consult opy-bg-white">
                <div className={submit ? 'pt-6 pb-7' : 'opy-fullpage pt-6'}>
                    <div className="container opy-block mt-6">
                        <div className="row">
                            <ConsultPageDescription />
                            {submit
                                ? <PricingFormThankYou />
                                : <ConsultForm captcha={captcha} setCaptcha={setCaptcha} setSubmit={setSubmit}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConsultPage;
