import {useTranslation} from "react-i18next";

const LegalBanner = ({heading, image}: {heading: string, image: string}) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="opy-content">
                <div className="opy-bg-white py-5 py-lg-6">
                    <div className="container opy-block">
                        <div className="row pt-5 pb-5 pt-md-6 pb-md-6 text-center">
                            <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center">
                                <div className="d-flex">
                                    <div className="me-4">
                                        <img className="img-fluid" src={image} alt=""/>
                                    </div>
                                    <h1 className="fs-4 fs-md-3 fs-xl-1 mb-4">{t(heading)}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LegalBanner;
