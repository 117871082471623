import {List} from "../../@types/solutionsListResources.tsx";
import solutions1 from '/assets/images/accelerating-online-page-images/solutions-1.jpg';

export const AcceleratingOnlineSolutionsListConfig: List[] = [
    {
        heading: 'streamlined_payment_solutions',
        image: solutions1,
        classNameFirst: 'row align-items-center mb-5 mb-lg-6',
        classNameSecond: 'col-lg-6 ps-lg-5 pe-lg-6 mb-5',
        content: [
            'streamlined_payment_solutions_description_1',
            'streamlined_payment_solutions_description_2',
            'streamlined_payment_solutions_description_3',
            'streamlined_payment_solutions_description_4',
            'streamlined_payment_solutions_description_5',
        ]
    }
]
