import {useTranslation} from "react-i18next";
import DesktopModalTableRates from "./DesktopModalTableRates.tsx";
import {ExampleFields, PaymentMethod} from "../../../../../../@types/pricingCalculatorFieldsResources.tsx";
import DesktopModalTableExample
    from "./DesktopModalTableExample.tsx";

const DesktopModalTable = (
    {rates, exampleFields}: {rates: PaymentMethod|undefined, exampleFields: ExampleFields}
) => {

    const {t} = useTranslation();

    return (
        <>
            {rates
                && <table className="prices-table table border bg-white calctable">
                    <thead>
                    <tr className="text-white" style={{backgroundColor: '#132748'}}>
                        <th className="text-center">{t('percentage_rate')}</th>
                        <th className="text-center">{t('min_rate')}</th>
                        <th className="text-center">{t('max_rate')}</th>
                        <th className="text-center">{t('fixed_rate')}</th>
                    </tr>
                    </thead>
                    <tbody className="text-black">
                    <tr>
                        <td colSpan={4}>
                            <div className="fs-7 text-center text-blue-dark mb-3 elmb">
                                {t('calculation_example')}
                            </div>
                        </td>
                    </tr>
                    <DesktopModalTableExample exampleFields={exampleFields} />
                    <DesktopModalTableRates rates={rates} />
                    </tbody>
                </table>
            }
        </>
    )
}

export default DesktopModalTable;
