import './DemonstrationsCards.scss';
import {Cards} from "../../@types/demonstrationsCardsResources.tsx";
import {useTranslation} from "react-i18next";
import DemonstrationsCard from "./DemonstrationsCard.tsx";

const DemonstrationsCards = ({config}: {config: Cards}) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="opy-page-demo-donation">
                <div className="opy-bg-white pt-4 pb-4">
                    <div className="container opy-block">
                        <div className="mb-4">
                            <h2 className="fs-7 text-blue">{t(config.heading)}</h2>
                            <div className="demonstrations-heading-line"></div>
                        </div>
                        <div className="row">
                            {config.cards.map((card, index) => (
                                <DemonstrationsCard card={card} key={index} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DemonstrationsCards;
