import {Cards} from "../../@types/demonstrationsCardsResources.tsx";
import serviceLogo1 from '/assets/images/home-page-images/icon-service-1.svg'
import serviceLogo2 from '/assets/images/home-page-images/icon-service-3.svg'
import serviceLogo3 from '/assets/images/online-banking-page-images/icon-demo-3.svg'

export const PisDemonstrationsCardsConfig: Cards = {
    heading: 'other_demonstrations',
    cards: [
        {
            link: 'routes.demonstrations_bank_link_payments',
            image: serviceLogo1,
            title: 'bank_link_payments'
        },
        {
            link: 'routes.demonstrations_donations',
            image: serviceLogo2,
            title: 'donation_collection'
        },
        {
            link: 'routes.demonstrations_self_service',
            image: serviceLogo3,
            title: 'self_service_2'
        }
    ]
}
