import {Section} from "../../@types/singlePaymentMethodDescriptionSectionResources.tsx";

export const CashPaymentsDescriptionSectionConfig: Section = {
    heading: 'what_is_cash_payments_heading',
    description: 'what_is_cash_payments_description',
    paragraphs: [
        {
            heading: 'how_cash_payments_works_heading',
            orderedList: [
                'how_cash_payments_works_list_1',
                'how_cash_payments_works_list_2',
                'how_cash_payments_works_list_3',
                'how_cash_payments_works_list_4',
                'how_cash_payments_works_list_5',
            ]
        },
        {
            heading: 'features_heading',
            unorderedList: [
                'cash_payments_features_list_1',
                'cash_payments_features_list_2',
                'cash_payments_features_list_3',
                'cash_payments_features_list_4',
                'cash_payments_features_list_5',
            ],
            description: 'cash_payments_features_description'
        }
    ]
}
