import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import gif from '/assets/images/home-page-images/plugins-slide.gif'

const PluginsServiceBanner = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="opy-bg-white pb-6">
                <div className="row mx-0">
                    <div className="col-lg-6 order-2 order-md-1 text-start pt-6">
                        <img className="img-fluid plugins-slide" src={gif} alt="Plugins slide"/>
                    </div>
                    <div
                        className="col-lg-6 order-1 order-md-2 ps-lg-6 fs-7 d-flex flex-column justify-content-center">
                        <h2 className="fs-4 fs-lg-3 mb-4">{t('easily_integrate')}</h2>
                        <p className="mb-5">{t('easily_integrate_description')}</p>
                        <div>
                            <Link to={`/${t('routes.integration')}`}
                                  className="opy-button">
                                <span>{t('learn_more_2')}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PluginsServiceBanner;
