import {AdvantageItem} from "../../../@types/demoAdvantagesResources.tsx";
import {useTranslation} from "react-i18next";

const DemoAdvantage = ({advantageItem}: {advantageItem: AdvantageItem}) => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="col-lg-4 mb-5">
                <div>
                    <img className="img-fluid mb-3"
                         src={i18n.language === 'lt' ? advantageItem.image.lt : advantageItem.image.en}
                         alt='advantage image' />
                    <p className="text-blue-dark">{t(advantageItem.description)}</p>
                </div>
            </div>
        </>
    )
}

export default DemoAdvantage;
