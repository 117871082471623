import mainBannerImage from "/assets/images/solutions-shopify-page/opay-shopify-1.svg";
import MainBanner from "../../components/home-page-components/main-banner/MainBanner.tsx";
import {
    SolutionsShopifyBannerButtonLinksConfig
} from "../../configs/solutions-shopify-page/solutionsShopifyPageMainBanner.ts";
import TwoColumnsDynamicBanners from "../../components/home-page-components/home-why-opay/TwoColumnsDynamicBanners.tsx";
import {SolutionsShopifyBannersConfig} from "../../configs/solutions-shopify-page/solutionsShopifyTwoColumnsDynamicBanners.ts";
import PricingFormSection from "../../components/pricing-page-components/pricing-form/PricingFormSection.tsx";
import {MessageSubjectEnum} from "../../enum/message/MessageSubjectEnum.ts";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const SolutionsShopifyPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.solutions_shopify');
    }, []);

    return (
        <>
            <MainBanner image={mainBannerImage}
                        links={SolutionsShopifyBannerButtonLinksConfig}
                        heading={'exclusive_solution'}
                        paragraph={'solutions_shopify_banner_description'} />
            <TwoColumnsDynamicBanners heading={'why_opay'} config={SolutionsShopifyBannersConfig}/>
            <PricingFormSection heading={'up_your_conversion'}
                                description={'fill_form_get_offer'}
                                subject={MessageSubjectEnum.SUBJECT_PRICING_SHOPIFY}
                                page={'solutions-shopify'} />
        </>
    )
}

export default SolutionsShopifyPage;
