import {useTranslation} from "react-i18next";
import IsLegalEntityRadioButtons from "./IsLegalEntityRadioButtons.tsx";
import {
    AcceptPaymentsPageFormSectionsIndividualConfig
} from "../../../configs/accept-payments-page/acceptPaymentsPageFormSectionsIndividual.ts";
import FormSection from "../../registration-form/FormSection.tsx";
import {UseFormRegister} from "react-hook-form";
import {AcceptPaymentsInputs} from "../../../@types/acceptPaymentsFormResources.ts";
import {
    AcceptPaymentsPageFormSectionsCompanyConfig
} from "../../../configs/accept-payments-page/acceptPaymentsPageFormSectionsCompany.ts";

const MerchantDataSection = (
    {register, isLegalEntity, setIsLegalEntity}:
        {register: UseFormRegister<AcceptPaymentsInputs>, isLegalEntity: boolean, setIsLegalEntity: (arg0: boolean) => void}
) => {

    const { t } = useTranslation();

    return (
        <>
            <h2 className="fs-5 mb-4">{t('seller_data')}</h2>
            <IsLegalEntityRadioButtons isLegalEntity={isLegalEntity} setIsLegalEntity={setIsLegalEntity} />
            <div id='merchant-section'>
                {isLegalEntity
                    ? AcceptPaymentsPageFormSectionsCompanyConfig.map((section, index) => (
                        <FormSection key={index} section={section} register={register} />
                    ))
                    : AcceptPaymentsPageFormSectionsIndividualConfig.map((section, index) => (
                        <FormSection key={index} section={section} register={register} />
                    ))
                }
            </div>
        </>
    )
}

export default MerchantDataSection;
