import {useTranslation} from "react-i18next";

const IsWebsiteRegisteredRadioButtons = (
    {isWebsiteRegistered, setIsWebsiteRegistered}:
        {isWebsiteRegistered: boolean, setIsWebsiteRegistered: (arg0: boolean) => void}
) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="form-group">
                <hr/>
                <div className="radio">
                    <label>
                        <input type="radio"
                               checked={!isWebsiteRegistered}
                               style={{marginRight: '5px'}}
                               onChange={() => setIsWebsiteRegistered(false)}/>
                        {t('website_not_registered')}
                    </label>
                </div>
                <div className="radio">
                    <label>
                        <input type="radio"
                               checked={isWebsiteRegistered}
                               style={{marginRight: '5px'}}
                               onChange={() => setIsWebsiteRegistered(true)}/>
                        {t('website_registered')}
                        <small>{t('website_registered_description')}</small>
                    </label>
                </div>
                <hr/>
            </div>
        </>
    )
}

export default IsWebsiteRegisteredRadioButtons;
