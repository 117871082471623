import {Row} from "../../@types/forTestingFormSectionsResources.ts";
import FormSectionRowInputMultiple from "./FormSectionRowInputMultiple.tsx";
import FormSectionRowInputSingle from "./FormSectionRowInputSingle.tsx";
import {UseFormRegister} from "react-hook-form";
import {ForTestingInputs} from "../../@types/forTestingFormResources.ts";
import {AcceptPaymentsInputs} from "../../@types/acceptPaymentsFormResources.ts";

const FormSectionRow = (
    {row, register}:
        {row: Row, register: UseFormRegister<ForTestingInputs>|UseFormRegister<AcceptPaymentsInputs>}
) => {
    return (
        <>
            <div className="row">
                {row.inputs.map((input, index) => (
                    input.singleInput
                        ? <FormSectionRowInputSingle input={input} key={index} register={register} />
                        : <FormSectionRowInputMultiple input={input} key={index} register={register} />
                ))}
            </div>
        </>
    )
}

export default FormSectionRow;
