import {Language} from "../../@types/generics.tsx";

export const languageToIso3 = (language: string) => {
    switch (language) {
        case Language.LT:
            return 'lit';
        case Language.EN:
            return 'eng';
        default:
            return 'lit';
    }
}
