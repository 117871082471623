import {Section} from "../../@types/privacyPolicySectionResources.tsx";
import {Language} from "../../@types/generics.tsx";

export const ForCandidatesPageSectionsConfig: Section[] = [
    {
        heading: 'notice_for_candidate_heading',
        paragraphs: [
            {
                text: 'notice_for_candidate_paragraph_1',
            },
            {
                text: 'notice_for_candidate_paragraph_2',
            },
            {
                text: 'notice_for_candidate_paragraph_3',
            }
        ],
        languageToShow: [Language.LT]
    },
    {
        heading: 'candidates_data_purposes_heading',
        paragraphs: [
            {
                text: 'candidates_data_purposes_paragraph_1',
            },
            {
                text: 'candidates_data_purposes_paragraph_2',
            }
        ],
        languageToShow: [Language.LT]
    },
    {
        heading: 'candidates_basis_for_data_heading',
        paragraphs: [
            {
                text: 'candidates_basis_for_data_paragraph_1',
            },
            {
                text: 'candidates_basis_for_data_paragraph_2',
                list: [
                    {
                        text: 'candidates_basis_for_data_paragraph_2_list_1',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_basis_for_data_paragraph_2_list_2',
                        languageToShow: [Language.LT]
                    }
                ]
            }
        ],
        languageToShow: [Language.LT]
    },
    {
        heading: 'candidates_data_categories_heading',
        paragraphs: [
            {
                text: 'candidates_data_categories_paragraph_1',
            },
            {
                text: 'candidates_data_categories_paragraph_2',
                list: [
                    {
                        text: 'candidates_data_categories_paragraph_2_list_1',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_2_list_2',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_2_list_3',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_2_list_4',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_2_list_5',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_2_list_6',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_2_list_7',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_2_list_8',
                        languageToShow: [Language.LT]
                    },
                ]
            },
            {
                text: 'candidates_data_categories_paragraph_3',
                list: [
                    {
                        text: 'candidates_data_categories_paragraph_3_list_1',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_3_list_2',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_3_list_3',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_3_list_4',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_3_list_5',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_3_list_6',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_3_list_7',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_3_list_8',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_3_list_9',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_3_list_10',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_categories_paragraph_3_list_11',
                        languageToShow: [Language.LT]
                    },
                ]
            }
        ],
        languageToShow: [Language.LT]
    },
    {
        heading: 'candidates_data_period_heading',
        paragraphs: [
            {
                text: 'candidates_data_period_paragraph_1',
            },
            {
                text: 'candidates_data_period_paragraph_2',
            },
            {
                text: 'candidates_data_period_paragraph_3',
                list: [
                    {
                        text: 'candidates_data_period_paragraph_3_list_1',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_period_paragraph_3_list_2',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_data_period_paragraph_3_list_3',
                        languageToShow: [Language.LT]
                    }
                ]
            }
        ],
        languageToShow: [Language.LT]
    },
    {
        heading: 'candidates_data_source_heading',
        paragraphs: [
            {
                text: 'candidates_data_source_paragraph_1',
            },
            {
                text: 'candidates_data_source_paragraph_2',
            },
            {
                text: 'candidates_data_source_paragraph_3',
            },
            {
                text: 'candidates_data_source_paragraph_4',
            }
        ],
        languageToShow: [Language.LT]
    },
    {
        heading: 'candidates_data_transfer_heading',
        paragraphs: [
            {
                text: 'candidates_data_transfer_paragraph_1',
            },
            {
                text: 'candidates_data_transfer_paragraph_2',
            }
        ],
        languageToShow: [Language.LT]
    },
    {
        heading: 'candidates_auto_solutions_heading',
        paragraphs: [
            {
                text: 'candidates_auto_solutions_paragraph_1',
            }
        ],
        languageToShow: [Language.LT]
    },
    {
        heading: 'candidates_rights_heading',
        paragraphs: [
            {
                text: 'candidates_rights_paragraph_1',
            },
            {
                text: 'candidates_rights_paragraph_2',
                list: [
                    {
                        text: 'candidates_rights_paragraph_2_list_1',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_rights_paragraph_2_list_2',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_rights_paragraph_2_list_3',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_rights_paragraph_2_list_4',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_rights_paragraph_2_list_5',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_rights_paragraph_2_list_6',
                        languageToShow: [Language.LT]
                    },
                    {
                        text: 'candidates_rights_paragraph_2_list_7',
                        languageToShow: [Language.LT]
                    },
                ]
            },
            {
                text: 'candidates_rights_paragraph_3',
            }
        ],
        languageToShow: [Language.LT]
    }
]
