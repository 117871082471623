import {Card} from "../../@types/buildingOnlineAdvantagesCardsResources.tsx";
import advantageIcon1 from '/assets/images/accelerating-online-page-images/advantage-icon-3.svg';
import advantageIcon2 from '/assets/images/building-online-page-images/advantage-icon-3.svg';
import advantageIcon3 from '/assets/images/custom-solutions-page-images/advantage-icon-3.svg';

export const CustomSolutionsAdvantagesCardsConfig: Card[] = [
    {
        image: advantageIcon1,
        description: 'collaborative_implementation_advantage_1'
    },
    {
        image: advantageIcon2,
        description: 'collaborative_implementation_advantage_2'
    },
    {
        image: advantageIcon3,
        description: 'collaborative_implementation_advantage_3'
    }
]
