import {updateMethods} from "../../../../helpers/pricing/calculatorHelper.ts";
import {PricingCalculatorConfig} from "../../../../configs/pricing-page/pricingCalculatorConfig.ts";
import {useTranslation} from "react-i18next";
import {PaymentMethod} from "../../../../@types/pricingCalculatorFieldsResources.tsx";

const SelectOptionsRegionSelect = (
    {sectionClass, sectionHeadingClass, setMethods}:
        {sectionClass: string, sectionHeadingClass: string|undefined, setMethods: (arg0: PaymentMethod[]) => void}
) => {

    const {t} = useTranslation();

    return (
        <>
            <div className={sectionClass}>
                <p className={sectionHeadingClass}>{t('region')}</p>
                <select onChange={(e) => updateMethods(e.target.value, setMethods)}
                        className="calculator_country m-calculation_country"
                        name="calculator_country">
                    {PricingCalculatorConfig.map((country, index) => (
                        <option key={index} value={country.name}>
                            {t(country.name)}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}

export default SelectOptionsRegionSelect;
