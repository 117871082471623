import {AdvantageItem} from "../../@types/advantagesResources.tsx";
import advantageLogo1 from "/assets/images/why-opay-page-images/icon-about-feature-10.svg";
import advantageLogo2 from "/assets/images/why-opay-page-images/icon-about-feature-11.svg";
import advantageLogo3 from "/assets/images/why-opay-page-images/icon-about-feature-12.svg";
import advantageLogo4 from "/assets/images/why-opay-page-images/icon-about-feature-13.svg";
import advantageLogo5 from "/assets/images/why-opay-page-images/icon-about-feature-9.svg";
import advantageLogo6 from "/assets/images/why-opay-page-images/icon-about-feature-1.svg";
import advantageLogo7 from "/assets/images/why-opay-page-images/icon-about-feature-3.svg";
import advantageLogo8 from "/assets/images/why-opay-page-images/icon-about-feature-2.svg";
import advantageLogo9 from "/assets/images/why-opay-page-images/icon-about-feature-4.svg";
import advantageLogo10 from "/assets/images/why-opay-page-images/icon-about-feature-5.svg";
import advantageLogo11 from "/assets/images/why-opay-page-images/icon-about-feature-6.svg";
import advantageLogo12 from "/assets/images/why-opay-page-images/icon-about-feature-7.svg";
import advantageLogo13 from "/assets/images/why-opay-page-images/icon-about-feature-8.svg";
import advantageLogo14 from "/assets/images/why-opay-page-images/icon-about-feature-14.svg";
import advantageLogo15 from "/assets/images/why-opay-page-images/icon-about-feature-15.svg";
import advantageLogo16 from "/assets/images/why-opay-page-images/icon-about-feature-16.svg";
import mainLogo1 from "/assets/images/why-opay-page-images/advantage-main-1.svg";
import mainLogo2 from "/assets/images/why-opay-page-images/advantage-main-2.svg";
import mainLogo3 from "/assets/images/why-opay-page-images/advantage-main-3.svg";
import mainLogo4 from "/assets/images/why-opay-page-images/advantage-main-4.svg";

export const WhyOpayAdvantagesConfig: AdvantageItem[] = [
    {
        heading: 'increase_sales',
        image: mainLogo1,
        imagePosition: 'left',
        advantages: [
            {
                image: advantageLogo1,
                text: 'increase_sales_advantage_1'
            },
            {
                image: advantageLogo2,
                text: 'increase_sales_advantage_2'
            },
            {
                image: advantageLogo3,
                text: 'increase_sales_advantage_3'
            },
            {
                image: advantageLogo4,
                text: 'increase_sales_advantage_4'
            },
            {
                image: advantageLogo5,
                text: 'increase_sales_advantage_5'
            }
        ]
    },
    {
        heading: 'manage_resources',
        image: mainLogo2,
        imagePosition: 'right',
        advantages: [
            {
                image: advantageLogo6,
                text: 'manage_resources_advantage_1'
            },
            {
                image: advantageLogo7,
                text: 'manage_resources_advantage_2'
            },
            {
                image: advantageLogo8,
                text: 'manage_resources_advantage_3'
            },
            {
                image: advantageLogo9,
                text: 'manage_resources_advantage_4'
            }
        ]
    },
    {
        heading: 'integrate_easily',
        image: mainLogo3,
        imagePosition: 'left',
        advantages: [
            {
                image: advantageLogo10,
                text: 'integrate_easily_advantage_1'
            },
            {
                image: advantageLogo11,
                text: 'integrate_easily_advantage_2'
            },
            {
                image: advantageLogo12,
                text: 'integrate_easily_advantage_3'
            },
            {
                image: advantageLogo13,
                text: 'integrate_easily_advantage_4'
            }
        ]
    },
    {
        heading: 'feel_secure',
        image: mainLogo4,
        imagePosition: 'right',
        advantages: [
            {
                image: advantageLogo14,
                text: 'feel_secure_advantage_1'
            },
            {
                image: advantageLogo15,
                text: 'feel_secure_advantage_2'
            },
            {
                image: advantageLogo16,
                text: 'feel_secure_advantage_3'
            }
        ]
    }
]
