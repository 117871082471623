import {Service} from "../../../@types/pricingTablePaymentServiceResources.tsx";
import {useTranslation} from "react-i18next";
import React from "react";
import {buildRateString} from "../../../helpers/pricing/calculatorHelper.ts";

const MobilePricingTableSection = ({service}: {service: Service}) => {

    const {t} = useTranslation();

    return (
        <>
            <tr className="prices-table-header">
                <td colSpan={5} >{t(service.heading)}</td>
            </tr>
            {service.countries.map((country, index) => (
                <React.Fragment key={index}>
                    <tr className="prices-table-country">
                        <td colSpan={5}>
                            <div className="text-center">
                                <img className="me-2" src={country.flag} alt='country flag' />
                                {t(country.name)}
                            </div>
                        </td>
                    </tr>
                    <tr className="prices-table-line">
                        <td>
                            <div>
                                <label className="fw-semibold">{t('percentage_rate')}</label>
                                <div>{country.percentageRate} %</div>
                            </div>
                        </td>
                        <td>
                            <div>
                                <label className="fw-semibold">{t('min_rate')}</label>
                                <div>
                                    {buildRateString(country.minRate)}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div>
                                <label className="fw-semibold">{t('max_rate')}</label>
                                <div>
                                    {buildRateString(country.maxRate)}
                                </div>
                            </div>
                        </td>
                        <td className="text-center align-middle fs-4 text-muted">+</td>
                        <td>
                            <div>
                                <label className="fw-semibold">{t('fixed_rate')}</label>
                                <div>{country.fixedRate} &euro;</div>
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            ))}
        </>
    )
}

export default MobilePricingTableSection;
