import {Button, Section} from "../../@types/faqAccordionResources.tsx";
import {Language} from "../../@types/generics.tsx";

export const FaqHeadingsConfig: Button[] = [
    {
        id: 'kyc-tab',
        targetId: 'kyc-faq',
        heading: 'faq_kyc_heading',
        active: {
            languageToActive: Language.LT
        },
        languageToShow: [Language.LT]
    },
    {
        id: 'terms-of-service-tab',
        targetId: 'terms-of-service',
        heading: 'faq_terms_of_service_heading',
        active: {
            languageToActive: Language.EN
        },
        languageToShow: [Language.LT, Language.EN]
    },
    {
        id: 'payment-methods-faq-tab',
        targetId: 'payment-methods-faq',
        heading: 'faq_payment_methods_heading',
        languageToShow: [Language.LT, Language.EN]
    },
    {
        id: 'client-registration-faq-tab',
        targetId: 'client-registration-faq',
        heading: 'faq_client_registration_heading',
        languageToShow: [Language.LT, Language.EN]
    },
    {
        id: 'client-account-faq-tab',
        targetId: 'client-account-faq',
        heading: 'faq_client_account_heading',
        languageToShow: [Language.LT, Language.EN]
    },
    {
        id: 'funds-management-faq-tab',
        targetId: 'funds-management-faq',
        heading: 'faq_funds_management_heading',
        languageToShow: [Language.LT, Language.EN]
    },
    {
        id: 'refund-faq-tab',
        targetId: 'refund-faq',
        heading: 'faq_refund_heading',
        languageToShow: [Language.LT, Language.EN]
    },
    {
        id: 'payer-rights-faq-tab',
        targetId: 'payer-rights-faq',
        heading: 'faq_payer_rights_heading',
        languageToShow: [Language.LT, Language.EN]
    },
]

export const FaqQuestionsConfig: Section[] = [
    {
        id: 'kyc-faq',
        labeledBy: 'kyc-tab',
        heading: 'faq_kyc_heading',
        languageToShow: [Language.LT],
        active: {
            languageToActive: Language.LT
        },
        questions: [
            {
                question: 'faq_kyc_question_1',
                answer: 'faq_kyc_answer_1',
                languageToShow: [Language.LT]
            },
            {
                question: 'faq_kyc_question_2',
                answer: 'faq_kyc_answer_2',
                languageToShow: [Language.LT]
            },
            {
                question: 'faq_kyc_question_3',
                answer: 'faq_kyc_answer_3',
                languageToShow: [Language.LT]
            },
            {
                question: 'faq_kyc_question_4',
                answer: 'faq_kyc_answer_4',
                languageToShow: [Language.LT]
            },
            {
                question: 'faq_kyc_question_5',
                answer: 'faq_kyc_answer_5',
                languageToShow: [Language.LT]
            },
            {
                question: 'faq_kyc_question_6',
                answer: 'faq_kyc_answer_6',
                languageToShow: [Language.LT]
            },
            {
                question: 'faq_kyc_question_7',
                answer: 'faq_kyc_answer_7',
                languageToShow: [Language.LT]
            },
            {
                question: 'faq_kyc_question_8',
                answer: 'faq_kyc_answer_8',
                languageToShow: [Language.LT]
            },
            {
                question: 'faq_kyc_question_9',
                answer: 'faq_kyc_answer_9',
                languageToShow: [Language.LT]
            },
            {
                question: 'faq_kyc_question_10',
                answer: 'faq_kyc_answer_10',
                languageToShow: [Language.LT]
            }
        ]
    },
    {
        id: 'terms-of-service',
        labeledBy: 'terms-of-service-tab',
        heading: 'faq_terms_of_service_heading',
        active: {
            languageToActive: Language.EN
        },
        languageToShow: [Language.LT, Language.EN],
        questions: [
            {
                question: 'faq_terms_of_service_question_1',
                answer: 'faq_terms_of_service_answer_1',
                languageToShow: [Language.LT, Language.EN]
            },
            {
                question: 'faq_terms_of_service_question_2',
                answer: 'faq_terms_of_service_answer_2',
                languageToShow: [Language.LT, Language.EN]
            },
            {
                question: 'faq_terms_of_service_question_3',
                answer: 'faq_terms_of_service_answer_3',
                languageToShow: [Language.LT]
            },
            {
                question: 'faq_terms_of_service_question_4',
                answer: 'faq_terms_of_service_answer_4',
                languageToShow: [Language.LT, Language.EN]
            }
        ]
    },
    {
        id: 'payment-methods-faq',
        labeledBy: 'payment-methods-faq-tab',
        heading: 'faq_payment_methods_heading',
        languageToShow: [Language.LT, Language.EN],
        questions: [
            {
                question: 'faq_payment_methods_question_1',
                answer: 'faq_payment_methods_answer_1',
                languageToShow: [Language.LT, Language.EN]
            },
            {
                question: 'faq_payment_methods_question_2',
                answer: 'faq_payment_methods_answer_2',
                languageToShow: [Language.LT, Language.EN]
            },
            {
                question: 'faq_payment_methods_question_3',
                answer: 'faq_payment_methods_answer_3',
                languageToShow: [Language.LT, Language.EN]
            }
        ]
    },
    {
        id: 'client-registration-faq',
        labeledBy: 'client-registration-faq-tab',
        heading: 'faq_client_registration_heading',
        languageToShow: [Language.LT, Language.EN],
        questions: [
            {
                question: 'faq_client_registration_question_1',
                answer: 'faq_client_registration_answer_1',
                languageToShow: [Language.LT, Language.EN]
            },
            {
                question: 'faq_client_registration_question_2',
                answer: 'faq_client_registration_answer_2',
                languageToShow: [Language.LT, Language.EN]
            }
        ]
    },
    {
        id: 'client-account-faq',
        labeledBy: 'client-account-faq-tab',
        heading: 'faq_client_account_heading',
        languageToShow: [Language.LT, Language.EN],
        questions: [
            {
                question: 'faq_client_account_question_1',
                answer: 'faq_client_account_answer_1',
                languageToShow: [Language.LT, Language.EN]
            },
            {
                question: 'faq_client_account_question_2',
                answer: 'faq_client_account_answer_2',
                languageToShow: [Language.LT, Language.EN]
            }
        ]
    },
    {
        id: 'funds-management-faq',
        labeledBy: 'funds-management-faq-tab',
        heading: 'faq_funds_management_heading',
        languageToShow: [Language.LT, Language.EN],
        questions: [
            {
                question: 'faq_funds_management_question_1',
                answer: 'faq_funds_management_answer_1',
                languageToShow: [Language.LT, Language.EN]
            },
            {
                question: 'faq_funds_management_question_2',
                answer: 'faq_funds_management_answer_2',
                languageToShow: [Language.LT, Language.EN]
            },
            {
                question: 'faq_funds_management_question_3',
                answer: 'faq_funds_management_answer_3',
                languageToShow: [Language.LT, Language.EN]
            },
            {
                question: 'faq_funds_management_question_4',
                answer: 'faq_funds_management_answer_4',
                languageToShow: [Language.LT, Language.EN]
            }
        ]
    },
    {
        id: 'refund-faq',
        labeledBy: 'refund-faq-tab',
        heading: 'faq_refund_heading',
        languageToShow: [Language.LT, Language.EN],
        questions: [
            {
                question: 'faq_refund_question_1',
                answer: 'faq_refund_answer_1',
                languageToShow: [Language.LT, Language.EN]
            },
            {
                question: 'faq_refund_question_2',
                answer: 'faq_refund_answer_2',
                languageToShow: [Language.LT, Language.EN]
            },
            {
                question: 'faq_refund_question_3',
                answer: 'faq_refund_answer_3',
                languageToShow: [Language.LT, Language.EN]
            }
        ]
    },
    {
        id: 'payer-rights-faq',
        labeledBy: 'payer-rights-faq-tab',
        heading: 'faq_payer_rights_heading',
        languageToShow: [Language.LT, Language.EN],
        questions: [
            {
                question: 'faq_payer_rights_question_1',
                answer: 'faq_payer_rights_answer_1',
                languageToShow: [Language.LT, Language.EN]
            }
        ]
    }
]
