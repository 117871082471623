import {Trans, useTranslation} from "react-i18next";

const PricingFormThankYou = () => {

    const {t} = useTranslation();

    return (
        <>
            <div className="col-lg-6">
                <div className="h-100 py-lg-5 py-xl-6 d-flex align-items-center justify-content-center">
                    <div>
                        <div className="fs-4 mb-5 fw-semibold text-center mw-md-75 mx-auto">
                            <Trans t={t} components={{
                                textOrange: <span className="text-orange" />,
                                textBlueDark: <span className="text-blue-dark" />,
                            }}>
                                {t('thank_you_message_got')}
                            </Trans>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PricingFormThankYou;
