import image1Lt from "/assets/images/demo-pages-images/donation-image-1-lt.gif";
import image2Lt from "/assets/images/demo-pages-images/donation-image-2-lt.png";
import image3Lt from "/assets/images/demo-pages-images/donation-image-3-lt.png";
import image4Lt from "/assets/images/demo-pages-images/donation-image-4-lt.png";
import image1En from "/assets/images/demo-pages-images/donation-image-1-en.gif";
import image2En from "/assets/images/demo-pages-images/donation-image-2-en.png";
import image3En from "/assets/images/demo-pages-images/donation-image-3-en.png";
import image4En from "/assets/images/demo-pages-images/donation-image-4-en.png";
import {Section} from "../../@types/demoAdvantagesResources.tsx";

export const DonationDemoAdvantagesConfig: Section = {
    heading: 'available_features',
    className: 'opy-bg-white pt-3 pt-md-5 pb-5 text-blue-dark',
    list: [
        {
            image: {
                lt: image1Lt,
                en: image1En
            },
            description: 'demo_advantage_1'
        },
        {
            image: {
                lt: image2Lt,
                en: image2En
            },
            description: 'demo_advantage_2'
        },
        {
            image: {
                lt: image3Lt,
                en: image3En
            },
            description: 'demo_advantage_3'
        },
        {
            image: {
                lt: image4Lt,
                en: image4En
            },
            description: 'demo_advantage_4'
        },
    ]
}
