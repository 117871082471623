import {Button} from "../../../@types/faqAccordionResources.tsx";
import {useTranslation} from "react-i18next";

const FaqHeadingButton = ({button}: {button: Button}) => {

    const { t, i18n} = useTranslation();

    return (
        <>
            <button className={button.active?.languageToActive === i18n.language
                ? 'active opy-faq-topic opy-faq-topic--active fw-semibold'
                : 'opy-faq-topic opy-faq-topic--active fw-semibold'}
                    id={button.id}
                    data-bs-toggle="pill"
                    data-bs-target={`#${button.targetId}`}
                    type="button"
                    role="tab"
                    aria-controls={button.targetId}
                    aria-selected="true" >
                {t(button.heading)}
            </button>
        </>
    )
}

export default FaqHeadingButton;
