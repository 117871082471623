import {useTranslation} from "react-i18next";
import DemoAdvantage from "./DemoAdvantage.tsx";
import {Section} from "../../../@types/demoAdvantagesResources.tsx";

const DemoAdvantages = ({config}: {config: Section}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="opy-page-demo-donation">
                <div className={config.className}>
                    <div className="container opy-block">
                        <h2 className="fs-4 mb-5">{t(config.heading)}</h2>
                        <div className="fw-medium fs-8">
                            <div className="row">
                                {config.list.map((advantage, index) => (
                                    <DemoAdvantage advantageItem={advantage} key={index} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DemoAdvantages;
