import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Card} from "../../../@types/linkableCardResources.tsx";

const LinkableCard = ({card}: {card: Card}) => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="col-md-6 col-lg-4 mb-4">
                <Link className="opy-card pt-4 pb-4"
                      to={i18n.language === 'lt' ? `/${t(card.link.lt)}` : `/${t(card.link.en)}`}>
                    <div className="mb-4 text-center">
                        <img className="img-fluid" src={card.image} alt='demonstration logo'/>
                    </div>
                    <div className="mb-3 px-5 fs-8 fw-semibold text-center">{t(card.heading)}</div>
                </Link>
            </div>
        </>
    )
}

export default LinkableCard;
