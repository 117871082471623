import {useTranslation} from "react-i18next";

const DemoBannerButtons = (
    {channelsInCheckout, setChannelsInCheckout}:
        {channelsInCheckout: boolean, setChannelsInCheckout: (arg0: boolean) => void}) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="demo-banner-buttons-container">
                <div className="container opy-block">
                    <div className="opy-tab-buttons">
                        <a className={channelsInCheckout ? "opy-tab-button opy-tab-button--active" : "opy-tab-button"}
                           onClick={() => setChannelsInCheckout(true)}>
                            {t('e_commerce_website_page')}
                        </a>
                        <a className={channelsInCheckout ? "opy-tab-button" : "opy-tab-button opy-tab-button--active"}
                           onClick={() => setChannelsInCheckout(false)}>
                            {t('OPAY_payment_page')}
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DemoBannerButtons;
