import LegalBanner from "../../components/privacy-policy-page-components/legal-banner/LegalBanner.tsx";
import bannerImage from "/assets/images/why-opay-page-images/icon-about-feature-13.svg";
import LegalPageSections
    from "../../components/privacy-policy-page-components/privacy-policy-sections/LegalPageSections.tsx";
import {ComplaintSubmissionSectionsConfig} from "../../configs/legal-pages/complaintSubmissionSections.ts";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const ComplaintSubmissionPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.complaint_submission');
    }, []);

    return (
        <>
            <LegalBanner heading={'submitting_a_complaint'} image={bannerImage} />
            <LegalPageSections config={ComplaintSubmissionSectionsConfig} />
        </>
    )
}

export default ComplaintSubmissionPage;
