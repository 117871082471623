import {Section} from "../../@types/privacyPolicySectionResources.tsx";
import {Language} from "../../@types/generics.tsx";

// @TODO: Update EN translations when they are ready
export const PrivacyPolicyPageSectionsConfig: Section[] = [
    {
        paragraphs: [
            {
                text: 'personal_data_processing_policy_paragraph_1',
            },
            {
                text: 'personal_data_processing_policy_paragraph_2',
            },
            {
                text: 'personal_data_processing_policy_paragraph_3',
            },
            {
                text: 'personal_data_processing_policy_paragraph_4',
            },
            {
                text: 'personal_data_processing_policy_paragraph_5',
            },
            {
                text: 'personal_data_processing_policy_paragraph_6',
            },
            {
                text: 'personal_data_processing_policy_paragraph_7',
            },
            {
                text: 'personal_data_processing_policy_paragraph_8',
            },
            {
                text: 'personal_data_processing_policy_paragraph_9',
            },
            {
                text: 'personal_data_processing_policy_paragraph_10',
            },
            {
                text: 'personal_data_processing_policy_paragraph_11',
            },
            {
                text: 'personal_data_processing_policy_paragraph_12',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'personal_data_sources_heading',
        paragraphs: [
            {
                text: 'personal_data_sources_paragraph_1',
                list: [
                    {
                        text: 'personal_data_sources_paragraph_1_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'personal_data_sources_paragraph_1_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'personal_data_sources_paragraph_1_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                ]
            },
            {
                text: 'personal_data_sources_paragraph_2',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'what_data_do_we_process_heading',
        paragraphs: [
            {
                text: 'what_data_do_we_process_paragraph_1',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'purposes_of_payment_services_heading',
        paragraphs: [
            {
                text: 'purposes_of_payment_services_paragraph_1',
            },
            {
                text: 'purposes_of_payment_services_paragraph_2',
                list: [
                    {
                        text: 'purposes_of_payment_services_paragraph_2_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_2_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_2_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_2_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_2_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_2_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_2_list_7',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'purposes_of_payment_services_paragraph_3',
                list: [
                    {
                        text: 'purposes_of_payment_services_paragraph_3_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_3_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_3_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_3_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_3_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'purposes_of_payment_services_paragraph_4',
            },
            {
                text: 'purposes_of_payment_services_paragraph_5',
            },
            {
                text: 'purposes_of_payment_services_paragraph_6',
            },
            {
                text: 'purposes_of_payment_services_paragraph_7',
                list: [
                    {
                        text: 'purposes_of_payment_services_paragraph_7_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_7_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_7_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_7_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_7_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_7_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_7_list_7',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_7_list_8',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ],
                note: 'purposes_of_payment_services_paragraph_7_note'
            },
            {
                text: 'purposes_of_payment_services_paragraph_8',
            },
            {
                text: 'purposes_of_payment_services_paragraph_9',
            },
            {
                text: 'purposes_of_payment_services_paragraph_10',
            },
            {
                text: 'purposes_of_payment_services_paragraph_11',
            },
            {
                text: 'purposes_of_payment_services_paragraph_12',
                list: [
                    {
                        text: 'purposes_of_payment_services_paragraph_12_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_payment_services_paragraph_12_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'purposes_of_payment_services_paragraph_13',
            },
            {
                text: 'purposes_of_payment_services_paragraph_14',
            },
            {
                text: 'purposes_of_payment_services_paragraph_15',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'purposes_of_aml_heading',
        paragraphs: [
            {
                text: 'purposes_of_aml_paragraph_1',
            },
            {
                text: 'purposes_of_aml_paragraph_2',
                list: [
                    {
                        text: 'purposes_of_aml_paragraph_2_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_7',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_8',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_9',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_10',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_11',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_12',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_13',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_14',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_15',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_16',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_17',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_2_list_18',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'purposes_of_aml_paragraph_3',
                list: [
                    {
                        text: 'purposes_of_aml_paragraph_3_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_3_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_3_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_3_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_3_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_3_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'purposes_of_aml_paragraph_4',
            },
            {
                text: 'purposes_of_aml_paragraph_5',
            },
            {
                text: 'purposes_of_aml_paragraph_6',
            },
            {
                text: 'purposes_of_aml_paragraph_7',
            },
            {
                text: 'purposes_of_aml_paragraph_8',
            },
            {
                text: 'purposes_of_aml_paragraph_9',
                list: [
                    {
                        text: 'purposes_of_aml_paragraph_9_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_9_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_9_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_9_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_9_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_9_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'purposes_of_aml_paragraph_10',
            },
            {
                text: 'purposes_of_aml_paragraph_11',
            },
            {
                text: 'purposes_of_aml_paragraph_12',
            },
            {
                text: 'purposes_of_aml_paragraph_13',
            },
            {
                text: 'purposes_of_aml_paragraph_14',
                list: [
                    {
                        text: 'purposes_of_aml_paragraph_14_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_14_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_14_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_14_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'purposes_of_aml_paragraph_15',
            },
            {
                text: 'purposes_of_aml_paragraph_16',
            },
            {
                text: 'purposes_of_aml_paragraph_17',
            },
            {
                text: 'purposes_of_aml_paragraph_18',
            },
            {
                text: 'purposes_of_aml_paragraph_19',
                list: [
                    {
                        text: 'purposes_of_aml_paragraph_19_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_19_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_19_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'purposes_of_aml_paragraph_19_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'purposes_of_aml_paragraph_20',
            },
            {
                text: 'purposes_of_aml_paragraph_21',
            },
            {
                text: 'purposes_of_aml_paragraph_22',
            },
            {
                text: 'purposes_of_aml_paragraph_23',
            },
            {
                text: 'purposes_of_aml_paragraph_24',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'other_purposes_heading',
        paragraphs: [
            {
                text: 'other_purposes_paragraph_1',
            },
            {
                text: 'other_purposes_paragraph_2',
                list: [
                    {
                        text: 'other_purposes_paragraph_2_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_2_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_2_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_2_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'other_purposes_paragraph_3',
            },
            {
                text: 'other_purposes_paragraph_4',
            },
            {
                text: 'other_purposes_paragraph_5',
            },
            {
                text: 'other_purposes_paragraph_6',
                list: [
                    {
                        text: 'other_purposes_paragraph_6_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_6_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_6_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_6_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_6_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_6_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_6_list_7',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'other_purposes_paragraph_7',
            },
            {
                text: 'other_purposes_paragraph_8',
            },
            {
                text: 'other_purposes_paragraph_9',
            },
            {
                text: 'other_purposes_paragraph_10',
                list: [
                    {
                        text: 'other_purposes_paragraph_10_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_10_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_10_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_10_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_10_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_10_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'other_purposes_paragraph_11',
            },
            {
                text: 'other_purposes_paragraph_12',
            },
            {
                text: 'other_purposes_paragraph_13',
            },
            {
                text: 'other_purposes_paragraph_14',
                list: [
                    {
                        text: 'other_purposes_paragraph_14_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_14_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_14_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_14_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_14_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_14_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_14_list_7',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_14_list_8',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'other_purposes_paragraph_15',
            },
            {
                text: 'other_purposes_paragraph_16',
            },
            {
                text: 'other_purposes_paragraph_17',
            },
            {
                text: 'other_purposes_paragraph_18',
            },
            {
                text: 'other_purposes_paragraph_19',
                list: [
                    {
                        text: 'other_purposes_paragraph_19_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_19_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'other_purposes_paragraph_20',
            },
            {
                text: 'other_purposes_paragraph_21',
            },
            {
                text: 'other_purposes_paragraph_22',
                list: [
                    {
                        text: 'other_purposes_paragraph_22_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_22_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'other_purposes_paragraph_23',
                list: [
                    {
                        text: 'other_purposes_paragraph_23_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_23_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_23_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'other_purposes_paragraph_24',
            },
            {
                text: 'other_purposes_paragraph_25',
            },
            {
                text: 'other_purposes_paragraph_26',
                list: [
                    {
                        text: 'other_purposes_paragraph_26_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_26_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_26_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_26_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_26_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'other_purposes_paragraph_27',
            },
            {
                text: 'other_purposes_paragraph_28',
            },
            {
                text: 'other_purposes_paragraph_29',
            },
            {
                text: 'other_purposes_paragraph_30',
            },
            {
                text: 'other_purposes_paragraph_31',
            },
            {
                text: 'other_purposes_paragraph_32',
                list: [
                    {
                        text: 'other_purposes_paragraph_32_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_32_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_32_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_32_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_32_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_32_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_32_list_7',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_32_list_8',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_32_list_9',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_32_list_10',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_32_list_11',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'other_purposes_paragraph_33',
            },
            {
                text: 'other_purposes_paragraph_34',
            },
            {
                text: 'other_purposes_paragraph_35',
            },
            {
                text: 'other_purposes_paragraph_36',
            },
            {
                text: 'other_purposes_paragraph_37',
                list: [
                    {
                        text: 'other_purposes_paragraph_37_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_37_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_37_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_37_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_37_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'other_purposes_paragraph_37_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'other_purposes_paragraph_38',
            },
            {
                text: 'other_purposes_paragraph_39',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'recipients_of_personal_data_heading',
        paragraphs: [
            {
                text: 'recipients_of_personal_data_paragraph_1',
                list: [
                    {
                        text: 'recipients_of_personal_data_paragraph_1_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'recipients_of_personal_data_paragraph_1_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'recipients_of_personal_data_paragraph_1_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'recipients_of_personal_data_paragraph_1_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'recipients_of_personal_data_paragraph_1_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'recipients_of_personal_data_paragraph_1_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'recipients_of_personal_data_paragraph_1_list_7',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'recipients_of_personal_data_paragraph_1_list_8',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'recipients_of_personal_data_paragraph_1_list_9',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'recipients_of_personal_data_paragraph_2',
            },
            {
                text: 'recipients_of_personal_data_paragraph_3',
            },
            {
                text: 'recipients_of_personal_data_paragraph_4',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'client_rights_heading',
        paragraphs: [
            {
                text: 'client_rights_paragraph_1',
                list: [
                    {
                        text: 'client_rights_paragraph_1_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'client_rights_paragraph_1_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'client_rights_paragraph_1_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'client_rights_paragraph_1_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'client_rights_paragraph_1_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'client_rights_paragraph_1_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'client_rights_paragraph_1_list_7',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'client_rights_paragraph_1_list_8',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'client_rights_paragraph_2',
                list: [
                    {
                        text: 'client_rights_paragraph_2_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'client_rights_paragraph_2_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                ]
            },
            {
                text: 'client_rights_paragraph_3',
                list: [
                    {
                        text: 'client_rights_paragraph_3_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'client_rights_paragraph_3_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'client_rights_paragraph_3_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'client_rights_paragraph_4',
            },
            {
                text: 'client_rights_paragraph_5',
            },
            {
                text: 'client_rights_paragraph_6',
            },
            {
                text: 'client_rights_paragraph_7',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'our_contacts_heading',
        paragraphs: [
            {
                text: 'our_contacts_paragraph_1',
                list: [
                    {
                        text: 'our_contacts_paragraph_1_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'our_contacts_paragraph_1_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'our_contacts_paragraph_1_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'privacy_policy_updates_heading',
        paragraphs: [
            {
                text: 'privacy_policy_updates_paragraph_1',
            },
            {
                text: 'privacy_policy_updates_paragraph_2',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    }
]
