import {Section} from "../../../@types/singlePaymentMethodDescriptionSectionResources.tsx";
import {useTranslation} from "react-i18next";
import SinglePaymentMethodDescriptionSectionParagraph from "./SinglePaymentMethodDescriptionSectionParagraph.tsx";

const SinglePaymentMethodDescriptionSection = ({config}: {config: Section}) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="opy-bg-gray pt-6 pb-7">
                <div className="container opy-block">
                    <h2 className="fs-6 mb-3">{t(config.heading)}</h2>
                    <p className="checkmark mb-4">{t(config.description)}</p>
                    {config.paragraphs.map((paragraph, index) => (
                        <SinglePaymentMethodDescriptionSectionParagraph paragraph={paragraph} key={index} />
                    ))}
                </div>
            </div>
        </>
    )
}

export default SinglePaymentMethodDescriptionSection;
