import {Checkbox} from "../../@types/acceptPaymentsAgreementSectionCheckboxResources.ts";

export const AcceptPaymentsAgreementSectionCheckboxesConfig: Checkbox[] = [
    {
        label: 'i_agree_with_services',
    },
    {
        label: 'i_agree_with_agreement_signing',
        list: [
            {
                label: 'opay_pricing_terms'
            },
            {
                label: 'opay_can_terminate_agreement'
            }
        ]
    },
    {
        label: 'i_agree_that_data_is_correct',
    }
]
