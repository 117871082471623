import {Trans, useTranslation} from "react-i18next";

const LegalPageSectionListItemTransComponent = ({text}: {text: string}) => {

    const {t} = useTranslation();

    return (
        <>
            <Trans t={t} components={{
                lrv: <a href="https://vdai.lrv.lt/lt/" />,
                opayFacebook: <a href="https://www.facebook.com/OPAY.LT" />,
                opayLinkedIn: <a href="https://www.linkedin.com/company/company-opay" />,
                facebookPrivacyPolicy: <a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0" />,
                linkedInPrivacyPolicy: <a href="https://www.linkedin.com/legal/privacy-policy" />,
                facebookCookiesLink:<a className="text-blue-dark"
                                       href="https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0" />,
                livechatCookiesLink: <a className="text-blue-dark"
                                        href="https://www.livechat.com/help/privacy-and-security/" />,
                privacyEmail: <a className='contact-by-email' href="mailto:privacy@opay.eu" />,
                contactEmail: <a className='contact-by-email' href="mailto:info@opay.eu" />,
                phone: <a href="tel:+37061155554" />,
                contactForm: <a href={`/${t('routes.consult')}`} target="_blank" />,
            }}>
                {text}
            </Trans>
        </>
    )
}

export default LegalPageSectionListItemTransComponent;
