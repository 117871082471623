import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import gif from '/assets/images/home-page-images/payment-methods-slide-24.11.14.gif'

const PaymentServiceBanner = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="opy-bg-white">
                <div className="row mx-0">
                    <div className="col-lg-6 ps-0 pe-lg-6 fs-7 mb-5 d-flex flex-column justify-content-center">
                        <h2 className="fs-4 fs-lg-3 mb-4">{t('20+_payment_methods')}</h2>
                        <p className="mb-5">{t('20+_payment_methods_description')}</p>
                      <div>
                          <Link to={`/${t('routes.payment_methods')}`}
                                className="opy-button">
                              <span>{t('learn_more_2')}</span>
                          </Link>
                      </div>
                    </div>
                    <div className="col-lg-6 pe-0">
                        <div className="ms-lg-5 h-100 d-flex justify-content-center">
                            <img className="img-fluid" src={gif} alt="Payment methods slide"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentServiceBanner;
