import {useTranslation} from "react-i18next";
import {ExampleMaxRate} from "../../../../../../@types/pricingCalculatorFieldsResources.tsx";
import {buildResultString} from "../../../../../../helpers/pricing/calculatorHelper.ts";

const MobileModalTableExampleMaxRate = ({rate}: {rate: ExampleMaxRate}) => {

    const {t} = useTranslation();

    return (
        <>
            {rate &&
                <div className="border-start border-end border-top text-center fs-9">
                    <div className="text-white text-center fw-semibold py-2 px-3" style={{backgroundColor: '#132748'}}>
                        {t('max_rate')}
                    </div>
                    <div className="row py-2 px-3 row-cols-2">
                        <div id="m-example-step-3" className="text-left">
                            <span style={{color: '#7D7D7D'}}>
                                {rate.percentageRateResult.toFixed(2)} € {t(rate.equalString)}
                            </span>
                            <span style={{color: '#09A563'}}> {rate.maxRate} €</span>
                        </div>
                        <div id="m-example-step-3-value" className="fs-7 text-right" style={{color: '#454545'}}>
                            {buildResultString(rate.result)}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default MobileModalTableExampleMaxRate;
