import {useEffect, useState} from "react";
import {ExampleFields, PaymentMethod} from "../../../../@types/pricingCalculatorFieldsResources.tsx";
import PricingCalculatorResult from "../calculator/PricingCalculatorResult.tsx";
import DesktopModalTable from "../modal/desktop/table/DesktopModalTable.tsx";
import MobileModalTable from "../modal/mobile/table/MobileModalTable.tsx";
import {
    calculateCommissionFee,
    getCurrentRates,
    getExampleFields,
    isCurrentRatesPopulated,
    updateMethods
} from "../../../../helpers/pricing/calculatorHelper.ts";
import SelectOptionsRegionSelect from "./SelectOptionsRegionSelect.tsx";
import SelectOptionsMethodSelect from "./SelectOptionsMethodSelect.tsx";
import SelectOptionsAmountField from "./SelectOptionsAmountField.tsx";

const SelectOptions = (
    {flex, sectionClass, sectionHeadingClass, mobile}:
        {flex: boolean, sectionClass: string, sectionHeadingClass: string|undefined, mobile: boolean}
) => {

    const [methods, setMethods] = useState<PaymentMethod[]>([]);
    const [selectedMethod, setSelectedMethod] = useState<string>('');
    const [paymentAmount, setPaymentAmount] = useState<number>(40);
    const [currentRates, setCurrentRates] = useState<PaymentMethod>({} as PaymentMethod);
    const [commissionFee, setCommissionFee] = useState<number>(0);
    const [exampleFields, setExampleFields] = useState<ExampleFields>({} as ExampleFields);

    useEffect(() => {
        setSelectedMethod(methods[0]?.name)
    }, [methods]);

    useEffect(() => {
        getCurrentRates(methods, selectedMethod, setCurrentRates);
    }, [selectedMethod]);

    useEffect(() => {
        updateMethods('lithuania', setMethods);
    }, []);

    useEffect(() => {
        if (isCurrentRatesPopulated(currentRates)) {
            calculateCommissionFee(paymentAmount, currentRates, setCommissionFee);
        }
    }, [paymentAmount, currentRates]);

    useEffect(() => {
        if (isCurrentRatesPopulated(currentRates)) {
            getExampleFields(paymentAmount, currentRates, setExampleFields, commissionFee);
        }
    }, [commissionFee]);

    return (
        <>
            <div className="px-4 mb-5" style={flex ? {display: 'flex'} : {display: 'block'}}>
                <SelectOptionsRegionSelect sectionClass={sectionClass}
                                           sectionHeadingClass={sectionHeadingClass}
                                           setMethods={setMethods} />
                <SelectOptionsMethodSelect sectionClass={sectionClass}
                                           sectionHeadingClass={sectionHeadingClass}
                                           selectedMethod={selectedMethod}
                                           setSelectedMethod={setSelectedMethod}
                                           methods={methods} />
                <SelectOptionsAmountField sectionClass={sectionClass}
                                          sectionHeadingClass={sectionHeadingClass}
                                          setPaymentAmount={setPaymentAmount} />
            </div>
            <PricingCalculatorResult commissionFee={commissionFee} />
            <div className="px-4 mb-5">
                {mobile
                    ? <MobileModalTable rates={currentRates} exampleFields={exampleFields} />
                    : <DesktopModalTable rates={currentRates} exampleFields={exampleFields} />
                }
            </div>
        </>
    )
}

export default SelectOptions;
