import RequisitesAndContacts
    from "../../components/contacts-page-components/requisites-and-contacts/RequisitesAndContacts.tsx";
import GoogleMap from "../../components/contacts-page-components/google-map/GoogleMap.tsx";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const ContactsPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.contacts');
    }, []);

    return (
        <>
            <RequisitesAndContacts />
            <GoogleMap />
        </>
    )
}

export default ContactsPage;
