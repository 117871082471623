import MainBanner from "../../components/home-page-components/main-banner/MainBanner.tsx";
import mainBannerImage from '/assets/images/faq-page-images/faq-image-1.svg'
import {FaqBannerButtonLinksConfig} from "../../configs/faq-page/faqPageMainBanner.ts";
import FaqAccordion from "../../components/faq-page-components/faq-accordion/FaqAccordion.tsx";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const FaqPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.faq');
    }, []);

    return (
        <>
            <MainBanner image={mainBannerImage}
                        links={FaqBannerButtonLinksConfig}
                        heading={'faq'}
                        paragraph={'faq_banner_description'} />
            <FaqAccordion />
        </>
    )
}

export default FaqPage;
