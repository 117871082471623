import {FormInput} from "../../@types/forTestingFormSectionsResources.ts";
import {useTranslation} from "react-i18next";
import {UseFormRegister} from "react-hook-form";

const FormSectionRowInputSingle = ({input, register}: {input: FormInput, register: UseFormRegister<any>}) => {

    const { t } = useTranslation();

    return (
        <>
            {input.type === 'textarea'
                ? <div className="col-sm-12 invisible-through-partner">
                <div className="form-group">
                    {input.label && <label className="text-blue-dark">{t(input.label)}</label>}
                    <div>
                        <textarea className="form-control"
                                  id={input.registerLabel}
                                  key={input.registerLabel}
                                  required={input.required}
                                  rows={4}
                                  {...register(input.registerLabel)}
                                  onChange={(e) => e.target.focus()}>
                        </textarea>
                    </div>
                    {input.hint && <div className="help-block">{t(input.hint)}</div>}
                </div>
                </div>
                : <div className="col-sm-6">
                    <div className="form-group">
                        {input.label && <label className="text-blue-dark">{t(input.label)}&nbsp;</label>}
                        <div>
                            <input id={input.registerLabel}
                                   key={input.registerLabel}
                                   className="form-control full"
                                   type={input.type}
                                   required={input.required}
                                   defaultValue={input.initialValue}
                                   pattern={input.pattern}
                                   minLength={input.minLength}
                                   maxLength={input.maxLength}
                                   {...register(input.registerLabel)}
                                   disabled={input.disabled}
                                   onChange={(e) => {input.onchange(e); e.target.focus()}}/>
                        </div>
                        {input.hint && <div className="help-block">{t(input.hint)}</div>}
                    </div>
                </div>}
        </>
    )
}

export default FormSectionRowInputSingle;
