import lektroLogo from '/assets/images/home-page-images/logo-lektro.png';
import batukrautuveleLogo from '/assets/images/home-page-images/logo-batukrautuvele.png';
import sauridaLogo from '/assets/images/home-page-images/logo-saurida.png';
import drogasLogo from '/assets/images/home-page-images/logo-drogas.png';
import emisijaLogo from '/assets/images/home-page-images/logo-emisija.png';
import foodforukraineLogo from '/assets/images/home-page-images/logo-foodforukraine.png';
import nebegedaLogo from '/assets/images/home-page-images/logo-nebegeda.png';
import domenaiLogo from '/assets/images/home-page-images/logo-domenai.png';
import {Card} from "../../@types/reviewCardsResources.tsx";

export const ReviewCardsConfig: Card[] = [
    {
        logo: drogasLogo,
        comment: 'drogas_comment',
        website: 'drogas.lt',
        name: 'DROGAS'
    },
    {
        logo: nebegedaLogo,
        comment: 'nebegeda_comment',
        website: 'nebegeda.lt',
        name: 'Kotryna'
    },
    {
        logo: lektroLogo,
        comment: 'lektro_comment',
        website: 'lektro.lt',
        name: 'Martynas'
    },
    {
        logo: sauridaLogo,
        comment: 'saurida_comment',
        website: 'esaurida.lt',
        name: 'Jūratė'
    },
    {
        logo: domenaiLogo,
        comment: 'domenai_comment',
        website: 'domenai.lt',
        name: 'Donatas'
    },
    {
        logo: emisijaLogo,
        comment: 'emisija_comment',
        website: 'emisija.lt',
        name: 'Elvis'
    },
    {
        logo: batukrautuveleLogo,
        comment: 'batukrautuvele_comment',
        website: 'batukrautuvele.lt',
        name: 'Irma'
    },
    {
        logo: foodforukraineLogo,
        comment: 'foodforukraine_comment',
        website: 'foodforukraine.eu',
        name: 'Nerijus'
    }
]
