import {Button} from "../../@types/notFoundResources.ts";

export const BannerButtonsConfig: Button[] = [
    {
        heading: 'home_page',
        link: 'routes.language'
    },
    {
        heading: 'why_opay',
        link: 'routes.about_opay'
    },
    {
        heading: 'requisites_and_contacts',
        link: 'routes.contacts'
    }
]
