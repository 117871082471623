import {useEffect, useRef} from "react";
import {handleNextButtonClick, handlePrevButtonClick, makeSlick} from "../../../helpers/carousel/carouselHelper.ts";
import $ from "jquery";
import {Cards} from "../../../@types/donationDemoCardsResources.tsx";
import DemoBannerCarouselCard from "./DemoBannerCarouselCard.tsx";
import buttonArrow from "/assets/images/payment-methods-page-images/icon-arrow-white-longer.svg";
import {useTranslation} from "react-i18next";

const DemoBannerCarousel = ({config}: {config: Cards}) => {

    const sliderRef = useRef<HTMLDivElement>(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        makeSlick(1, sliderRef.current!);

        $('#donation-demo-slider-arrow-right').on('click', () => handleNextButtonClick(sliderRef.current!));
        $('#donation-demo-slider-arrow-left').on('click', () => handlePrevButtonClick(sliderRef.current!));

        return () => {
            $('#donation-demo-slider-arrow-right').off('click', () => handleNextButtonClick(sliderRef.current!));
            $(sliderRef.current!).slick('unslick');
        };
    }, []);

    return (
        <>
            <div className="opy-bg-white pb-5">
                <div style={{marginTop: "-70px", position: "relative"}}>
                    <div className="container opy-block demo-slider">
                        <div id="donation-demo-slider-container">
                            <div id="donation-demo-slider-arrow-left"></div>
                            <div id="donation-demo-slider-arrow-right"></div>
                            <div id="demo-slider" ref={sliderRef} >
                                {config.list.map((card, index) => (
                                   <DemoBannerCarouselCard card={card} key={index} />
                                ))}
                            </div>
                        </div>
                        {config.button && <div className="text-center pt-5">
                            <a className="opy-button donation-content-link-button"
                               href={i18n.language === 'lt' ? config.button.link.lt : config.button.link.en}
                               target="_blank">
                           <span className="me-3">
                            {t(config.button.title)}
                           </span>
                                <img className="d-inline" src={buttonArrow} alt="button image" />
                            </a>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DemoBannerCarousel;
