import {FaqHeadingsConfig, FaqQuestionsConfig} from "../../../configs/faq-page/faqAccordion.ts";
import FaqHeadingButton from "./FaqHeadingButton.tsx";
import FaqQuestionsSection from "./FaqQuestionsSection.tsx";
import {useTranslation} from "react-i18next";
import {getCurrentLanguageEnum} from "../../../@types/generics.tsx";

const FaqAccordion = () => {

    const { i18n} = useTranslation();

    return (
        <>
            <div className="opy-page-faq">
                <section className="opy-bg-gray pt-5 pb-5">
                    <div className="container opy-block">
                        <div className="row">
                            <div className="col-lg-4 mb-5">
                                <div className="nav flex-column opy-faq-topics pt-5 pb-5 ps-5 pe-5"
                                     id="v-pills-tab"
                                     role="tablist"
                                     aria-orientation="vertical" >
                                    {FaqHeadingsConfig.map((button, index) => (
                                        button.languageToShow.includes(getCurrentLanguageEnum(i18n.language))
                                        && <FaqHeadingButton key={index} button={button} />
                                    ))}
                                </div>
                            </div>
                            <div className="tab-content col-lg-8" id="v-pills-tabContent">
                                {FaqQuestionsConfig.map((section, index) => (
                                    section.languageToShow.includes(getCurrentLanguageEnum(i18n.language))
                                    && <FaqQuestionsSection key={index} section={section} />
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default FaqAccordion;
