import {useTranslation} from "react-i18next";

const AdvantageItem = ({image, text}: {image: string, text: string}) => {

    const { t } = useTranslation();

    return (
        <li>
            <div>
                <img src={image} alt='Advantage icon'/>
            </div>
            <span>{t(text)}</span>
        </li>
    )
}

export default AdvantageItem;
