import MainBanner from "../../components/home-page-components/main-banner/MainBanner.tsx";
import bannerImage from "/assets/images/why-opay-page-images/why-opay-banner.svg";
import {WhyOpayBannerButtonLinksConfig} from "../../configs/why-opay-page/whyOpayPageMainBanner.ts";
import GetStartedBanner from "../../components/home-page-components/get-started-banner/GetStartedBanner.tsx";
import AdvantagesSection from "../../components/why-opay-page-components/advantages-section/AdvantagesSection.tsx";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const WhyOpayPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.why_opay');
    }, []);

    return (
        <>
            <MainBanner heading={'reliable_partner'}
                        image={bannerImage}
                        paragraph={'reliable_partner_description'}
                        links={WhyOpayBannerButtonLinksConfig} />
            <AdvantagesSection />
            <GetStartedBanner bannerText={'boost_your_business'}
                              linkText={'get_started'}
                              linkClassname={'opy-button opy-button--white opy-button--medium text-center'}
                              linkTo={'routes.start_using_accept_payments'} />
        </>
    )
}

export default WhyOpayPage;
