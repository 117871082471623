import FormSection from "../../registration-form/FormSection.tsx";
import ForTestingFormAgreementSection from "./ForTestingFormAgreementSection.tsx";
import {SubmitHandler, useForm} from "react-hook-form";
import {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ForTestingInputs} from "../../../@types/forTestingFormResources.ts";
import '/src/scss/jquery.fancybox.css';
import {
    clearErrorsOnClick,
    getRegistrationStatus,
    handleRegistrationStatus
} from "../../../helpers/register-form/registerFormHelper.tsx";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import {ForTestingPageFormSectionsConfig} from "../../../configs/for-testing-page/forTestingPageFormSections.ts";
import {getFromIso2} from "../../../@types/generics.tsx";
import Loading from "../../loading/Loading.tsx";
import {handleEmptyValues} from "../../../helpers/pricing/formHelper.ts";

const ForTestingForm = () => {

    const { t, i18n } = useTranslation();
    const formRef = useRef<HTMLFormElement>(null);
    const captchaRef = useRef<ReCAPTCHA>(null);
    const navigate = useNavigate();

    const {register, handleSubmit} = useForm<ForTestingInputs>();
    const [captcha, setCaptcha] = useState<boolean|null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit: SubmitHandler<ForTestingInputs> = async (data) => {
        if (captcha === null || !captcha) {
            return setCaptcha(false);
        }

        clearErrorsOnClick(formRef.current);

        data = handleEmptyValues(data);

        data.language = getFromIso2(i18n.language).toUpperCase();

        const registrationStatus = await getRegistrationStatus('testing', data, formRef.current, setLoading);

        return handleRegistrationStatus(registrationStatus, formRef.current, captchaRef, navigate, 'testing');
    }

    return (
        <>
            <div className="container opy-block">
                <form ref={formRef} id="start-using-form" className="start-using-form" onSubmit={handleSubmit(onSubmit)}>
                    {captcha === false && <div className="alert alert-danger top-error fw-semibold">
                        <p className="m-0">{t('wrong_inputs_in_form')}</p>
                    </div>}
                    {ForTestingPageFormSectionsConfig.map((section, index) => (
                        <FormSection section={section} key={index} register={register}/>
                    ))}
                    <ForTestingFormAgreementSection captcha={captcha} setCaptcha={setCaptcha} captchaRef={captchaRef}/>
                </form>
            </div>
            <a id="verification_popup" style={{display: 'none'}} data-fancybox-type="iframe"></a>
            <Loading display={loading ? 'block' : 'none'} />
        </>
    )
}

export default ForTestingForm;
