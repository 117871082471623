import './GoogleMap.css';

const GoogleMap = () => {
    return (
        <>
            <div className="raw-html-embed">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2305.8911907904285!2d25.30517771577804!3d54.69394238061856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dd96b1ed99d41d%3A0x940ff7e3d743c2c4!2sOPAY!5e0!3m2!1slt!2slt!4v1655381465058!5m2!1slt!2slt"
                    className="contacts-google-map"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </>
    )
}

export default GoogleMap;
