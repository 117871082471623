import {useTranslation} from "react-i18next";

const DemoDescription = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="opy-bg-white pt-5 pb-6">
                <div className="container opy-block">
                    <p className="fw-medium">{t('demo_description')}</p>
                    <div className="row py-4 align-items-center"></div>
                </div>
            </div>
        </>
    )
}

export default DemoDescription;
