import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import image from "../../../public/assets/images/not-found-page-images/icon-arrow-white-longer.png"
import {Button} from "../../@types/notFoundResources.ts";

const BannerButton = ({button}: {button: Button}) => {

    const { t } = useTranslation();

    return (
        <>
            <Link className="next-step opy-button opy-button--small fw-normal mb-4 mb-md-0 d-flex gap-1"
                  to={t(button.link)}>
                <span>{t(button.heading)}</span>
                <span>
                    <img src={image} alt="Button image"/>
                </span>
            </Link>
        </>
    )
}

export default BannerButton;
