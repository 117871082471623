import {useTranslation} from "react-i18next";
import {PluginsCardsConfig} from "../../../configs/integration-page/pluginsCards.ts";
import PluginsCard from "./PluginsCard.tsx";
import './Plugins.css';

const Plugins = () => {

    const { t } = useTranslation();

    return (
        <div>
            <div id={t('plugins_section_id')}></div>
            <div className="opy-page-plugins">
                <div className="opy-bg-gray pt-5 pb-6">
                    <div className="container opy-block">
                        <h2 className="fs-3 mb-3">{t('free_plugins')}</h2>
                        <p className="mb-5 w-sm-75">{t('free_plugins_description')}</p>
                        <div className="row">
                            {PluginsCardsConfig.map((plugin, index) => (
                                <PluginsCard key={index} plugin={plugin} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Plugins;
