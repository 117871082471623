import {Section} from "../../@types/privacyPolicySectionResources.tsx";
import {Language} from "../../@types/generics.tsx";

export const WhistleblowerPolicyPageSectionsConfig: Section[] = [
    {
        heading: 'wrongdoing_heading',
        paragraphs: [
            {
                text: 'wrongdoing_paragraph_1',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'report_wrongdoings_heading',
        paragraphs:[
            {
                text: 'report_wrongdoings_paragraph_1',
            },
            {
                text: 'report_wrongdoings_paragraph_2',
                list: [
                    {
                        text: 'report_wrongdoings_paragraph_2_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'report_wrongdoings_paragraph_2_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'report_wrongdoings_paragraph_2_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'report_wrongdoings_paragraph_2_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'report_wrongdoings_paragraph_2_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'report_wrongdoings_paragraph_2_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'report_wrongdoings_paragraph_2_list_7',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'report_wrongdoings_paragraph_2_list_8',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'report_wrongdoings_paragraph_2_list_9',
                        languageToShow: [Language.LT, Language.EN]
                    },
                ]
            },
            {
                text: 'report_wrongdoings_paragraph_3',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'how_to_report_heading',
        paragraphs: [
            {
                text: 'how_to_report_paragraph_1',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'rights_and_guarantees_heading',
        paragraphs: [
            {
                text: 'rights_and_guarantees_paragraph_1',
            },
            {
                text: 'rights_and_guarantees_paragraph_2',
            },
            {
                text: 'rights_and_guarantees_paragraph_3',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'raising_concerns_heading',
        paragraphs: [
            {
                text: 'raising_concerns_paragraph_1',
                list: [
                    {
                        text: 'raising_concerns_paragraph_1_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'raising_concerns_paragraph_1_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'raising_concerns_paragraph_1_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'raising_concerns_paragraph_1_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'raising_concerns_paragraph_1_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                ]
            },
            {
                text: 'raising_concerns_paragraph_2',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    }
]
