import {FormSectionType} from "../../@types/forTestingFormSectionsResources.ts";
import {handleWebsiteId} from "../../helpers/register-form/registerFormHelper.tsx";

export const AcceptPaymentsPageFormSectionsRegisteredConfig: FormSectionType[] = [
    {
        heading: 'e_shop_information',
        rows: [
            {
                inputs: [
                    {
                        label: 'store_website_id',
                        registerLabel: 'website_id',
                        required: false,
                        type: 'text',
                        maxLength: 10,
                        singleInput: true,
                        onchange: (e) => handleWebsiteId(e.target),
                    },
                    {
                        label: ' ',
                        registerLabel: 'website_check_url',
                        required: false,
                        type: 'text',
                        singleInput: true,
                        onchange: () => {},
                        disabled: true,
                    },
                ]
            },
            {
                inputs: [
                    {
                        label: 'website_name',
                        registerLabel: 'registered_website_name',
                        required: false,
                        type: 'text',
                        hint: 'example_website_name',
                        singleInput: true,
                        onchange: () => {},
                    },
                ]
            },
            {
                inputs: [
                    {
                        label: 'website_description',
                        registerLabel: 'registered_website_description',
                        required: false,
                        type: 'textarea',
                        hint: 'example_website_description',
                        singleInput: true,
                        onchange: () => {},
                    }
                ]
            }
        ]
    }
]
