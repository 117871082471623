import BannerWithElement from "../../components/demo-pages-components/demo-banner/BannerWithElement.tsx";
import DemoBannerButtons from "../../components/demo-pages-components/demo-banner/DemoBannerButtons.tsx";
import DemoDescription from "../../components/demo-pages-components/DemoDescription.tsx";
import PurchaseExample from "../../components/demo-pages-components/purchase-example/PurchaseExample.tsx";
import Solutions from "../../components/accelerating-online-page-components/solutions/Solutions.tsx";
import {DemoBankLinkSolutionsListConfig} from "../../configs/demo-pages/demoBankLinkSolutionsListConfig.ts";
import DemonstrationsCards from "../../components/demonstrations/DemonstrationsCards.tsx";
import {BankLinkDemonstrationsCardsConfig} from "../../configs/demo-pages/bankLinkDemoCards.ts";
import GetStartedBanner from "../../components/home-page-components/get-started-banner/GetStartedBanner.tsx";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const DemoBankLinkPage = () => {

    const [channelsInCheckout, setChannelsInCheckout] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.demo_bank_link_payments');
    }, []);

    return (
        <>
            <div className="opy-content">
                <div className="opy-page-demo-payment">
                    <BannerWithElement heading={'bank_link_demo'}
                                       element={<DemoBannerButtons
                                           channelsInCheckout={channelsInCheckout}
                                           setChannelsInCheckout={setChannelsInCheckout} />}
                                       slider={false} />
                    <DemoDescription />
                    <PurchaseExample page={'bankLink'} channelsInCheckout={channelsInCheckout} />
                    <Solutions solutions={DemoBankLinkSolutionsListConfig} />
                    <DemonstrationsCards config={BankLinkDemonstrationsCardsConfig} />
                    <GetStartedBanner bannerText={'boost_your_business'}
                                      linkText={'get_started'}
                                      linkClassname={'opy-button opy-button--white opy-button--medium text-center'}
                                      linkTo={'routes.start_using_accept_payments'} />
                </div>
            </div>
        </>
    )
}

export default DemoBankLinkPage;
