import PricingFormInput from "./PricingFormInput.tsx";
import PricingFormPrivacyCheckbox from "./PricingFormPrivacyCheckbox.tsx";
import PricingFormRecaptcha from "./PricingFormRecaptcha.tsx";
import {UseFormRegister} from "react-hook-form";
import {FormInput} from "../../../@types/pricingFormInputsResources.ts";
import {useTranslation} from "react-i18next";

const FormColumns = (
    {config, register, setCaptcha, linkClassName, isConsult}:
        {
            config: FormInput[],
            register: UseFormRegister<any>,
            setCaptcha: (arg0: boolean|null) => void,
            linkClassName: string
            isConsult?: boolean
        }
) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="form-columns">
                {config.map((formInput, index) => (
                    <PricingFormInput key={index} formInput={formInput} register={register}/>
                ))}
                {isConsult && <p className="text-white mb-3">* {t('required_fields')}</p>}
                <PricingFormPrivacyCheckbox linkClassName={linkClassName} />
                <PricingFormRecaptcha setCaptcha={setCaptcha}/>
            </div>
        </>
    )
}

export default FormColumns;
