import {useTranslation} from "react-i18next";

const MainBannerText = ({heading, paragraph}: {heading: string, paragraph: string}) => {

    const { t } = useTranslation();

    return (
      <div className="main-banner-text">
          <h1 className="fs-4 fs-md-3 fs-xl-1 mb-4">{t(heading)}</h1>
          <p className="fs-7 mb-5">{t(paragraph)}</p>
      </div>
  );
}

export default MainBannerText;
