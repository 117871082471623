import BenefitsSection from "./BenefitsSection.tsx";
import {Section} from "../../../@types/buildingOnlineBenefitsSectionsResources.tsx";

const Benefits = ({benefits}: {benefits: Section[]}) => {
    return (
        <>
            {benefits.map((section, index) => (
                <BenefitsSection key={index} section={section} />
            ))}
        </>
    )
}

export default Benefits;
