import {ExamplePercentageRate} from "../../../../../../@types/pricingCalculatorFieldsResources.tsx";
import {useTranslation} from "react-i18next";
import {X} from "lucide-react";

const MobileModalTableExamplePercentageRate = ({rate}: {rate: ExamplePercentageRate}) => {

    const {t} = useTranslation();

    return (
        <>
            {rate &&
                <div className="border-start border-end border-top text-center fs-9 rounded-top">
                    <div className="text-white text-center fw-semibold py-2 px-3  rounded-top"
                         style={{backgroundColor: '#132748'}}>
                        {t('percentage_rate')}
                    </div>
                    <div className="row py-2 px-3 row-cols-2">
                        <div id="m-example-step-1" className="text-left fs-6">
                            <span style={{color: '#8F3090'}}>{rate.paymentAmount}</span>
                            <span style={{color: '#7D7D7D'}}> <X size={14} /> </span>
                            <span style={{color: '#FF9E0C'}}>{rate.percentageRate} %</span>
                        </div>
                        <div id="m-example-step-1-value" className="fs-7 text-right" style={{color: '#454545'}}>
                            {rate.result.toFixed(2)} €
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default MobileModalTableExamplePercentageRate;
