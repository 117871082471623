import {Country} from "../../@types/demoCountrySelectorResources.ts";

export const CountrySelectorConfig: Country[] = [
    {
        title: 'Lithuania',
        code: 'LT'
    },
    {
        title: 'Latvia',
        code: 'LV'
    },
    {
        title: 'Estonia',
        code: 'EE'
    },
];
