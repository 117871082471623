import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const Default = () => {

    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage('lt').then(() => {
            window.location.href = `/${i18n.language}`;
        });
    }, []);

    return (
        <>
        </>
    )
}

export default Default;
