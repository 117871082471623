import {useTranslation} from "react-i18next";

const PaymentMethodCardFeatures = ({features}: {features: string[] | null}) => {

    const { t } = useTranslation();

    return (
        <>
            {features &&
                <>
                    <div className="text-blue-dark fs-7 fw-semibold mb-4">{t('features')}:</div>
                    <div className="row">
                        <ul className="opy-list-2 text-blue-dark">
                            {features.map((feature, index) => (
                                <li key={index} className="pb-2 pb-xl-1">{t(feature)}</li>
                            ))}
                        </ul>
                    </div>
                </>
            }
        </>
    )
}

export default PaymentMethodCardFeatures;
