import {useTranslation} from "react-i18next";
import {Card} from '../../../@types/reviewCardsResources.tsx';

const ReviewerCard = ({card}: {card: Card}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="col-6 p-3">
                <div className="opy-card opy-card--bordered py-4 px-5">
                    <img src={card.logo} alt="Reviewer logo"/>
                    <p className="mb-5">{t(card.comment)}</p>
                    <div>
                        <strong className="text-blue-dark">{card.website}</strong>
                        <p>{card.name}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReviewerCard;
