import {Section} from "../../@types/privacyPolicySectionResources.tsx";
import {Language} from "../../@types/generics.tsx";

export const ComplaintSubmissionSectionsConfig: Section[] = [
    {
        heading: 'how_submit_heading',
        paragraphs: [
            {
                text: 'how_submit_paragraph_1',
            },
            {
                text: 'how_submit_paragraph_2',
                list: [
                    {
                        text: 'how_submit_paragraph_2_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'how_submit_paragraph_2_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'how_submit_paragraph_2_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'how_submit_paragraph_2_list_4',
                        languageToShow: [Language.LT]
                    }
                ]
            },
            {
                text: 'how_submit_paragraph_3',
            },
            {
                text: 'how_submit_paragraph_4',
                list: [
                    {
                        text: 'how_submit_paragraph_4_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'how_submit_paragraph_4_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'how_submit_paragraph_4_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                ]
            },
            {
                text: 'how_submit_paragraph_5',
                list: [
                    {
                        text: 'how_submit_paragraph_5_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'how_submit_paragraph_5_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'how_submit_paragraph_5_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'how_submit_paragraph_5_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'how_submit_paragraph_5_list_5',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'how_submit_paragraph_5_list_6',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'how_submit_paragraph_5_list_7',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'how_submit_paragraph_6',
            },
            {
                text: 'how_submit_paragraph_7',
            },
            {
                text: 'how_submit_paragraph_8',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'is_complaint_received_heading',
        paragraphs: [
            {
                text: 'is_complaint_received_paragraph_1',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'how_long_respond_take_heading',
        paragraphs: [
            {
                text: 'how_long_respond_take_paragraph_1',
            },
            {
                text: 'how_long_respond_take_paragraph_2',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'response_not_satisfy_heading',
        paragraphs: [
            {
                text: 'response_not_satisfy_paragraph_1',
            },
            {
                text: 'response_not_satisfy_paragraph_2',
            },
            {
                text: 'response_not_satisfy_paragraph_3',
            },
            {
                text: 'response_not_satisfy_paragraph_4',
            },
            {
                text: 'response_not_satisfy_paragraph_5',
            },
            {
                text: 'response_not_satisfy_paragraph_6',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    }
]
