import {MenuItem} from "../../@types/menuResources.tsx";

export const MenuSectionConfig: MenuItem[] = [
        {
            name: 'solutions',
            action: [
                {
                    to: {
                        lt: 'routes.about_opay',
                        en: 'routes.about_opay'
                    },
                    className: 'nav-link dropdown-item',
                    text: 'why_opay'
                },
                {
                    to: {
                        lt: 'routes.payment_methods',
                        en: 'routes.payment_methods'
                    },
                    className: 'nav-link dropdown-item',
                    text: 'payment_methods'
                },
                {
                    to: {
                        lt: 'routes.building_online_business',
                        en: 'routes.building_online_business'
                    },
                    className: 'nav-link dropdown-item',
                    text: 'building_online'
                },
                {
                    to: {
                        lt: 'routes.accelerating_online_business',
                        en: 'routes.accelerating_online_business'
                    },
                    className: 'nav-link dropdown-item',
                    text: 'accelerating_online'
                },
                {
                    to: {
                        lt: 'routes.custom_payments_solutions',
                        en: 'routes.custom_payments_solutions'
                    },
                    className: 'nav-link dropdown-item',
                    text: 'custom_solutions'
                }
            ],
        },
        {
            name: 'integration',
            action: 'routes.integration'
        },
        {
            name: 'pricing',
            action: 'routes.pricing'
        },
        {
            name: 'demonstrations',
            action: [
                {
                    to: {
                        lt: 'routes.demonstrations_bank_link_payments',
                        en: 'routes.demonstrations_pis_payments'

                    },
                    className: 'nav-link dropdown-item',
                    text: 'payment_initiation'
                },
                {
                    to: {
                        lt: 'routes.demonstrations_donations',
                        en: 'routes.demonstrations_donations'
                    },
                    className: 'nav-link dropdown-item',
                    text: 'donation_collection'
                },
                {
                    to: {
                        lt: 'routes.demonstrations_self_service',
                        en: 'routes.demonstrations_self_service'
                    },
                    className: 'nav-link dropdown-item',
                    text: 'self_service_2'
                }
            ],
        },
        {
            name: 'faq',
            action: 'routes.faq'
        }
    ]
