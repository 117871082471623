const Loading = ({display}: {display: string}) => {
    return (
        <>
            <div style={{display: display}}>
                <div className='fancybox-overlay fancybox-overlay-fixed' style={{display: display}}></div>
                <div id='fancybox-loading'>
                    <div></div>
                </div>
            </div>
        </>
    )
}

export default Loading;
