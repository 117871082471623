import {useTranslation} from "react-i18next";
import {CountrySelectorConfig} from "../../../configs/demo-pages/countrySelectorConfig.ts";

const CountrySelector = ({setCountry}: {setCountry: (arg0: string) => void}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="country_el mb-3">
                <p className="text-center text-md-start fs-6 mb-4 text-blue-dark fw-semibold">{t('select_country')}:</p>
                <select onChange={(e) => setCountry(e.target.value)}>
                    {CountrySelectorConfig.map((country, index) => (
                        <option key={index} value={country.code}>{t(country.title)}</option>
                    ))}
                </select>
            </div>
        </>
    )
}

export default CountrySelector;
