import {Checkbox} from "../../../@types/acceptPaymentsAgreementSectionCheckboxResources.ts";
import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const AgreementSectionCheckbox = ({checkbox}: {checkbox: Checkbox}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="form-group" style={{margin: '10px 0 0 0'}}>
                <div className="checkbox d-flex">
                    <input type="checkbox" className="policy_agreement"/>
                    <label className="text-blue-dark fs-9" style={{margin: 0}}>
                        <Trans t={t} components={{
                            paymentTerms: <Link to={`/${t('routes.general_terms')}`} target="_blank" />,
                            fees: <Link to={`/${t('routes.pricing')}`} target="_blank" />,
                            processingTerms: <Link to={`/${t('routes.client_consent')}`} target="_blank" />,
                            privacyPolicy: <Link to={`/${t('routes.privacy_policy')}`} target="_blank" />,
                        }}>
                            {checkbox.label}
                        </Trans>
                        {checkbox.list && <ul style={{margin: 0}}>
                            {checkbox.list.map((item, index) => (
                                <li key={index} style={{listStyle: 'none', marginTop: '10px'}}>
                                    {t(item.label)}
                                </li>
                            ))}
                        </ul>}
                    </label>
                </div>
            </div>
        </>
    )
}

export default AgreementSectionCheckbox;
