import {Section} from "../../@types/singlePaymentMethodDescriptionSectionResources.tsx";

export const BankLinkDescriptionSectionConfig: Section = {
    heading: 'what_is_bank_link_heading',
    description: 'what_is_bank_link_description',
    paragraphs: [
        {
            heading: 'how_bank_link_works_heading',
            orderedList: [
                'how_bank_link_works_list_1',
                'how_bank_link_works_list_2',
                'how_bank_link_works_list_3',
                'how_bank_link_works_list_4',
                'how_bank_link_works_list_5'
            ]
        },
        {
            heading: 'features_heading',
            unorderedList: [
                'bank_link_features_list_1',
                'bank_link_features_list_2',
                'bank_link_features_list_3',
                'bank_link_features_list_4',
                'bank_link_features_list_5'
            ],
            description: 'bank_link_features_description'
        }
    ]
}
