import {Trans, useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const PricingFormPrivacyCheckbox = ({linkClassName}: {linkClassName: string}) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="form-column row ">
                <div className="form-field agree-container ">
                    <div className="form-label"><label className="inline"></label>
                    </div>
                    <div className="form-data">
                        <div className="form-input-wrapper  ">
                            <input name="agree_to_terms" type="checkbox" className="form-check-input" required/>
                            <label style={{display: 'inline'}} className="inline">
                                <Trans t={t} components={{
                                    privacyPolicy: <Link to={`/${t('routes.privacy_policy')}`}
                                                         className={linkClassName} />,
                                }}>
                                    {'form_agree_to_terms'}
                                </Trans>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PricingFormPrivacyCheckbox;
