import { Link } from "react-router-dom";
import MenuSectionSubItemList from "./MenuSectionSubItemList";
import { MenuSubItem } from "../../../@types/menuResources.tsx";
import {useTranslation} from "react-i18next";

const MenuSectionItem = ({name, action}: { name: string, action: string | MenuSubItem[] }) => {

    const { t } = useTranslation();

    const isLink = typeof (action) === "string";
    const link = isLink ? t(action as string) : "";
    const subItems = isLink ? [] : action as MenuSubItem[];

    return (
        <>
            <li className={"nav-item me-3 fw-semibold " + (!isLink ? "dropdown" : "")}>
                {isLink
                    ? <Link to={link} className="nav-link"><span>{t(name)}</span></Link>
                    : <>
                        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown">
                            <span>{t(name)}</span>
                        </a>
                        <MenuSectionSubItemList items={subItems} />
                    </>
                }
            </li>
        </>
    )
}

export default MenuSectionItem;
