import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import PaymentMethodsSection from "./PaymentMethodsSection.tsx";
import {listPaymentChannels, selectChannel} from "../../../helpers/demo/demoHelper.ts";

const PaymentMethods = (
    {page, country, selectedChannel, setSelectedChannel}:
        {page: string, country: string, selectedChannel: string, setSelectedChannel: (arg0: string) => void}
) => {

    const { t } = useTranslation();
    const paymentMethodsRef = useRef<HTMLDivElement>(null);

    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        listPaymentChannels(page, country, setPaymentMethods).then(r => r);
    }, [country]);

    useEffect(() => {
        selectChannel(paymentMethodsRef.current)
    }, [paymentMethods ,selectedChannel]);

    return (
        <>
            <div className="text-center text-md-start fs-5 fw-semibold mb-4 text-blue-dark">
                {t('select_payment_method')}:
            </div>
            <div ref={paymentMethodsRef}>
                {paymentMethods.map((section, index) => (
                    <PaymentMethodsSection key={index} section={section} setSelectedChannel={setSelectedChannel} />
                ))}
            </div>
        </>
    )
}

export default PaymentMethods;
