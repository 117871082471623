import {PurchaseExampleItemsConfig} from "../../../configs/demo-pages/purchaseExampleItemsConfig.ts";
import PurchaseExampleItem from "./PurchaseExampleItem.tsx";
import {useTranslation} from "react-i18next";
import PaymentMethods from "./PaymentMethods.tsx";
import CountrySelector from "./CountrySelector.tsx";
import {useState} from "react";
import PaymentPageSelectVisualization from "./PaymentPageSelectVisualization.tsx";
import {redirect} from "../../../helpers/demo/demoHelper.ts";

const PurchaseExampleCheckout = ({page, channelsInCheckout}: {page: string, channelsInCheckout: boolean}) => {

    const { t } = useTranslation();

    const [country, setCountry] = useState('LT');
    const [selectedChannel, setSelectedChannel] = useState('');

    return(
        <>
            <div className="bg-white pt-5 pb-4 border rounded">
                <div className="mw-75 mx-auto">
                    {PurchaseExampleItemsConfig.map((item, index) => (
                        <PurchaseExampleItem key={index} item={item}/>
                    ))}
                    <div className="opy-bg-light-blue rounded fw-semibold px-3 px-lg-4 py-3 fs-6 fs-lg-4 text-md-end mb-5 text-blue-dark">
                        {t('purchase_example_total')}
                    </div>
                    {page === 'pis' && channelsInCheckout && <CountrySelector setCountry={setCountry} />}
                    {channelsInCheckout
                        ? <PaymentMethods page={page} country={country} selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel} />
                        : <PaymentPageSelectVisualization />}
                    <div className="text-center mb-5">
                        <button className="btn py-3 opy-button btn-block demo-cart-btn-checkout"
                                onClick={async () => {
                                    await redirect(selectedChannel, channelsInCheckout, setSelectedChannel, page, country)
                                }}>
                            <span className="fw-semibold px-5 px-md-6 text-uppercase">{t('pay')}</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PurchaseExampleCheckout;
