import LegalPageSectionListItemTransComponent
    from "../../legal-pages-components/LegalPageSectionListItemTransComponent.tsx";

const LegalPageSectionParagraphText = ({text}: {text: string}) => {
    return (
        <>
            <p className="mb-4 checkmark text-blue-dark">
                <LegalPageSectionListItemTransComponent text={text}/>
            </p>
        </>
    )
}

export default LegalPageSectionParagraphText;
