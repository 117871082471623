import MainBanner from "../../components/home-page-components/main-banner/MainBanner.tsx";
import ServicesAndProducts from "../../components/home-page-components/services-and-products/ServicesAndProducts.tsx";
import TwoColumnsDynamicBanners from "../../components/home-page-components/home-why-opay/TwoColumnsDynamicBanners.tsx";
import LongImageBanner from "../../components/home-page-components/long-image-banner/LongImageBanner.tsx";
import ReviewersCarousel from "../../components/home-page-components/reviewers-carousel/ReviewersCarousel.tsx";
import GetStartedBanner from "../../components/home-page-components/get-started-banner/GetStartedBanner.tsx";
import mainBannerImage from '/assets/images/home-page-images/main-banner.svg'
import {HomeBannerButtonLinksConfig} from "../../configs/home-page/homePageMainBanner.ts";
import longBannerImage from '/assets/images/home-page-images/homepage-experience.jpeg'
import {WhyOpayBannersConfig} from "../../configs/home-page/homePageTwoColumnsDynamicBanners.ts";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

const HomePage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.home_page');
    }, []);

    return (
        <>
            <MainBanner image={mainBannerImage}
                        links={HomeBannerButtonLinksConfig}
                        heading={'we_take_care'}
                        paragraph={'we_take_care_description'} />
            <ServicesAndProducts />
            <TwoColumnsDynamicBanners heading={'why_opay'} config={WhyOpayBannersConfig}/>
            <LongImageBanner headingClass={'fs-4 fs-xl-3 mb-3 text-blue-dark fw-semibold'}
                             heading={'extensive_expertise'}
                             descriptions={['extensive_expertise_description']}
                             descriptionClass={null}
                             logo={longBannerImage} />
            <ReviewersCarousel />
            <GetStartedBanner bannerText={'boost_your_business'}
                              linkText={'get_started'}
                              linkClassname={'opy-button opy-button--white opy-button--medium text-center'}
                              linkTo={'routes.start_using_accept_payments'} />
        </>
    )
}

export default HomePage;
