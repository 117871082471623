import {useTranslation} from "react-i18next";
import {requisitesAndContacts} from "../../../configs/contacts-page/requisitesAndContacts.ts";

const Requisites = () => {

    const {t} = useTranslation();

    return (
        <>
            <div className="me-md-6 me-lg-7 mb-5">
                <h2 className="fs-6 fw-bold">{t('requisites')}</h2>
                <div className="fs-8">
                    <div>{requisitesAndContacts.companyName}</div>
                    <div>{t('company_code')} {requisitesAndContacts.companyCode}</div>
                    <div>{t('requisites_address')}</div>
                    <div>{t('bank_account')} {requisitesAndContacts.bankAccountNumber}</div>
                    <div>{requisitesAndContacts.bankName}, {t('bank_code')} {requisitesAndContacts.bankCode}</div>
                </div>
            </div>
        </>
    )
}

export default Requisites;
