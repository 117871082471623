import {Button} from "../../@types/mainBannerButtonLinksResources.tsx";

export const SolutionsShopifyBannerButtonLinksConfig: Button[] = [
    {
        to: 'solutions_shopify',
        toId: 'pricing-form',
        text: 'get_offer',
        className: 'd-block d-sm-inline mb-3 me-sm-2 mb-sm-0 text-center opy-button'
    }
]
