import {Section} from "../../@types/privacyPolicySectionResources.tsx";
import {Language} from "../../@types/generics.tsx";

export const ClientConsentPageSectionsConfig: Section[] = [
    {
        heading: 'the_client_agree_that',
        paragraphs: [
            {
                list: [
                    {
                        text: 'the_client_agree_that_list_1',
                        languageToShow: [Language.LT, Language.EN],
                    },
                    {
                        text: 'the_client_agree_that_list_2',
                        languageToShow: [Language.LT, Language.EN],
                    },
                    {
                        text: 'the_client_agree_that_list_3',
                        languageToShow: [Language.LT, Language.EN],
                    }
                ]
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    }
]
