import { Logger } from './logger';
import { SentryLogger } from '../../public/js/sentry/sentry-init';

let logger: Logger = new SentryLogger();

export const log = {
    error: (error: any) => logger.error(error),
    info: (error: any) => logger.info(error),
};

export const setLogger = (newLogger: Logger): void => {
    logger = newLogger;
};
