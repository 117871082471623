import {FollowUsLink, Link} from "../../@types/footerLinksResources.tsx";
import facebookLogo from '/assets/images/layout-images/icon-facebook-blue.png';
import linkedinLogo from '/assets/images/layout-images/icon-linkedin-blue.svg';
import {Language} from "../../@types/generics.tsx";

export const ReferencesSectionConfig: Link[] = [
    {
        to: 'contacts',
        text: 'requisites_and_contacts',
        languageToShow: [Language.LT, Language.EN]
    },
    {
        to: 'consult',
        text: 'consult',
        languageToShow: [Language.LT, Language.EN]
    }
]

export const SolutionsSectionConfig: Link[] = [
    {
        to: 'about_opay',
        text: 'why_opay',
        languageToShow: [Language.LT, Language.EN]
    },
    {
        to: 'payment_methods',
        text: 'payment_methods',
        languageToShow: [Language.LT, Language.EN]
    },
    {
        to: 'demonstrations',
        text: 'demonstrations',
        languageToShow: [Language.LT, Language.EN]
    },
    {
        to: 'integration',
        text: 'integration',
        languageToShow: [Language.LT, Language.EN]
    }
]

export const UsefulSectionConfig: Link[] = [
    {
        to: 'faq',
        text: 'faq',
        languageToShow: [Language.LT, Language.EN]
    },
    {
        to: 'legal_information',
        text: 'legal_info',
        languageToShow: [Language.LT, Language.EN]
    },
    {
        to: 'complaint_submission',
        text: 'complaint_submission',
        languageToShow: [Language.LT, Language.EN]
    },
    {
        to: 'whistleblower_policy',
        text: 'whistleblower_policy',
        languageToShow: [Language.EN]
    }
]

export const FollowUsSectionConfig: FollowUsLink[] = [
    {
        name: 'Facebook',
        link: 'https://www.facebook.com/OPAY.LT/',
        image: facebookLogo,
        className: 'follow-image-facebook'
    },
    {
        name: 'LinkedIn',
        link: 'https://lt.linkedin.com/company/company-opay',
        image: linkedinLogo,
        className: 'follow-image-linkedin'
    }
]
