import {FormInput} from "../../../@types/pricingFormInputsResources.ts";
import PricingFormInputSingle from "./PricingFormInputSingle.tsx";
import {UseFormRegister} from "react-hook-form";

const PricingFormInput = ({formInput, register}: {formInput: FormInput, register: UseFormRegister<any>}) => {
    return (
        <>
            <div className={formInput.firstClass}>
                <PricingFormInputSingle formInput={formInput} register={register}/>
                {formInput.secondInput
                    && <PricingFormInputSingle formInput={formInput.secondInput} register={register}/>}
            </div>
        </>
    )
}

export default PricingFormInput;
