import {Card} from "../../@types/paymentMethodsCardsResources.tsx";
import bankLinkIcon from '/assets/images/payment-methods-page-images/icon-banklink.svg';
import swedbankLogo from '/assets/images/payment-methods-page-images/logo-swedbank.png';
import sebLogo from '/assets/images/payment-methods-page-images/logo-seb.png';
import luminorLogo from '/assets/images/payment-methods-page-images/logo-luminor.png';
import citadeleLogo from '/assets/images/payment-methods-page-images/logo-citadele.png';
import siauliubankasLogo from '/assets/images/payment-methods-page-images/logo-siauliu-bankas.png';
import urboLogo from '/assets/images/payment-methods-page-images/logo-urbo.png';
import revolutLogo from '/assets/images/payment-methods-page-images/logo-revolut.png';
import lkuLogo from '/assets/images/payment-methods-page-images/logo-lku.png';
import payseraLogo from '/assets/images/payment-methods-page-images/logo-paysera.png';
import rietumuLogo from '/assets/images/payment-methods-page-images/logo-rietumu.png';
import lhvLogo from '/assets/images/payment-methods-page-images/logo-lhv.png';
import coopLogo from '/assets/images/payment-methods-page-images/logo-coop.png';
import wiseLogo from '/assets/images/payment-methods-page-images/logo-wise.png';
import n26Logo from '/assets/images/payment-methods-page-images/logo-n26.png';
import lpbLogo from '/assets/images/payment-methods-page-images/logo-lpb.png';
import cardIcon from '/assets/images/payment-methods-page-images/icon-cardpayment.svg';
import applePayLogo from '/assets/images/payment-methods-page-images/logo-apple-pay.png';
import googlePayLogo from '/assets/images/payment-methods-page-images/logo-google-pay.png';
import mastercardLogo from '/assets/images/payment-methods-page-images/logo-mastercard.png';
import visaLogo from '/assets/images/payment-methods-page-images/logo-visa.png';
import maestroLogo from '/assets/images/payment-methods-page-images/logo-maestro.png';
import cashIcon from '/assets/images/payment-methods-page-images/icon-cashpayment.svg';
import perlasLogo from '/assets/images/payment-methods-page-images/logo-perlas.png';
import contractIcon from '/assets/images/payment-methods-page-images/icon-contract.svg';
import gfLogo from '/assets/images/payment-methods-page-images/logo-gf.png';

export const PaymentMethodsCardsConfig: Card[] = [
    {
        image: bankLinkIcon,
        heading: "bank_link_service",
        description: "bank_link_service_description",
        additionalDescription: null,
        banks: [swedbankLogo, sebLogo, luminorLogo, citadeleLogo, siauliubankasLogo, urboLogo],
        features: ['bank_link_feature_1', 'bank_link_feature_2', 'bank_link_feature_3', 'bank_link_feature_4'],
        link: "routes.payment_methods_online_banking"
    },
    {
        image: bankLinkIcon,
        heading: "pis_service",
        description: "pis_service_description",
        additionalDescription: null,
        banks: [
            swedbankLogo,
            sebLogo,
            luminorLogo,
            citadeleLogo,
            siauliubankasLogo,
            urboLogo,
            revolutLogo,
            lkuLogo,
            payseraLogo,
            rietumuLogo,
            lhvLogo,
            coopLogo,
            wiseLogo,
            n26Logo,
            lpbLogo
        ],
        features: null,
        link: "routes.payment_methods_payment_initiation"
    },
    {
        image: cardIcon,
        heading: "card_payments_service",
        description: "card_payments_service_description_1",
        additionalDescription: "card_payments_service_description_2",
        banks: [applePayLogo, googlePayLogo, mastercardLogo, maestroLogo, visaLogo],
        features: [
            'card_feature_1',
            'card_feature_2',
            'card_feature_3',
            'card_feature_4',
            'card_feature_5',
            'card_feature_6',
            'card_feature_7'
        ],
        link: "routes.payment_methods_card_payments"
    },
    {
        image: cashIcon,
        heading: "cash_payments_service",
        description: "cash_payments_service_description",
        additionalDescription: null,
        banks: [perlasLogo],
        features: [
            'cash_feature_1',
            'cash_feature_2',
            'cash_feature_3',
            'cash_feature_4',
            'cash_feature_5'
        ],
        link: "routes.payment_methods_cash_payments"
    },
    {
        image: contractIcon,
        heading: "installment_purchases_service",
        description: "installment_purchases_service_description",
        additionalDescription: null,
        banks: [gfLogo],
        features: [
            'installment_feature_1',
            'installment_feature_2',
            'installment_feature_3',
            'installment_feature_4',
            'installment_feature_5'
        ],
        link: "routes.payment_methods_installment_payments"
    },
    {
        image: contractIcon,
        heading: "wire_transfers_service",
        description: "wire_transfers_service_description",
        additionalDescription: null,
        banks: [
            swedbankLogo,
            sebLogo,
            luminorLogo,
            citadeleLogo,
            siauliubankasLogo,
            urboLogo,
            lkuLogo,
            payseraLogo,
            rietumuLogo,
            lpbLogo,
            lhvLogo,
            coopLogo
        ],
        features: [
            'wire_transfer_feature_1',
            'wire_transfer_feature_2',
            'wire_transfer_feature_3',
            'wire_transfer_feature_4',
        ],
        link: "routes.payment_methods_bank_transfer"
    }
]
