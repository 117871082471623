import React, {Suspense} from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './fontkit.css'
import './scss/style.scss'
import './i18n/config.ts';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
      <Suspense>
          <App />
      </Suspense>
  </React.StrictMode>,
)
