import {useTranslation} from "react-i18next";
import {ExampleFixedRate} from "../../../../../../@types/pricingCalculatorFieldsResources.tsx";

const MobileModalTableExampleFixedRate = ({rate}: {rate: ExampleFixedRate}) => {

    const {t} = useTranslation();

    return (
        <>
            {rate &&
                <div className="border-start border-end border-top text-center fs-9 border-bottom rounded-bottom">
                    <div className="text-white text-center fw-semibold py-2 px-3" style={{backgroundColor: '#132748'}}>
                        {t('fixed_rate')}
                    </div>
                    <div className="row py-2 px-3 row-cols-2">
                        <div id="m-example-step-4" className="text-left">
                            <span style={{color: '#7D7D7D'}}>{rate.calculationResult.toFixed(2)} € + </span>
                            <span style={{color: '#09A563'}}>{rate.fixedRate.toFixed(2)} €</span>
                        </div>
                        <div id="m-example-step-4-value" className="fs-7 text-right" style={{color: '#454545'}}>
                            {rate.result.toFixed(2)} €
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default MobileModalTableExampleFixedRate;
