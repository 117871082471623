import {WhyOpayBannerButtonLinksConfig} from "../../configs/why-opay-page/whyOpayPageMainBanner.ts";
import MainBanner from "../../components/home-page-components/main-banner/MainBanner.tsx";
import bannerImage from "/assets/images/payment-methods-page-images/payment-methods-image-1.svg";
import PaymentFlowCarousel
    from "../../components/payment-methods-page-components/payment-flow-carousel/PaymentFlowCarousel.tsx";
import PaymentMethods from "../../components/payment-methods-page-components/payment-methods/PaymentMethods.tsx";
import LinkableCardsBanner from "../../components/payment-methods-page-components/linkable-cards-banner/LinkableCardsBanner.tsx";
import GetStartedBanner from "../../components/home-page-components/get-started-banner/GetStartedBanner.tsx";
import {PaymentMethodsPageLinkableCardsConfig} from "../../configs/payment-methods-page/paymentMethodsPageLinkableCards.ts";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const PaymentMethodsPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.payment_methods');
    }, []);

    return (
        <>
            <MainBanner heading={'payment_methods'}
                        image={bannerImage}
                        paragraph={'payment_methods_description'}
                        links={WhyOpayBannerButtonLinksConfig} />
            <PaymentFlowCarousel />
            <PaymentMethods />
            <LinkableCardsBanner cards={PaymentMethodsPageLinkableCardsConfig} />
            <GetStartedBanner bannerText={'payment_methods_banner_text'}
                              linkTo={'routes.pricing'}
                              linkClassname={'opy-button opy-button--white opy-button--medium text-center'}
                              linkText={'get_prices'} />
        </>
    )
}

export default PaymentMethodsPage;
