import {FormInput} from "../../@types/forTestingFormSectionsResources.ts";
import {useTranslation} from "react-i18next";
import {UseFormRegister} from "react-hook-form";

const FormSectionRowInputMultiple = (
    {input, register}:
        {input: FormInput, register: UseFormRegister<any>}
) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="col-sm-6">
                <div className="form-group">
                    {input.label && <label className="text-blue-dark">{t(input.label)}</label>}
                    <div className="row row-sm">
                    <div className="col-md-3 col-4 pe-0">
                            {input.isSelect
                                ? <select id={input.registerLabel}
                                          className="form-control left"
                                          {...register(input.registerLabel)}>
                                    {input.selectOptions?.map((option, index) => (
                                        <option key={index} value={option.name}>{option.name}</option>
                                    ))}
                                </select>
                                : <input id={input.registerLabel}
                                         key={input.registerLabel}
                                         className="form-control left"
                                         type={input?.type}
                                         required={input.required}
                                         defaultValue={input.initialValue}
                                         pattern={input.pattern}
                                         minLength={input.minLength}
                                         maxLength={input.maxLength}
                                         {...register(input.registerLabel)}
                                         onChange={(e) => {input.onchange(e); e.target.focus()}}
                                />}
                        </div>
                        {input.secondInput && <div className="col-md-9 col-8 ps-0">
                            <input id={input.secondInput.registerLabel}
                                   key={input.secondInput.registerLabel}
                                   className="form-control right"
                                   type={input.secondInput.type}
                                   required={input.secondInput.required}
                                   defaultValue={input.secondInput.initialValue}
                                   pattern={input.secondInput.pattern}
                                   minLength={input.secondInput.minLength}
                                   maxLength={input.secondInput.maxLength}
                                   {...register(input.secondInput.registerLabel)}
                                   onChange={(e) => {input.secondInput?.onchange(e); e.target.focus()}} />
                        </div>}
                    </div>
                    {input.hint && <div className="help-block">{t(input.hint)}</div>}
                </div>
            </div>
        </>
    )
}

export default FormSectionRowInputMultiple;
