import {useEffect, useRef} from "react";
import $ from "jquery";
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useTranslation} from "react-i18next";
import ReviewerCard from "./ReviewerCard.tsx";
import {ReviewCardsConfig} from "../../../configs/home-page/reviewCards.ts";
import {getSlidesCount, handleNextButtonClick, handleResize, makeSlick} from "../../../helpers/carousel/carouselHelper.ts";

const ReviewersCarousel = () => {

    const { t } = useTranslation();
    const sliderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        makeSlick(getSlidesCount(), sliderRef.current!);

        $('.review-next-button').on('click', () => handleNextButtonClick(sliderRef.current!));

        window.addEventListener('resize', () => handleResize(sliderRef.current!));

        return () => {
            $('.review-next-button').off('click', () => handleNextButtonClick(sliderRef.current!));
            window.removeEventListener('resize', () => handleResize(sliderRef.current!));
            $(sliderRef.current!).slick('unslick');
        };
    }, []);

    return (
        <>
            <div className="opy-bg-gray pt-6 pb-6">
                <div className="container opy-block">
                    <div className="mb-5">
                        <h2 className="fs-3 fs-md-2">{t('trusted_by_our_clients')}</h2>
                    </div>
                </div>
                <div className="container opy-block">
                    <div id="reviews">
                        <div className="review-next-button"></div>
                        <div className="row mb-6" id="reviews-slider" ref={sliderRef}>
                            {ReviewCardsConfig.map((card, index) => (
                                <ReviewerCard key={index} card={card} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReviewersCarousel;
