import {Trans, useTranslation} from "react-i18next";

const PaymentMethodText = (
    {heading, description, additionalDescription}:
    {heading: string, description: string, additionalDescription: string | null}
) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="pt-4 pt-md-5 w-sm-75 pe-lg-5">
                <h2 className="fs-4 fs-md-3 pb-3">{t(heading)}</h2>
                {additionalDescription && <p className=" mb-3 mb-md-5">
                    {t(description)}
                    <strong>
                        <Trans t={t} components={{
                            cardNotesLT: <a style={{textDecoration: 'underline'}}
                                            href='/user/themes/opay-theme/images/Mokejimo_korteles_atmintine_klientams_LT_v2024-08-22.pdf'
                                            target="_blank"/>,
                            cardNotesEN: <a style={{textDecoration: 'underline'}}
                                            href='/user/themes/opay-theme/images/Payment_card_service_guidelines_EN_v2024-08-22.pdf'
                                            target="_blank"/>
                        }}>
                            {t(additionalDescription)}
                        </Trans>
                    </strong>
                </p>}
            </div>
        </>
    )
}

export default PaymentMethodText;
