import {Card} from "../../../@types/pluginsCardsResources.tsx";

const PluginsCard = ({plugin}: {plugin: Card}) => {
    return (
        <>
            <div className="col-md-4 col-lg-3 col-sm-6 pb-5">
                <a className="opy-plugin-card pb-5" href={plugin.link}>
                    <div className="opy-plugin-card__logo">
                        <img src={plugin.image} alt='plugin image' />
                    </div>
                    <div className="opy-plugin-card__title">{plugin.name}</div>
                </a>
            </div>
        </>
    )
}

export default PluginsCard;
