import {listChannels, redirectToPayment} from "../../api";
import i18n, {t} from "i18next";

export const selectChannel = (container: HTMLDivElement|null) => {
    const channels = container?.querySelectorAll('.opy-payment-method__card');

    if (channels === null || channels === undefined) {
        return;
    }

    channels.forEach((channel: Element) => {
        channel.addEventListener('click', () => {
            channels.forEach((c: Element) => {
                c.classList.remove('checked');
            });

            channel.classList.add('checked');
        });
    });
}

export const listPaymentChannels = async (
    page: string,
    country: string,
    setPaymentMethods: (arg0: never[]) => void
) => {
    try {
        const {data} = await listChannels({
            payment_method: page === 'bankLink' ? 'bank_link' : 'pis',
            country: country,
            language: i18n.language
        });

        setPaymentMethods(Object.values(data.response.result));
    } catch (e) {
        //
    }
};

export const redirect = async (
    selectedChannel: string,
    channelsInCheckout: boolean,
    setSelectedChannel: (arg0: string) => void,
    page: string,
    country: string
) => {
    if (selectedChannel === '' && channelsInCheckout) {
        alert(t('select_payment_method'));
        return;
    }

    if (!channelsInCheckout) {
        setSelectedChannel('');
    }

    const {data} = await redirectToPayment({
        payment_method: page === 'bankLink' ? 'bank_link' : 'pis',
        country: country,
        language: i18n.language,
        selected_channel: selectedChannel
    });

    if (data.url) {
        window.location.href = data.url;
    }
}
