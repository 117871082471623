import CenteredBannerNoImage
    from "../../components/legal-information-page-components/centered-banner-no-image/CenteredBannerNoImage.tsx";
import LinkableCardsBanner
    from "../../components/payment-methods-page-components/linkable-cards-banner/LinkableCardsBanner.tsx";
import {DemonstrationsPageLinkableCardsConfig} from "../../configs/demo-pages/demonstrationsPageLinkableCards.ts";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const DemonstrationsPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.demonstrations');
    }, []);

    return (
        <>
            <CenteredBannerNoImage heading={'demonstrations'} paragraph={'demonstrations_banner_text'} />
            <LinkableCardsBanner cards={DemonstrationsPageLinkableCardsConfig} />
        </>
    )
}

export default DemonstrationsPage;
