import {Section} from "../../@types/privacyPolicySectionResources.tsx";
import {Language} from "../../@types/generics.tsx";

export const SigningOfDocumentsPageSectionsConfig: Section[] = [
    {
        heading: 'signing_remotely_heading',
        paragraphs: [
            {
                text: 'signing_remotely_paragraph_1',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'dokobit_heading',
        paragraphs: [
            {
                text: 'dokobit_paragraph_1',
                list: [
                    {
                        text: 'dokobit_paragraph_1_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'dokobit_paragraph_1_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'dokobit_paragraph_2',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'smart_id_heading',
        paragraphs: [
            {
                text: 'smart_id_paragraph_1',
                list: [
                    {
                        text: 'smart_id_paragraph_1_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'smart_id_paragraph_1_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'smart_id_paragraph_1_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'smart_id_paragraph_1_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
            {
                text: 'smart_id_paragraph_2',
            }
        ],
        languageToShow: [Language.LT, Language.EN]
    },
    {
        heading: 'mobile_signature_heading',
        paragraphs: [
            {
                text: 'mobile_signature_paragraph_1',
                list: [
                    {
                        text: 'mobile_signature_paragraph_1_list_1',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'mobile_signature_paragraph_1_list_2',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'mobile_signature_paragraph_1_list_3',
                        languageToShow: [Language.LT, Language.EN]
                    },
                    {
                        text: 'mobile_signature_paragraph_1_list_4',
                        languageToShow: [Language.LT, Language.EN]
                    }
                ]
            },
        ],
        languageToShow: [Language.LT, Language.EN]
    }
]
