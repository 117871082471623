import {Section} from "../../@types/singlePaymentMethodDescriptionSectionResources.tsx";

export const InstallmentPaymentsDescriptionSectionConfig: Section = {
    heading: 'what_is_installment_payments_heading',
    description: 'what_is_installment_payments_description',
    paragraphs: [
        {
            heading: 'how_installment_payments_works_heading',
            orderedList: [
                'how_installment_payments_works_list_1',
                'how_installment_payments_works_list_2',
                'how_installment_payments_works_list_3',
                'how_installment_payments_works_list_4',
                'how_installment_payments_works_list_5',
                'how_installment_payments_works_list_6'
            ]
        },
        {
            heading: 'features_heading',
            unorderedList: [
                'installment_payments_features_list_1',
                'installment_payments_features_list_2',
                'installment_payments_features_list_3',
                'installment_payments_features_list_4',
                'installment_payments_features_list_5',
                'installment_payments_features_list_6'
            ],
            description: 'installment_payments_features_description'
        }
    ]
}
