import './LongImageBanner.css'
import {useTranslation} from "react-i18next";

const LongImageBanner = (
    {headingClass, heading, descriptions, descriptionClass, logo}:
        {headingClass: string, heading: string, descriptions: string[], descriptionClass: string|null, logo: string}
) => {

    const {t} = useTranslation();

    return (
        <>
            <div className="experience-container opy-bg-gray-dark">
                    <div className="experience-container-text">
                        <div className={t(headingClass)}>
                            <p className="heading">{t(heading)}</p>
                        </div>
                        {descriptions.map((description, index) => (
                            <p key={index} className={descriptionClass === null ? '' : descriptionClass}>
                                {t(description)}
                            </p>
                        ))}
                </div>
                <div className="image-container"><img src={logo} alt="banner section background"/></div>
            </div>
        </>
    )
}

export default LongImageBanner;
