import ThankYouBanner from "../../components/thank-you-banner/ThankYouBanner.tsx";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const IdentityConfirmedPage = () => {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('titles.identity_confirmed');
    }, []);

    return (
        <>
            <ThankYouBanner text={'identity_confirmed'} isNavigationToHome={true} />
        </>
    )
}

export default IdentityConfirmedPage;
