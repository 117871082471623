import {FormInput} from "../../@types/pricingFormInputsResources.ts";
import {RegexPatternEnum} from "../../enum/pattern/regexPatternEnum.ts";

export const ConsultFormInputsConfig: FormInput[] = [
    {
        firstClass: 'form-column row mb-4',
        secondClass: 'form-column col-md-6 mb-4',
        label: 'name',
        required: true,
        type: 'text',
        secondInput: {
            firstClass: 'form-column row mb-4',
            secondClass: 'form-column col-md-6 mb-4',
            label: 'email',
            required: true,
            type: 'email',
            pattern: RegexPatternEnum.email,
        }
    },
    {
        firstClass: 'form-column row mb-4',
        secondClass: 'form-column col-md-6 mb-4',
        label: 'website_address',
        description: 'website_address_description',
        required: false,
        type: 'text',
        secondInput: {
            firstClass: 'form-column row mb-4',
            secondClass: 'form-column col-md-6 mb-4',
            label: 'phone_number',
            required: true,
            type: 'tel',
            pattern: '^[0-9\\-\\+\\s\\(\\)]*$',
            placeholder: '+370 65912387',
        }
    },
    {
        firstClass: 'form-column row mb-4',
        secondClass: 'form-column col-md-6 mb-4',
        label: 'subject',
        required: true,
        isSelect: true,
        selectOptions: [
            {name: 'shopify_payments'},
            {name: 'services_operation'},
            {name: 'services_pricing'},
            {name: 'technical_questions'},
            {name: 'legal_information'},
            {name: 'other_questions'},
        ]
    },
    {
        firstClass: 'form-column row mb-4',
        secondClass: 'form-column',
        label: 'message',
        required: true,
        isTextarea: true,
        type: 'textarea',
        minLength: 3,
        maxLength: 2048,
        rows: 3,
    }
]
