import {useTranslation} from "react-i18next";

const SelfServiceNotificationsDescription = () => {

    const { t } = useTranslation();


    return (
        <>
            <div className="opy-bg-white pt-4">
                <div className="container opy-block">
                    <h2 className="fs-4 mb-4">{t('real_time_notifications')}</h2>
                    <p className="mb-5">{t('real_time_notifications_description')}</p>
                </div>
            </div>
        </>
    )
}

export default SelfServiceNotificationsDescription;
