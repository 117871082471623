import {Service} from "../../../@types/pricingTablePaymentServiceResources.tsx";
import {useTranslation} from "react-i18next";
import {buildRateString} from "../../../helpers/pricing/calculatorHelper.ts";
import {Plus} from "lucide-react";

const DesktopPricingTableSection = ({service, index}: {service: Service, index: number}) => {

    const {t} = useTranslation();

    return (
        <>
            <tr>
                <td className="fw-semibold fs-7 pt-3 pb-3">{t(service.heading)}</td>
                <td></td>
                <td></td>
                <td></td>
                {index === 0
                    ? <td className="bg-gray text-center align-middle fs-3 text-muted rowspan" rowSpan={14}>
                        <Plus />
                </td>
                    : null}
                <td></td>
            </tr>
            {service.countries.map((country, index) => (
                <tr key={index}>
                    <td className="d-flex align-items-center pb-2">
                        <img className="me-2" src={country.flag} alt='country flag icon'/>
                        <div className="pt-1">{t(country.name)}</div>
                    </td>
                    <td className="text-center">{country.percentageRate} %</td>
                    <td className="text-center">
                        {buildRateString(country.minRate)}
                    </td>
                    <td className="text-center">
                        {buildRateString(country.maxRate)}
                    </td>
                    <td className="text-center">{country.fixedRate} &euro;</td>
                </tr>
            ))}
        </>
    )
}

export default DesktopPricingTableSection;
