import {Link} from "react-router-dom";
import {Card} from "../../../@types/serviceCardsResources.tsx";
import {useTranslation} from "react-i18next";

const ServiceCard = ({card}: {card: Card}) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="col-md-6 mb-4">
                <Link to={`/${t(card.to)}`} className="opy-service-card opy-card opy-card--bordered d-flex h-100">
                    <div className="me-4">
                        <img className="img-fluid" src={card.logo} alt="Service logo"/>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                        <div>
                            <div className="fs-5 pb-3 fw-semibold text-blue-dark">{t(card.heading)}</div>
                            <p className="fs-8">{t(card.text)}</p>
                        </div>
                        <div>
                            <div className="d-inline opy-button opy-button--light opy-button--small">
                                {t(card.buttonText)}
                            </div>
                            <div className="opy-service-card__arrow-icon"></div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default ServiceCard;
