import {useTranslation} from "react-i18next";
import gif from '/assets/images/home-page-images/OPAY-Shopify.svg'
import {HashLink} from "react-router-hash-link";

const ShopifyBanner = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="opy-bg-white">
                <div className="row mx-0">
                    <div className="col-lg-6 order-2 order-md-1 text-start pt-5">
                        <img className="img-fluid plugins-slide" src={gif} alt="Plugins slide"/>
                    </div>
                    <div
                        className="col-lg-6 order-1 order-md-2 ps-lg-6 fs-7 d-flex flex-column justify-content-center">
                        <h2 className="fs-4 fs-lg-3 mb-4">{t('shopify_partner')}</h2>
                        <p className="mb-5">{t('shopify_partner_description')}</p>
                        <div>
                            <HashLink to={`/${t('routes.solutions_shopify')}#pricing-form`}
                                  className="opy-button">
                                <span>{t('get_offer')}</span>
                            </HashLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShopifyBanner;
