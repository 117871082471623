import MobileModalTableRates from "./MobileModalTableRates.tsx";
import {ExampleFields, PaymentMethod} from "../../../../../../@types/pricingCalculatorFieldsResources.tsx";
import MobileModalTableExample
    from "./MobileModalTableExample.tsx";
import {useTranslation} from "react-i18next";

const MobileModalTable = (
    {rates, exampleFields}: {rates: PaymentMethod|undefined, exampleFields: ExampleFields}
) => {

    const {t} = useTranslation();

    return (
        <>
            {rates
                && <div>
                    <div className="fs-7 fw-semibold text-center text-blue-dark mb-3">
                        {t('calculation_example')}
                </div>
                    <MobileModalTableExample exampleFields={exampleFields} />
                    <MobileModalTableRates rates={rates} />
                </div>
            }
        </>
    )
}

export default MobileModalTable;
