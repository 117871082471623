import {ExampleMaxRate} from "../../../../../../@types/pricingCalculatorFieldsResources.tsx";
import {useTranslation} from "react-i18next";
import arrowImage from '/assets/images/pricing-page-images/round-plus.png';
import {buildResultString} from "../../../../../../helpers/pricing/calculatorHelper.ts";

const DesktopPricingCalculatorModalTableCalculationExampleMinRate = ({rate}: {rate: ExampleMaxRate}) => {

    const {t} = useTranslation();

    return (
        <>
            {rate &&
                <td className="fw-semibold text-center align-middle position-relative" style={{width: '200px'}}>
                    <div className="pt-1">
                        <div className="fs-9 mb-2" id="example-step-3">
                            <span style={{color: '#7D7D7D'}}>
                                {rate.percentageRateResult.toFixed(2)} € {t(rate.equalString)}
                            </span>
                            <span style={{color: '#09A563'}}> {rate.maxRate} €</span>
                        </div>
                        <div className="fs-7" id="example-step-3-value" style={{color: '#09A563'}}>
                            {buildResultString(rate.result)}
                        </div>
                    </div>
                    <img className="position-absolute"
                         src={arrowImage}
                         style={{top: '35px', right: '-15px'}}
                         alt='direction icon'/>
                </td>
            }
        </>
    )
}

export default DesktopPricingCalculatorModalTableCalculationExampleMinRate;
