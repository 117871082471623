import {Card} from "../../@types/linkableCardResources.tsx";
import demo1Image from '/assets/images/payment-methods-page-images/demonstrations-image-1.svg';
import demo2Image from '/assets/images/payment-methods-page-images/demonstrations-image-2.svg';
import demo3Image from '/assets/images/payment-methods-page-images/demonstrations-image-3.svg';

export const PaymentMethodsPageLinkableCardsConfig: Card[] = [
    {
        image: demo1Image,
        heading: "payments_demo",
        link: {
            lt: "routes.demonstrations_bank_link_payments",
            en: "routes.demonstrations_pis_payments"
        }
    },
    {
        image: demo2Image,
        heading: "donation_demo",
        link: {
            lt: "routes.demonstrations_donations",
            en: "routes.demonstrations_donations"
        }
    },
    {
        image: demo3Image,
        heading: "self_service_demo",
        link: {
            lt: "routes.demonstrations_self_service",
            en: "routes.demonstrations_self_service"
        }
    }
]
