import {useTranslation} from "react-i18next";
import {PricingTablePaymentServicesConfig} from "../../../configs/pricing-page/pricingTablePaymentServicesConfig.ts";
import DesktopPricingTableSection from "./DesktopPricingTableSection.tsx";
import PricingShopifyBanner from "./PricingShopifyBanner.tsx";
import MobilePricingTableSection from "./MobilePricingTableSection.tsx";

const PricingTable = () => {

    const {t} = useTranslation();

    return (
        <>
            <div className="raw-html-embed">
                <div className="opy-page-prices">
                    <div className="opy-bg-gray">
                        <div className="container opy-block">
                            <table className="d-none d-lg-table prices-table table border bg-white">
                                <thead>
                                <tr className="text-white pricing-table-head">
                                    <th>{t('payment_method')}</th>
                                    <th>{t('percentage_rate')}</th>
                                    <th>{t('min_rate')}</th>
                                    <th>{t('max_rate')}</th>
                                    <th style={{width: '50px'}}></th>
                                    <th>{t('fixed_rate')}</th>
                                </tr>
                                </thead>
                                <tbody className="text-black">
                                    {PricingTablePaymentServicesConfig.map((service, index) => (
                                        <DesktopPricingTableSection key={index} service={service} index={index} />
                                    ))}
                                </tbody>
                            </table>
                            <table className="d-lg-none prices-table-mobile table border bg-white">
                                <tbody>
                                    {PricingTablePaymentServicesConfig.map((service, index) => (
                                        <MobilePricingTableSection key={index} service={service} />
                                    ))}
                                </tbody>
                            </table>
                            <PricingShopifyBanner />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PricingTable;
